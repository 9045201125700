/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface vendorActivityState {
  vendorActivity: string;
  other?: string;
}

const initialState: vendorActivityState = {
  vendorActivity: '',
  other: '',
};

export const vendorActivitySlice = createSlice({
  name: 'vendorActivity',
  initialState,
  reducers: {
    setVendorActivity: (state, action: PayloadAction<vendorActivityState>) => {
      return { ...action.payload };
    },
  },
});

export const { setVendorActivity } = vendorActivitySlice.actions;

export default vendorActivitySlice.reducer;
