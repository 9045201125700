import styled from 'styled-components/macro';

const ProgressBar = styled.div<{ width: string }>`
  width: ${({ width }) => `${width}`};
  height: ${({ theme }) => `${theme.getValue(6)}px`};
  border-radius: ${({ theme }) => `${theme.getValue(5)}px`};
  background-color: ${({ theme }) => `${theme.colors.green1}`};
  margin-bottom: ${({ theme }) => `${theme.getValue(12)}px`};
  transition: width 0.7s ease-in-out;

  &.not-animated {
    transition: none;
  }
`;

const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  align-content: center;
`;

const UploadStatusContainer = styled.div<{ justify: string }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: ${({ justify }) => justify};
  align-items: center;
  align-content: center;
`;

const ShowProgress = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 150%;
  margin: 0;
  color: ${({ theme }) => `${theme.colors.gray2}`};
`;

const ConfirmationIcon = styled.img`
  object-fit: contain;
  width: ${({ theme }) => `${theme.getValue(15.8)}px`};
  height: ${({ theme }) => `${theme.getValue(15.8)}px`};
`;

export default { ProgressBar, UploadStatusContainer, ProgressBarContainer, ShowProgress, ConfirmationIcon };
