import styled from 'styled-components/macro';

export const Container = styled.div`
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => `${theme.getValue(4)}px`} ${({ theme }) => `${theme.getValue(12)}px`};
  gap: ${({ theme }) => `${theme.getValue(10)}px`};

  height: ${({ theme }) => `${theme.getValue(23)}px`};

  background: ${({ theme }) => `${theme.colors.gray9}`};
  border-radius: 17px;
`;

export const Label = styled.span`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  display: flex;
  align-items: flex-end;
  text-align: center;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  white-space: nowrap;
  display: block;
`;

export default { Container, Label };
