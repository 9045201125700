import { Dispatch, SetStateAction } from 'react';
import * as yup from 'yup';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';

export const UserFormSchema = yup.object({
  firstName: yup.string().required(REQUIRED_VALIDATION_MSG).default(''),
  lastName: yup.string().required(REQUIRED_VALIDATION_MSG).default(''),
  email: yup
    .string()
    .email(EMAIL_VALIDATION_MSG)
    .required(REQUIRED_VALIDATION_MSG)
    .default('')
    .matches(
      /^[A-Za-z0-9.]+@advantagesolutions\.net$/gm,
      'Only users with an @advantagesolutions.net email address can be invited to this platform.',
    ),
});

export type UserFormType = yup.InferType<typeof UserFormSchema>;

export type UserFormPropsType = {
  userData: UserFormType | undefined;
  loading?: boolean;
  userId?: string | number;
  updateUser: (userInfo: UserFormType) => Promise<void>;
  setDeleteDialogState: Dispatch<SetStateAction<boolean>>;
  errorMsg: string;
};

export default UserFormSchema;
