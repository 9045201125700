import styled from 'styled-components/macro';
import Input from '../../../components/Input';
import { Label, Input as InnerInput } from '../../../components/Input/styles';
import TextArea from '../../../components/TextArea';
import Headline from '../../../components/typography/Headline';

const Title = styled.h2`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(20)}px`};
  line-height: 125%;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  span {
    .review-grid__col2 & {
      max-width: 85%;
    }
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      max-width: 85%;
    }
  }
  button {
    align-self: flex-start;
    background-color: transparent;
    border: 0;
    padding: 0;
    margin: 0;
    width: ${({ theme }) => `${theme.getValue(17)}px`};
    height: ${({ theme }) => `${theme.getValue(17)}px`};
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 13.4588V17H3.54118L13.9853 6.55589L10.4441 3.01472L0 13.4588ZM16.7238 3.81739C17.0921 3.4491 17.0921 2.85419 16.7238 2.4859L14.5141 0.276212C14.1458 -0.0920705 13.5509 -0.0920705 13.1826 0.276212L11.4545 2.0043L14.9957 5.54548L16.7238 3.81739Z' fill='%230090BA'/%3E%3C/svg%3E");
    background-size: cover;
    &:hover {
      cursor: pointer;
    }
    span {
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      overflow: hidden;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
    .is-admin & {
      display: none;
    }
    @media print {
      display: none;
    }
  }
`;

export const Inner = styled.div<{ showBorder?: boolean }>`
  padding: ${({ theme, showBorder }) => (showBorder ? `${theme.getValue(16)}px` : '0')};
  border: ${({ theme, showBorder }) => (showBorder ? ` solid 1px ${theme.colors.primaryBlue}` : '0')};
  border-radius: ${({ theme }) => `${theme.getValue(4)}px`};
  margin-top: ${({ theme, showBorder }) => (showBorder ? `${theme.getValue(16)}px` : '0')};
  .grid-container--is-print & {
    margin-top: 8px;
  }
`;

export const ReviewGridInput = styled(Input)`
  min-width: 0;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    ${Label},${InnerInput} {
      font-size: ${({ theme }) => `${theme.getValue(16)}px`};
    }
  }
`;

export const ReviewGridTextArea = styled(TextArea)`
  min-width: 0;
`;

export const SectionTitle = styled(Headline)`
  margin: 0;
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
`;

export const SectionDescription = styled.p`
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  line-height: 150%;
  color: ${({ theme }) => `${theme.colors.gray2}`};
`;

export const FileList = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(12)}px`};
`;

export const FileListItem = styled.li`
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  color: ${({ theme }) => `${theme.colors.gray2}`};
  cursor: pointer;
  line-height: 150%;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  .grid-container--is-print & {
    font-size: 8px;
    color: ${({ theme }) => `${theme.colors.black}`};
  }
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
    .grid-container--is-print & {
      margin-top: 8px;
    }
  }
  &:before {
    content: '';
    width: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(16)}px`};
    margin-right: ${({ theme }) => `${theme.getValue(8)}px`};
    background-size: contain;
    background-image: url("data:image/svg+xml,%3Csvg width='17' height='17' viewBox='0 0 17 17' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.49967 0.583374C4.14551 0.583374 0.583008 4.14587 0.583008 8.50004C0.583008 12.8542 4.14551 16.4167 8.49967 16.4167C12.8538 16.4167 16.4163 12.8542 16.4163 8.50004C16.4163 4.14587 12.8538 0.583374 8.49967 0.583374ZM6.91634 12.4584L2.95801 8.50004L4.07426 7.38379L6.91634 10.218L12.9251 4.20921L14.0413 5.33337L6.91634 12.4584Z' fill='%2310B981'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    flex: 0 0 auto;
    .grid-container--is-print & {
      width: 12px;
      height: 12px;
    }
  }
`;

export default {
  Title,
  Inner,
  ReviewGridInput,
  SectionTitle,
  SectionDescription,
  FileList,
  FileListItem,
  ReviewGridTextArea,
};
