import SignedDocumentType from './types';
import { FileContainer, Link, Icon } from './styles';
import checkIcon from './checkIcon.svg';

export default function SignedDocument(props: SignedDocumentType) {
  const { link } = props;

  return (
    <FileContainer>
      <Link href={link} target="_blank" rel="noreferrer">
        <Icon src={checkIcon} /> Signature.pdf
      </Link>
    </FileContainer>
  );
}
