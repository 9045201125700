import styled from 'styled-components';
import { Container as DropdownContainer } from '../../Dropdown/styles';

const Container = styled.div`
  display: flex;
  column-gap: ${({ theme }) => `${theme.getValue(12)}px`};
  ${DropdownContainer} {
    margin-bottom: 0;
  }
`;

const FilterBtn = styled.button`
  font-family: 'Proxima Nova';
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 150%;
  letter-spacing: -0.015em;
  color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  border: 0;
  background-color: transparent;
  border-radius: 0;
  height: ${({ theme }) => `${theme.getValue(36)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(12)}px`};
  &:hover {
    cursor: pointer;
  }
`;

const PillsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => `${theme.getValue(12)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(12)}px`};
`;

export default { Container, FilterBtn, PillsContainer };
