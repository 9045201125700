import { useState, useRef, useLayoutEffect } from 'react';
import gsap from 'gsap';
import Components from './styles';
import AccordionType from './types';

export default function Accordion(props: AccordionType) {
  const { title, children, onClick } = props;
  const [isOpen, setIsOpen] = useState(false);
  const accordionContentRef = useRef(null);
  const handleAccordionHeaderClick = () => {
    setIsOpen(!isOpen);
    onClick?.();
  };

  useLayoutEffect(() => {
    if (accordionContentRef.current) {
      gsap
        .timeline()
        .to(accordionContentRef.current, { height: !isOpen ? '0' : 'auto', opacity: !isOpen ? 0 : 1, duration: 0.5 });
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [isOpen]);

  return (
    <Components.AccordionWrapper className={isOpen ? 'accordion--is-open' : ''}>
      <Components.AccordionHeader>
        <button type="button" onClick={handleAccordionHeaderClick}>
          {title}
          <Components.AccordionHeaderSymbol />

          <Components.AccordionHeaderSymbol />
        </button>
      </Components.AccordionHeader>
      <Components.AccordionContent className="accordion__accordion-content" ref={accordionContentRef}>
        <Components.AccordionContentInner>{children}</Components.AccordionContentInner>
      </Components.AccordionContent>
    </Components.AccordionWrapper>
  );
}

Accordion.defaultProps = {
  title: 'Accordion Title',
  children: <p>Accordion content</p>,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
};
