import Components from './styles';
import VendorPortalPanelType from './types';

export default function VendorPortalPanel(props: VendorPortalPanelType) {
  const { subheadline, children } = props;
  return (
    <Components.Container>
      <Components.Headline>Vendor Portal</Components.Headline>
      {subheadline ? <Components.Subheadline>{subheadline}</Components.Subheadline> : null}
      <Components.Inner>{children}</Components.Inner>
    </Components.Container>
  );
}
