/* eslint-disable @typescript-eslint/no-explicit-any */
import { isArray } from 'lodash';
import { SectionTitle as Title, SectionDescription as Description } from '../../ReviewGridStep/styles';
import { Container } from './styles';
import { ReviewStep8PropsType } from './types';
import AttachedFilesList from '../../../../components/AttachedFilesList';

export default function ReviewStep8(props: ReviewStep8PropsType) {
  const { fieldValues: businessClassificationData } = props;
  const { businessClassificationCategories } = businessClassificationData;

  if (!businessClassificationCategories.length) {
    return null;
  }
  const parseAttachedFiles = (dataToParse: any[] | undefined) => {
    let dataArr;
    if (isArray(dataToParse)) {
      dataArr = dataToParse;
    } else {
      dataArr = (dataToParse as any).data;
    }

    return dataArr.map((file: Record<string, any>) => {
      return {
        name: file?.attributes?.name || file?.name || 'attached file',
        url: file?.attributes?.url || file?.url || '',
      };
    });
  };
  return (
    <>
      {businessClassificationCategories.map(cat => {
        return (
          <Container key={cat.internalId}>
            <Title>{cat.title}</Title>
            {cat.description ? <Description dangerouslySetInnerHTML={{ __html: cat.description }} /> : null}
            {(isArray(cat.relatedFiles) ? cat.relatedFiles : (cat.relatedFiles as any)?.data)?.length ? (
              <AttachedFilesList files={parseAttachedFiles(cat.relatedFiles)} />
            ) : null}
          </Container>
        );
      })}
    </>
  );
}
