import React from 'react';

export function VendorResource1() {
  return (
    <>
      <p>
        A person who is required to file an information return with the IRS must obtain your correct taxpayer
        identification number (TIN) to report, for example, income paid to you, real estate transactions, mortgage
        interest you paid, acquisition or abandonment of secured property, cancellation of debt, or contributions you
        made to an IRA, Individual/Sole proprietor, Corporation, Partnership. Advantage Solutions requests that this
        Vendor Information Form be used in lieu of IRS form W9.
      </p>
      <p>
        <strong>Note</strong>
      </p>
      <p>
        If a requester gives you a form other than the IRS Form W-9 to request your TIN, you must use the requester’s
        form. We consider this document to be substantially similar.
      </p>
    </>
  );
}

export default {
  title: 'Purpose of federal form',
  content: <VendorResource1 />,
};
