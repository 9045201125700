/* eslint-disable react/jsx-props-no-spreading */
import { useState, useEffect } from 'react';
import Components from './styles';
import ConfirmationIcon from './confirmationIcon.svg';

export type ProgressBarProps = {
  size: number;
  shouldDisplayBar: boolean;
};

export default function ProgressBar(props: ProgressBarProps) {
  const { size, shouldDisplayBar } = props;
  const [progressPercentage, setProgressPercentage] = useState<string>('0%');
  const [progress, setProgress] = useState<number>(0);
  const stepAdvanced = size > 3000000 ? 1500000 : 250000;

  useEffect(() => {
    const calculateProgress = () => {
      if (progress >= size) {
        setProgressPercentage('100%');
      } else {
        setProgress(prevState => prevState + stepAdvanced);
        const normalizedProgress = Math.floor((progress * 100) / size);
        setProgressPercentage(`${normalizedProgress}%`);
      }
    };
    let interval: string | number | NodeJS.Timeout | undefined;

    if (shouldDisplayBar) {
      interval = setInterval(calculateProgress, 500);
    } else {
      setProgressPercentage('100%');
    }
    return () => clearInterval(interval);
  }, [progress, shouldDisplayBar, size, stepAdvanced]);

  return (
    <Components.ProgressBarContainer>
      <Components.ProgressBar className={shouldDisplayBar ? '' : 'not-animated'} width={progressPercentage} />
      <Components.UploadStatusContainer justify={progressPercentage !== '100%' ? 'flex-end' : 'space-between'}>
        {progressPercentage === '100%' ? <Components.ConfirmationIcon src={ConfirmationIcon} /> : null}
        <Components.ShowProgress>Progress: {progressPercentage}</Components.ShowProgress>
      </Components.UploadStatusContainer>
    </Components.ProgressBarContainer>
  );
}
