import Components from './styles';
import AttachedFilesListProps from './types';
import CheckIcon from '../../assets/images/icon-file-check--3x.png';

export default function AttachedFilesList(props: AttachedFilesListProps) {
  const { files } = props;
  return files.length ? (
    <Components.FileList>
      {files.map(f => (
        <Components.FileListItem onClick={f.url ? () => window.open(f.url, '_blank') : () => null} key={f.name}>
          <img src={CheckIcon} alt="" />
          {f.name}
        </Components.FileListItem>
      ))}
    </Components.FileList>
  ) : null;
}
