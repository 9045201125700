import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.getValue(12)}px`} 0 0 0;
  min-width: ${({ theme }) => `${theme.getValue(295)}px`};
`;

export const Input = styled.input`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  width: ${({ theme }) => `${theme.getValue(30)}px`};
  height: ${({ theme }) => `${theme.getValue(36)}px`};
  padding: ${({ theme }) => `${theme.getValue(5)}px`} ${({ theme }) => `${theme.getValue(7)}px`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  border: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
  border-radius: 4px;
  &:focus,
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    opacity: 0.6;
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export const Error = styled.p`
  color: ${({ theme }) => `${theme.colors.orange1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export const NumbersContainer = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
`;

export const GroupNumbers = styled.div`
  display: flex;
  width: 100%;
  column-gap: ${({ theme }) => `${theme.getValue(10)}px`};
  align-items: center;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    column-gap: ${({ theme }) => `${theme.getValue(6)}px`};
  }
`;

export const SingleNumber = styled.input`
  width: ${({ theme }) => `${theme.getValue(30)}px`};
  height: ${({ theme }) => `${theme.getValue(36)}px`};
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  font-size: ${({ theme }) => `${theme.getValue(20)}px`};
  font-weight: bold;
  line-height: 1;
  padding: 0;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: 30px;
    height: 36px;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: 8.1355932203%;
    font-size: ${({ theme }) => `${theme.getValue(16)}px`};
    line-height: 1.2;
    padding: 0;
  }
  :focus {
    outline: none !important;
    border: 1px solid ${({ theme }) => `${theme.colors.primaryBlue}`};
    box-shadow: 0 0 10px #719ece;
  }
`;

export const SeparationBar = styled.div`
  display: flex;
  padding: ${({ theme }) => `0 ${theme.getValue(5)}px`};
  width: ${({ theme }) => `${theme.getValue(20)}px`};
  height: ${({ theme }) => `${theme.getValue(2)}px`};
  background: ${({ theme }) => `${theme.colors.gray2}`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: ${({ theme }) => `${theme.getValue(10)}px`};
    margin-left: ${({ theme }) => `${theme.getValue(-3)}px`};
    margin-right: ${({ theme }) => `${theme.getValue(-3)}px`};
  }
`;

export default { Container, Input, Label, Error, NumbersContainer, GroupNumbers, SingleNumber, SeparationBar };
