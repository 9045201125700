import MagGlassIcon from '../svgs/MagglassIcon';
import CloseIcon from '../svgs/CloseIcon';
import Components from './styles';
import SearchBarPropsType from './types';

function SearchBar(props: SearchBarPropsType) {
  const { value, handleChange, handleKeyDown, handleResetClick } = props;
  return (
    <Components.SearchInputWrapper>
      <Components.IconWrapper>
        <MagGlassIcon />
      </Components.IconWrapper>
      <Components.SearchInput
        placeholder="Search"
        type="text"
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      {value ? (
        <Components.ResetBtn type="button" onClick={handleResetClick}>
          <CloseIcon />
        </Components.ResetBtn>
      ) : null}
    </Components.SearchInputWrapper>
  );
}

export default SearchBar;
