import { useFormik } from 'formik';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { decrement, increment } from '../../store/slices/stepSlice';
import { setSustainabilityPolicy } from '../../store/slices/sustainabilityPolicySlice';
import Headline from '../../components/typography/Headline';
import Button from '../../components/Button';
import SustainabilityPolicyType, { Step5Schema as validationSchema } from './types';
import { ButtonSection } from '../Step2/styles';
import { Container, Label, PoliciesContainer, Headline2 } from './styles';
import CheckboxUpload from '../../components/CheckboxUpload';
import TextArea from '../../components/TextArea';
import { Error } from '../../components/Input/styles';
import CheckboxItem from '../../components/CheckboxItem';
import policies from './data/sustainabilityPolicies';
import sustainabilityLabels from './data/sustainabilityLabels';
import { RootState } from '../../store/store';
import useScrollToTop from '../stepUtils';

const stepUuid = uuid();

const booleanEntries = [
  {
    label: 'Yes',
  },
  {
    label: 'No',
  },
];

function Step5() {
  useScrollToTop();
  const sustainabilityPolicyData: SustainabilityPolicyType = useSelector(
    (state: RootState) => state.sustainabilityPolicy,
  );

  const dispatch = useDispatch();

  const step5HandleSubmit = (vals: SustainabilityPolicyType) => {
    dispatch(setSustainabilityPolicy(vals));
    dispatch(increment());
  };

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid } =
    useFormik({
      initialValues: sustainabilityPolicyData,
      onSubmit: vals => {
        step5HandleSubmit(vals);
      },
      validationSchema,
      validateOnMount: false,
    });

  useEffect(() => {
    if (values.hasNonePolicies) {
      setFieldValue('hasSustainanbilityPolicy', false);
      setFieldValue('hasEnvironmentPolicy', false);
      setFieldValue('hasSocialResponsibilityPolicy', false);
      setFieldValue('hasSustainabilityProcurementPolicy', false);
    }
  }, [values.hasNonePolicies, setFieldValue]);

  useEffect(() => {
    if (
      values.hasEnvironmentPolicy ||
      values.hasSocialResponsibilityPolicy ||
      values.hasSustainabilityProcurementPolicy ||
      values.hasSustainanbilityPolicy
    ) {
      setFieldValue('hasNonePolicies', false);
    }
  }, [
    values.hasEnvironmentPolicy,
    values.hasSocialResponsibilityPolicy,
    values.hasSustainabilityProcurementPolicy,
    values.hasSustainanbilityPolicy,
    setFieldValue,
  ]);

  useEffect(() => {
    if (
      values.hasEnvironmentPolicy ||
      values.hasSocialResponsibilityPolicy ||
      values.hasSustainabilityProcurementPolicy ||
      values.hasSustainanbilityPolicy ||
      values.hasNonePolicies
    ) {
      setFieldValue('hasSelectedPolicies', true);
    } else {
      setFieldValue('hasSelectedPolicies', false);
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [
    values.hasEnvironmentPolicy,
    values.hasSocialResponsibilityPolicy,
    values.hasSustainabilityProcurementPolicy,
    values.hasSustainanbilityPolicy,
    values.hasNonePolicies,
    setFieldValue,
  ]);

  return (
    <Container>
      <Headline>Sustainability Policies</Headline>
      <Label>
        Please provide the following information about your business&apos; sustainability policies. For those selected,
        please provide PDF copies of all relevant policies. Answers are required for all fields.
      </Label>
      <PoliciesContainer>
        <CheckboxUpload
          key={`${stepUuid}-${policies[0].title.replace(' ', '-').toLowerCase()}`}
          name={policies[0].title}
          title={policies[0].title}
          value={policies[0].id}
          description=""
          enableUploadControl={values.hasSustainanbilityPolicy}
          uploadLabel="Please upload your Sustainability Policy*"
          onChange={() => {
            setFieldValue('hasSustainanbilityPolicy', !values.hasSustainanbilityPolicy);
            setFieldTouched('hasSustainanbilityPolicy', !values.hasSustainanbilityPolicy);
            setFieldTouched('hasSelectedPolicies', true);
          }}
          filesUploaded={values.sustainabilityPolicyFiles}
          filesLocation="sustainabilityPolicyFiles"
          saveFiles={setFieldValue}
          isChecked={values.hasSustainanbilityPolicy}
          touched={touched.hasSustainanbilityPolicy}
          error={errors.hasSustainanbilityPolicy}
          filesErrors={errors.sustainabilityPolicyFiles}
        />
        <CheckboxUpload
          key={`${stepUuid}-${policies[1].title.replace(' ', '-').toLowerCase()}`}
          name={policies[1].title}
          title={policies[1].title}
          value={policies[1].id}
          description=""
          enableUploadControl={values.hasEnvironmentPolicy}
          uploadLabel="Please upload your Environment Policy*"
          onChange={() => {
            setFieldValue('hasEnvironmentPolicy', !values.hasEnvironmentPolicy);
            setFieldTouched('hasEnvironmentPolicy', !values.hasEnvironmentPolicy);
            setFieldTouched('hasSelectedPolicies', true);
          }}
          filesUploaded={values.environmentPolicyFiles}
          filesLocation="environmentPolicyFiles"
          saveFiles={setFieldValue}
          isChecked={values.hasEnvironmentPolicy}
          touched={touched.hasEnvironmentPolicy}
          error={errors.hasEnvironmentPolicy}
          filesErrors={errors.environmentPolicyFiles}
        />
        <CheckboxUpload
          key={`${uuid}-${policies[2].title.replace(' ', '-').toLowerCase()}`}
          name={policies[2].title}
          title={policies[2].title}
          value={policies[2].id}
          description=""
          enableUploadControl={values.hasSocialResponsibilityPolicy}
          uploadLabel="Please upload your Social Responsibility Policy*"
          onChange={() => {
            setFieldValue('hasSocialResponsibilityPolicy', !values.hasSocialResponsibilityPolicy);
            setFieldTouched('hasSocialResponsibilityPolicy', !values.hasSocialResponsibilityPolicy);
            setFieldTouched('hasSelectedPolicies', true);
          }}
          filesUploaded={values.socialResponsibilityFiles}
          filesLocation="socialResponsibilityFiles"
          saveFiles={setFieldValue}
          isChecked={values.hasSocialResponsibilityPolicy}
          touched={touched.hasSocialResponsibilityPolicy}
          error={errors.hasSocialResponsibilityPolicy}
          filesErrors={errors.socialResponsibilityFiles}
        />
        <CheckboxUpload
          key={`${uuid}-${policies[3].title.replace(' ', '-').toLowerCase()}`}
          name={policies[3].title}
          title={policies[3].title}
          value={policies[3].id}
          description=""
          enableUploadControl={values.hasSustainabilityProcurementPolicy}
          uploadLabel="Please upload your Sustainability Procurement/Purchasing Policy*"
          onChange={() => {
            setFieldValue('hasSustainabilityProcurementPolicy', !values.hasSustainabilityProcurementPolicy);
            setFieldTouched('hasSustainabilityProcurementPolicy', !values.hasSustainabilityProcurementPolicy);
            setFieldTouched('hasSelectedPolicies', true);
          }}
          filesUploaded={values.sustainabilityProcurementFiles}
          filesLocation="sustainabilityProcurementFiles"
          saveFiles={setFieldValue}
          isChecked={values.hasSustainabilityProcurementPolicy}
          touched={touched.hasSustainabilityProcurementPolicy}
          error={errors.hasSustainabilityProcurementPolicy}
          filesErrors={errors.sustainabilityProcurementFiles}
        />
        <CheckboxUpload
          key={`${uuid}-${policies[4].title.replace(' ', '-').toLowerCase()}`}
          name={policies[4].title}
          title={policies[4].title}
          value={policies[4].id}
          description=""
          onChange={() => {
            setFieldValue('hasNonePolicies', !values.hasNonePolicies);
            setFieldTouched('hasNonePolicies', !values.hasNonePolicies);
            setFieldTouched('hasSelectedPolicies', true);
          }}
          isChecked={values.hasNonePolicies}
          touched={touched.hasNonePolicies}
          error={errors.hasNonePolicies}
        />
      </PoliciesContainer>
      {errors.hasSelectedPolicies && touched.hasSelectedPolicies ? <Error>{errors.hasSelectedPolicies}</Error> : null}
      <Headline2>{sustainabilityLabels[0].title}</Headline2>
      <Label dangerouslySetInnerHTML={{ __html: sustainabilityLabels[0].label }} />
      {booleanEntries.map(entry => (
        <CheckboxItem
          key={`${uuid}-hasSustainabilityResponsibility-${entry?.label}`}
          name="hasSustainabilityResponsibility"
          title={entry?.label}
          description=""
          value={entry?.label}
          onClick={() => {
            setFieldValue('hasSustainabilityResponsibility', entry?.label);
          }}
          onBlur={handleBlur}
          isChecked={entry?.label === values.hasSustainabilityResponsibility}
          touched={touched.hasSustainabilityResponsibility}
          error={errors.hasSustainabilityResponsibility}
        >
          {values.hasSustainabilityResponsibility === 'Yes' && (
            <TextArea
              id="sustainabilityResponsibility"
              name="sustainabilityResponsibility"
              label={sustainabilityLabels[0].inputLabel}
              value={values.sustainabilityResponsibility}
              error={errors.sustainabilityResponsibility}
              touched={touched.sustainabilityResponsibility}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </CheckboxItem>
      ))}
      <Headline2>{sustainabilityLabels[1].title}</Headline2>
      <Label dangerouslySetInnerHTML={{ __html: sustainabilityLabels[1].label }} />
      {booleanEntries.map(entry => (
        <CheckboxItem
          key={`${uuid}-hasIdentifiedImpacts-${entry?.label}`}
          name="hasIdentifiedImpacts"
          title={entry?.label}
          description=""
          value={entry?.label}
          onClick={() => {
            setFieldValue('hasIdentifiedImpacts', entry?.label);
          }}
          onBlur={handleBlur}
          isChecked={entry?.label === values.hasIdentifiedImpacts}
          touched={touched.hasIdentifiedImpacts}
          error={errors.hasIdentifiedImpacts}
        >
          {values.hasIdentifiedImpacts === 'Yes' && (
            <TextArea
              id="impactsIdentifiedList"
              name="impactsIdentifiedList"
              label={sustainabilityLabels[1].inputLabel}
              value={values.impactsIdentifiedList}
              error={errors.impactsIdentifiedList}
              touched={touched.impactsIdentifiedList}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </CheckboxItem>
      ))}
      <Headline2>{sustainabilityLabels[2].title}</Headline2>
      <Label dangerouslySetInnerHTML={{ __html: sustainabilityLabels[2].label }} />
      {booleanEntries.map(entry => (
        <CheckboxItem
          key={`${uuid}-hasEnvironmentalManagementStandards-${entry?.label}`}
          name="hasEnvironmentalManagementStandards"
          title={entry?.label}
          description=""
          value={entry?.label}
          onClick={() => {
            setFieldValue('hasEnvironmentalManagementStandards', entry?.label);
          }}
          onBlur={handleBlur}
          isChecked={entry?.label === values.hasEnvironmentalManagementStandards}
          touched={touched.hasEnvironmentalManagementStandards}
          error={errors.hasEnvironmentalManagementStandards}
        >
          {values.hasEnvironmentalManagementStandards === 'Yes' && (
            <TextArea
              id="managementStandardsDetails"
              name="managementStandardsDetails"
              label={sustainabilityLabels[2].inputLabel}
              value={values.managementStandardsDetails}
              error={errors.managementStandardsDetails}
              touched={touched.managementStandardsDetails}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          )}
        </CheckboxItem>
      ))}
      <ButtonSection>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Back
        </Button>
        <Button
          disabled={!isValid}
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </ButtonSection>
    </Container>
  );
}

export default Step5;
