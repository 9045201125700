import { CompletedIndicatorWrapper } from './styles';

export default function CompletedIndicator(props: { completed: boolean }) {
  const { completed } = props;
  return (
    <CompletedIndicatorWrapper completed={completed}>
      {completed ? <span>Completed</span> : <span>Pending</span>}
    </CompletedIndicatorWrapper>
  );
}
