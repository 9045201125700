import React, { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Components from './styles';
import CheckboxItemType from './types';

export default function CheckboxItem(props: CheckboxItemType & React.InputHTMLAttributes<HTMLInputElement>) {
  const uuid = uuidv4();
  const { title, description, name, isChecked, children, onClick, onChange } = props;
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLInputElement & HTMLLabelElement>) => {
      onClick?.(e);
    },
    [onClick],
  );

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
    },
    [onChange],
  );
  return (
    <Components.Container checked={isChecked as boolean}>
      <Components.Label className={isChecked ? 'is-checked' : ''} htmlFor={uuid} onClick={handleClick}>
        <Components.RadioWrapper>
          <Components.Checkbox checked={isChecked} id={uuid} type="radio" name={name || uuid} onChange={handleChange} />
          <Components.Control />
        </Components.RadioWrapper>
        <Components.Content>
          <Components.Title>{title}</Components.Title>
          {description ? <Components.Description>{description}</Components.Description> : null}
        </Components.Content>
      </Components.Label>
      {isChecked && children && (
        <Components.Inner className={isChecked ? 'is-visible' : ''}>{children}</Components.Inner>
      )}
    </Components.Container>
  );
}

CheckboxItem.defaultProps = {
  title: 'Checkbox Title',
  description: 'Including Dentistry, Podiatry, Psychotherapy, Optometry, Chiropractic, etc.',
  isChecked: false,
  touched: false,
  error: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
} as Partial<CheckboxItemType>;
