import * as yup from 'yup';
import { REQUIRED_VALIDATION_MSG } from '../../constants';

export const Step3Schema = yup.object({
  specifyOther: yup.bool(),
  vendorActivity: yup.string().required(REQUIRED_VALIDATION_MSG),
  other: yup.string().when('specifyOther', {
    is: true,
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
  }),
});

export type VendorActivityType = yup.InferType<typeof Step3Schema>;

export default VendorActivityType;
