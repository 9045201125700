import { useSearchParams } from 'react-router-dom';
import Components from './styles';
import steps from '../stepList';
import Step2Form from '../Step2/Step2FormPrint';
import ReviewStep3 from '../ReviewGrid/steps/ReviewStep3/printView';
import ReviewStep4 from '../ReviewGrid/steps/ReviewStep4/printView';
import ReviewStep5 from '../ReviewGrid/steps/ReviewStep5/printView';
import ReviewStep6 from '../ReviewGrid/steps/ReviewStep6/printView';
import ReviewStep7 from '../ReviewGrid/steps/ReviewStep7/printView';
import ReviewStep8 from '../ReviewGrid/steps/ReviewStep8/printView';
import ReviewStep9 from '../ReviewGrid/steps/ReviewStep9/printView';

import { ReviewGridPropsType } from './types';
import useSubmissionInfoWithUuid from '../../hooks/useSubmissionInfoWithUuid';

export default function ReviewGrid(props: ReviewGridPropsType) {
  const { submission } = props;

  const [searchParams] = useSearchParams();
  const uuid = searchParams.get('uuid') || '';
  const formInfo = useSubmissionInfoWithUuid(uuid, `${process.env.REACT_APP_API_TOKEN}`);
  const { error } = formInfo;

  if (error) {
    console.error(error);
  }

  const Step2 = (
    <Components.Step1 title={steps[1]?.title}>
      <Step2Form fieldValues={submission.gBI} disableAll />
    </Components.Step1>
  );
  const Step3 = (
    <Components.Step2 title={steps[2]?.title} showBorder>
      <ReviewStep3 fieldValues={submission.vendorActivity} />
    </Components.Step2>
  );
  const Step4 = (
    <Components.Step3 title={steps[3]?.title} showBorder>
      <ReviewStep4 fieldValues={submission.businessEntity} />
    </Components.Step3>
  );
  const Step5 = (
    <Components.Step4 title={steps[4]?.title}>
      <ReviewStep5 fieldValues={submission.sustainabilityPolicy} />
    </Components.Step4>
  );
  const Step6 = (
    <Components.Step5 title={steps[5]?.title} showBorder>
      <ReviewStep6 fieldValues={submission.irsForm} />
    </Components.Step5>
  );
  const Step7 = (
    <Components.Step6 title={steps[6]?.title} showBorder>
      <ReviewStep7 fieldValues={submission.conflictOfInterest} />
    </Components.Step6>
  );
  const Step8 = (
    <Components.Step7 title={steps[7]?.title} showBorder>
      <ReviewStep8 fieldValues={submission?.businessClassification} />
    </Components.Step7>
  );
  const Step9 = (
    <Components.Step9 title={steps[8]?.title} showBorder>
      <ReviewStep9 fieldValues={submission.responsabilityCertification} />
    </Components.Step9>
  );
  return (
    <Components.GridContainer className="grid-container--is-print">
      <Components.Col1>
        {Step2}
        {Step3}
        {Step4}
        {Step5}
        {Step6}
        {Step7}
        {Step8}
        {Step9}
      </Components.Col1>
    </Components.GridContainer>
  );
}
