import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';

const Nav = styled.nav``;
const NavList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
`;
const NavItem = styled.li`
  color: white;
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  & + & {
    margin-left: ${({ theme }) => `${theme.getValue(34)}px`};
  }
  a {
    color: ${({ theme }) => `${theme.colors.white}`};
    text-decoration: none;
    &:visited {
      color: ${({ theme }) => `${theme.colors.white}`};
    }
    &.active {
      color: ${({ theme }) => `${theme.colors.primaryBlue}`};
    }
  }
`;

const StyledNavLink = styled(NavLink)`
  color: ${({ theme }) => `${theme.colors.white}`};
  text-decoration: none;
  &:visited {
    color: ${({ theme }) => `${theme.colors.white}`};
  }
  &.active {
    color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  }
`;

const StyledNavButton = styled.button`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  color: ${({ theme }) => `${theme.colors.white}`};
  text-decoration: none;
  &:hover {
    cursor: pointer;
  }
  &:visited {
    color: ${({ theme }) => `${theme.colors.white}`};
  }
  &.active {
    color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  }
`;

export default { Nav, NavList, NavItem, StyledNavLink, StyledNavButton };
