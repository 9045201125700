import { useState } from 'react';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-named-default
import { default as Page } from '../../../components/Page/AuthPage';
import ResetPasswordForm from '../../../components/admin/ResetPasswordForm';
import Components from './styles';

export default function ResetPasswordScreen() {
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  return (
    <Page subtitle={!showSuccessMsg ? 'To reset your password, please create a new one below.' : 'Good news!'}>
      {!showSuccessMsg ? (
        <ResetPasswordForm setShowSuccess={setShowSuccessMsg} />
      ) : (
        <Components.Container>
          <p>Your password was successfully updated!</p>
          <p>
            <Link to="/login">Log in now</Link>
          </p>
        </Components.Container>
      )}
    </Page>
  );
}
