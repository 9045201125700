import styled from 'styled-components/macro';
import Input from '../Input';

const SearchInputWrapper = styled.div`
  position: relative;
  svg {
    display: block;
    width: 100%;
    height: 100%;
  }
`;

const SearchInput = styled(Input)`
  position: relative;
  margin: 0;
  input {
    padding-left: ${({ theme }) => `${theme.getValue(33)}px`};
    padding-right: ${({ theme }) => `${theme.getValue(22)}px`};
  }
  z-index: 1;
`;

const IconWrapper = styled.div`
  position: absolute;
  width: ${({ theme }) => `${theme.getValue(13)}px`};
  height: ${({ theme }) => `${theme.getValue(14)}px`};
  top: 50%;
  left: ${({ theme }) => `${theme.getValue(12)}px`};
  transform: translateY(-50%);
  z-index: 2;
`;

const ResetBtn = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  width: ${({ theme }) => `${theme.getValue(14)}px`} !important; // conflict with Input button
  height: ${({ theme }) => `${theme.getValue(14)}px`};
  z-index: 2;
  position: absolute;
  right: ${({ theme }) => `${theme.getValue(8)}px`};
  top: 50%;
  background: none;
  transform: translateY(-50%);
  margin-bottom: 0 !important;
  &:hover {
    cursor: pointer;
  }
  svg {
    display: block;
    width: 100%;
  }
`;

export default { SearchInputWrapper, SearchInput, IconWrapper, ResetBtn };
