import styled from 'styled-components/macro';
import Headline from '../../../../components/typography/Headline';

const Title = styled(Headline)`
  margin: 0;
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
`;

export default { Title };
