import styled from 'styled-components/macro';

const AccordionWrapper = styled.div`
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(40)}px`};
  }
`;

const AccordionHeader = styled.h2`
  margin: 0;
  button {
    display: block;
    position: relative;
    border: 0;
    background-color: transparent;
    padding: 0;
    text-transform: capitalize;
    font-size: ${({ theme }) => `${theme.getValue(20)}px`};
    line-height: 1;
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    color: ${({ theme }) => `${theme.colors.primaryBlue}`};
    font-weight: ${({ theme }) => `${theme.weights.bold}`};
    width: 100%;
    text-align: left;
    padding-right: ${({ theme }) => `${theme.getValue(12)}px`};
    &:hover {
      cursor: pointer;
    }
  }
`;

const AccordionHeaderSymbol = styled.div`
  position: absolute;
  width: ${({ theme }) => `${theme.getValue(12)}px`};
  height: ${({ theme }) => `${theme.getValue(12)}px`};
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  &:after,
  &:before {
    content: '';
    width: 100%;
    height: ${({ theme }) => `${theme.getValue(3)}px`};
    background-color: ${({ theme }) => `${theme.colors.gray7}`};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
  }
  &:after {
    transform: translateY(-50%) rotate(90deg);
    transition: transform 0.3s linear;
    .accordion--is-open & {
      transform: translateY(-50%) rotate(360deg);
    }
  }
`;

const AccordionContent = styled.div`
  p {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-size: ${({ theme }) => `${theme.getValue(14)}px`};
    line-height: ${({ theme }) => `${theme.getValue(21)}px`};
    letter-spacing: -0.015em;
    margin-top: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }

  overflow: hidden;
  height: 0;
  opacity: 0;
`;
const AccordionContentInner = styled.div`
  padding-top: ${({ theme }) => `${theme.getValue(20)}px`};
  padding-bottom: ${({ theme }) => `${theme.getValue(20)}px`};
  border-bottom: solid 1px ${({ theme }) => `${theme.colors.gray8}`};
`;

export default { AccordionWrapper, AccordionHeader, AccordionHeaderSymbol, AccordionContent, AccordionContentInner };
