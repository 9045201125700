import styled from 'styled-components/macro';

const Header = styled.header`
  height: ${({ theme }) => `${theme.getValue(80)}px`};
  background-color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  padding: ${({ theme }) => `${theme.getValue(14)}px`} 0;
  padding-left: ${({ theme }) => `${theme.getValue(68)}px`};
  padding-right: ${({ theme }) => `${theme.getValue(100)}px`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    height: ${({ theme }) => `${theme.getValue(68)}px`};
    padding-left: ${({ theme }) => `${theme.getValue(30)}px`};
    padding-right: ${({ theme }) => `${theme.getValue(30)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    padding: ${({ theme }) => `${theme.getValue(7)}px`} 0;
    padding-left: ${({ theme }) => `${theme.getValue(20)}px`};
    padding-right: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(50)}px`};
  }
  @media print {
    display: none;
  }
`;

const Logo = styled.button<{ isClickable: boolean }>`
  margin: 0;
  padding: 0;
  border: 0;
  background-color: transparent;
  width: ${({ theme }) => `${theme.getValue(219)}px`};
  height: ${({ theme }) => `${theme.getValue(51)}px`};
  &:hover {
    cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'default')};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: ${({ theme }) => `${theme.getValue(172)}px`};
    height: ${({ theme }) => `${theme.getValue(40)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: ${({ theme }) => `${theme.getValue(150)}px`};
    height: ${({ theme }) => `${theme.getValue(36)}px`};
  }
  img {
    display: block;
    height: auto;
    width: 100%;
  }
`;

export default { Header, Logo };
