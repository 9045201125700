import styled from 'styled-components/macro';

const Container = styled.div<{ checked: boolean }>`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  border-radius: 4px;
  border: solid 1px ${({ theme, checked }) => (checked ? `${theme.colors.blue1}` : `${theme.colors.gray4}`)};
  padding: ${({ theme }) => `${theme.getValue(16)}px`};
  &::last-child {
    margin-bottom: 0;
  }
`;

const Header = styled.header`
  display: flex;
  gap: ${({ theme }) => `${theme.getValue(16)}px`};
  align-items: center;
`;

const AccordionBtn = styled.button`
  width: ${({ theme }) => `${theme.getValue(12)}px`};
  height: ${({ theme }) => `${theme.getValue(12)}px`};
  background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M10.5 4.5H7.5V1.5C7.5 0.671573 6.82843 0 6 0C5.17157 0 4.5 0.671573 4.5 1.5V4.5H1.5C0.671573 4.5 0 5.17157 0 6C0 6.82843 0.671573 7.5 1.5 7.5H4.5V10.5C4.5 11.3284 5.17157 12 6 12C6.82843 12 7.5 11.3284 7.5 10.5V7.5H10.5C11.3284 7.5 12 6.82843 12 6C12 5.17157 11.3284 4.5 10.5 4.5Z' fill='%23D9D9D9'/%3E%3C/svg%3E");
  background-color: transparent;
  background-repeat: no-repeat;
  flex: 0 0 auto;
  border: 0;
  justify-self: flex-end;
  &:hover {
    cursor: pointer;
  }
  &.is-open {
    transform: rotate(45deg);
  }
`;

const Description = styled.p`
  font-family: 'Proxima Nova';
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(21)}px`};
  margin: ${({ theme }) => `${theme.getValue(10)}px 0`};
  color: ${({ theme }) => `${theme.colors.gray2}`};

  p {
    margin: 0;
  }

  a {
    color: ${({ theme }) => `${theme.colors.gray2}`};
    font-weight: ${({ theme }) => `${theme.weights.bold}`};
  }
`;

const Title = styled.h3`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  margin: 0;
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  width: 100%;
  strong {
    font-weight: ${({ theme }) => `${theme.weights.extrabold}`};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => `0 ${theme.getValue(36)}px`};
  &.is-collapsed {
    display: none;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    padding-right: 0;
  }
`;

const CheckboxStyled = styled.input``;

const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  position: relative;
  gap: ${({ theme }) => `${theme.getValue(16)}px`};
`;

const IconContainer = styled.div`
  cursor: pointer;
  &:last-child {
    margin-left: auto;
  }
`;

const ExpandableIcon = styled.p`
  margin: 0;
  color: ${({ theme }) => `${theme.colors.gray7}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}, Proxima Nova`};
  font-size: ${({ theme }) => `${theme.getValue(25)}px`};
`;

export default {
  AccordionBtn,
  Header,
  CheckboxStyled,
  CheckboxWrapper,
  Description,
  Title,
  Content,
  Container,
  IconContainer,
  ExpandableIcon,
};
