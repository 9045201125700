import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setBusinessUnits } from '../store/slices/businessUnitsSlice';

type useBusinessUnitsProps = {
  authToken: string;
};

const useBusinessUnits = (props: useBusinessUnitsProps) => {
  const { authToken } = props;
  const [currentBusinessUnits, setCurrentBusinessUnits] = useState(['']);
  const dispatch = useDispatch();

  useEffect(() => {
    const getBusinessUnits = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/business-units?sort=order`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        const { data: results } = data;
        if (results.length) {
          const newUnits = ['', ...results.map((bUnit: { attributes: { name: string } }) => bUnit.attributes.name)];
          setCurrentBusinessUnits(newUnits);
          dispatch(setBusinessUnits(newUnits));
        }
      } catch (error) {
        console.error(error);
      }
    };
    getBusinessUnits();
  }, [authToken, dispatch]);

  return currentBusinessUnits;
};

export default useBusinessUnits;
