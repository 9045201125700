import React from 'react';
import Components from './styles';
import { VendorFilterPillProps } from './types';

function VendorFilterPill(props: VendorFilterPillProps) {
  const { children, onPillClick } = props;
  return (
    <Components.Pill>
      {children}
      <Components.PillBtn
        onClick={() => {
          onPillClick();
        }}
      >
        <span>Close</span>
      </Components.PillBtn>
    </Components.Pill>
  );
}

export default VendorFilterPill;
