import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { ResetPasswordFieldsType, ResetPasswordFormPropsType, ResetPasswordSchema } from './types';
import Input from '../../Input';
import Button from '../../Button';
import { Error as ErrorMsg } from '../../Input/styles';
import Components from './styles';

const initialValues: ResetPasswordFieldsType = {
  password: '',
  confirmPassword: '',
};
export default function ResetPasswordForm(props: ResetPasswordFormPropsType) {
  const { setShowSuccess } = props;
  const [searchParams] = useSearchParams();
  const token = searchParams.get('code');
  const [serverErr, setServerErr] = useState<string>('');

  const { values, errors, touched, isValid, handleChange, handleBlur, handleSubmit } = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    validateOnMount: true,
    onSubmit: async (vals: ResetPasswordFieldsType) => {
      try {
        const resetPasswordResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth/reset-password`, {
          method: 'POST',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            code: token,
            password: vals.password,
            passwordConfirmation: vals.confirmPassword,
          }),
        });
        const resetPasswordData = await resetPasswordResponse.json();
        const { user, error } = resetPasswordData;
        if (error) throw error;
        if (user) {
          // Show confirmation
          setShowSuccess(true);
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error(error);
        setServerErr(error?.message || error || 'Something went wrong. Please try again later or contact support');
      }
    },
  });

  return (
    <>
      <Components.FormWrapper>
        <Input
          id="password"
          name="password"
          value={values.password}
          touched={touched.password}
          error={errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          label="New password*"
        />
        <Input
          id="confirmPassword"
          name="confirmPassword"
          value={values.confirmPassword}
          touched={touched.confirmPassword}
          error={errors.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          label="Confirm new password*"
        />
      </Components.FormWrapper>
      <Components.ButtonGroup>
        <Button
          disabled={!isValid}
          onClick={() => {
            handleSubmit();
          }}
        >
          Reset Password
        </Button>
        {serverErr ? <ErrorMsg>{serverErr}</ErrorMsg> : null}
      </Components.ButtonGroup>
    </>
  );
}
