import styled from 'styled-components/macro';
import LandingComponents from '../Landing/styles';
import { visuallyHidden } from '../../../styles/mixins';

const TableLink = styled(LandingComponents.TableLink)`
  text-decoration: none;
  font-weight: normal;
  padding: 0;
  margin: 0;
  background: transparent;
  border: 0;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const MoreButtonWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const MoreButton = styled.button`
  background: none;
  border: 0;
  width: 100%;
  width: ${({ theme }) => `${theme.getValue(26)}px`};
  padding: 0;
  display: flex;
  justify-content: center;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    margin: 0 ${({ theme }) => `${theme.getValue(10)}px`};
  }
  &:hover {
    cursor: pointer;
  }
`;

const MoreOptionsButton = styled.button`
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  text-decoration: none;
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 1.5;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(5)}px`};
  }
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const MoreOptionsWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: ${({ theme }) => `${theme.colors.white}`};
  padding: ${({ theme }) => `${theme.getValue(10)}px`};
  border-radius: 4px;
  min-width: ${({ theme }) => `${theme.getValue(90)}px`};
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  z-index: 10;
`;

export const CompletedIndicatorWrapper = styled.div<{ completed: boolean }>`
  position: relative;
  padding-left: ${({ theme }) => `${theme.getValue(16)}px`};
  &:before,
  &:after {
    content: '';
    position: absolute;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme, completed }) => (completed ? `${theme.colors.green1}` : `${theme.colors.orange1}`)};
  }
  &:before {
    width: ${({ theme }) => `${theme.getValue(8)}px`};
    height: ${({ theme }) => `${theme.getValue(8)}px`};
    left: 0;
    opacity: 0.3;
  }
  &:after {
    width: ${({ theme }) => `${theme.getValue(4)}px`};
    height: ${({ theme }) => `${theme.getValue(4)}px`};
    left: ${({ theme }) => `${theme.getValue(2)}px`};
  }
  span {
    display: inline-block;
    margin-top: ${({ theme }) => `${theme.getValue(2)}px`};
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      ${visuallyHidden}
    }
  }
`;

const SortArrow = styled.img<{ direction: 'desc' | 'asc' }>`
  object-fit: contain;
  width: ${({ theme }) => `${theme.getValue(24)}px`};
  height: ${({ theme }) => `${theme.getValue(6)}px`};
  position: relative;
  transform: rotate(${({ direction }) => (direction === 'desc' ? 0 : 180)}deg);
`;

export default {
  MoreButtonWrapper,
  MoreButton,
  TableLink,
  MoreOptionsButton,
  MoreOptionsWrapper,
  CompletedIndicatorWrapper,
  SortArrow,
};
