import { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Footer from '../Footer';
import AdminFooter from '../AdminFooter';
import Header from '../Header';
import Components from './styles';
import PageType from './types';
import { setScrollableRef } from '../../store/slices/stepSlice';

function renderInnerContent(props: PageType) {
  const { layout, children, sidebar } = props;

  switch (layout) {
    case 'single':
      return <Components.ContentWrapper>{children}</Components.ContentWrapper>;
    case 'sidebar-col':
      return (
        <Components.TwoColLayout>
          <Components.Sidebar>{sidebar}</Components.Sidebar>
          <Components.Column>{children}</Components.Column>
        </Components.TwoColLayout>
      );
    case 'review-grid':
      return <Components.ContentWrapper>{children}</Components.ContentWrapper>;

    default:
      return <Components.ContentWrapper>{children}</Components.ContentWrapper>;
  }
}

export default function Page(props: PageType) {
  const { showHeader, layout, headerContent, className, admin } = props;
  const scrollableRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!scrollableRef?.current) return;
    dispatch(setScrollableRef(scrollableRef.current));
  }, [dispatch]);

  return (
    <Components.PageContainer className={className}>
      <Components.ContentContainer id="content" ref={scrollableRef}>
        {showHeader && <Header rightSection={headerContent} />}
        <Components.Main hasHeader={showHeader}>{layout && renderInnerContent(props)}</Components.Main>
      </Components.ContentContainer>
      {admin ? <AdminFooter /> : <Footer />}
    </Components.PageContainer>
  );
}

Page.defaultProps = {
  showHeader: true,
  admin: false,
  children: null,
  sidebar: null,
  layout: 'single',
  headerContent: null,
} as Partial<PageType>;
