import { memo } from 'react';

function CloseIcon() {
  return (
    <svg viewBox="0 0 14 14" fill="none">
      <path d="M1 13L13 1M1 1l12 12" stroke="#9CA3AF" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  );
}

const MemoCloseIcon = memo(CloseIcon);
export default MemoCloseIcon;
