import styled from 'styled-components/macro';

export const FileList = styled.ul`
  padding: 0;
  list-style-type: none;
  margin: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(12)}px`};
`;

export const FileListItem = styled.li`
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  color: ${({ theme }) => `${theme.colors.gray2}`};
  cursor: pointer;
  line-height: 150%;
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  .grid-container--is-print & {
    font-size: 8px;
    color: ${({ theme }) => `${theme.colors.black}`};
  }
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
    .grid-container--is-print & {
      margin-top: 8px;
    }
  }
  img {
    width: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(16)}px`};
    margin-right: ${({ theme }) => `${theme.getValue(8)}px`};
    display: block;
    flex: 0 0 auto;
    .grid-container--is-print & {
      width: 12px;
      height: 12px;
      margin-right: 4px;
    }
  }
`;

export default { FileList, FileListItem };
