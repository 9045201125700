export default [
  {
    internalId: '1',
    title: 'Large Business (LG)',
    description:
      '<p>A large business is a domestic business that does not meet the small business size standard as per the Small Business Administration (SBA) definition. The threshold for number of employees and revenue varies depending on the product and/or service. Refer to <a href="https://www.sba.gov/size" target="_blank" rel="noopener noreferrer">www.sba.gov/size</a> in order to determine status.</p>',
    uploadLabel: '',
    requireFiles: false,
  },
  {
    internalId: '2',
    title: 'Small Business (SM)',
    description:
      '<p>A small business concern is “one that is independently owned and operated and is not dominant in its field of operation”. The Small Business Administration (SBA) has established a table of size standards matched to the North American Industrial Classification (NAICS) codes and standards. Refer to <a href="https://www.sba.gov/size" target="_blank" rel="noopener noreferrer">www.sba.gov/size</a> in order to determine status. <strong>Businesses meeting the NAICS criteria may self-certify.</strong></p>',
    uploadLabel: '',
    requireFiles: false,
  },
  {
    internalId: '3',
    title: 'Small Disadvantage Owned Business (SD)',
    description:
      '<p>A small disadvantaged owned business must be owned by at least one or more disadvantaged individual(s), or in the case of a publicly owned business, at least 51% of the stock must be owned by one or more disadvantaged individual(s), and whose management and daily operations are controlled by one or more disadvantaged individual(s). Businesses meeting these criteria must be certified by a federal, state, or local government agency as having met the standards consistent with 13 CFR Part 124, Subpart B.</p>',
    uploadLabel: 'Upload Certification Documents',
    requireFiles: true,
  },
  {
    internalId: '4',
    title: 'Small Woman Owned Business (SW)',
    description:
      '<p>A small woman owned business must be owned by one or more women, or in the case of a publicly owned business, at least 51% of the stock must be owned by one or more women, and whose management and daily operations are controlled by one or more women. Businesses meeting these criteria must be certified by a federal, state, or local government agency as having met the standards criteria.</p>',
    uploadLabel: 'Upload Certification Documents',
    requireFiles: true,
  },
  {
    internalId: '5',
    title: 'Small Veteran Owned Business (SV)',
    description:
      '<p>A small veteran owned business must be owned by one or more veterans, or in the case of a publicly owned business, at least 51% of the stock must be owned by one or more veterans, and whose management and daily operations are controlled by one or more veterans. Businesses meeting these criteria must be certified by the Veterans Administration.</p>',
    uploadLabel: 'Upload Certification Documents',
    requireFiles: true,
  },
  {
    internalId: '6',
    title: 'Small Service Disabled Veteran Owned Business (S2)',
    description:
      '<p>A small service disabled veteran owned business must be owned by one or more service disabled veteran(s), or in the case of a publicly owned business, at least 51% of the stock must be owned by one or more service disabled veteran(s), and whose management and daily operations are controlled by one or more service disabled veteran(s). Businesses meeting these criteria must be certified by the Veterans Administration.</p>',
    uploadLabel: 'Upload Certification Documents',
    requireFiles: true,
  },
  {
    internalId: '7',
    title: 'Historically Black College/University (HB)',
    description:
      '<p>A historically black college/university is an institution of higher education that meets the requirements of 34 CFR 608.2. Refer to U.S. Dept of Education web site <a href="https://www.ed.gov/about/inits/list/whhbcu/edlite-list.html" target="_blank" rel="noopener noreferrer">www.ed.gov/about/inits/list/whhbcu/edlite-list.html</a> for a complete listing.</p>',
    uploadLabel: 'Upload Certification Documents',
    requireFiles: true,
  },
  {
    internalId: '8',
    title: 'Minority Institutions (MI)',
    description:
      '<p>A minority institution is an institution of higher education that meets the requirements of the Higher Education Act of 1965, 20 U.S.C. 1135d-5(3), Section 1046(3). Refer to U.S. Dept. of Education web site <a href="https://www.ed.gov/about/offices/list/ocr/edlite-minorityinst-list-tab.html" target="_blank" rel="noopener noreferrer">www.ed.gov/about/offices/list/ocr/edlite-minorityinst-list-tab.html</a> for a complete listing.</p>',
    uploadLabel: '',
    requireFiles: false,
  },
  {
    internalId: '9',
    title: 'HUB Zone Underutilized Business (HZ)',
    description:
      '<p>A HUB Zone business is located in an underutilized business zone. The Small Business Administration (SBA) determines HUB Zones. Refer to SBA web site <a href="https://maps.certify.sba.gov/hubzone/map#center=35.683115,-84.429109&zoom=4" target="_blank" rel="noopener noreferrer">https://maps.certify.sba.gov/hubzone/map</a> to validate zones by entering a zip code.</p>',
    uploadLabel: '',
    requireFiles: false,
  },
  {
    internalId: '10',
    title: 'Foreign Owned Business (FS)',
    description:
      '<p>A foreign business is organized and exists under the laws of another country other than the U.S. The U.S. includes the 50 states, District of Columbia, Puerto Rico, Northern Marianna Islands, U.S. territories, and any other locations subject to U.S. jurisdiction.</p>',
    uploadLabel: '',
    requireFiles: false,
  },
  {
    internalId: '11',
    title: 'Other',
    description: '',
    uploadLabel: '',
    requireFiles: false,
  },
];
