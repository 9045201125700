import Page from '../../../components/Page';
import FormUtil from '../../../components/FormUtil';

export default function UtilScreen() {
  return (
    <Page layout="sidebar-col">
      <FormUtil />
    </Page>
  );
}
