import { useTheme } from 'styled-components';
import Page from '.';
import PageType from './types';
import { LogoContainer, AuthContainer } from './styles';
import VendorPortalPanel from '../VendorPortalPanel';
import useDimensions from '../../hooks/useDimensions';
import DeviceMessage from '../DeviceMessage';

type AuthPageType = {
  subtitle?: string;
};

export default function AuthPage(props: PageType & AuthPageType) {
  const { children, subtitle } = props;
  const screenDimensions = useDimensions();
  const theme = useTheme();
  const isXsmall = screenDimensions.width < theme.breakpoints.tabletsmall;
  return isXsmall ? (
    <DeviceMessage />
  ) : (
    <Page showHeader={false} className="page--auth">
      <AuthContainer>
        <LogoContainer>
          <svg viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M43.998 88C19.7387 88 0 68.2613 0 43.998C0 19.7346 19.7387 0 43.998 0C68.2573 0 87.9959 19.7387 87.9959 43.998C87.9959 68.2573 68.2573 87.9959 43.998 87.9959V88ZM43.998 2.40884C21.0651 2.40884 2.40884 21.0651 2.40884 43.998C2.40884 66.9308 21.0651 85.5871 43.998 85.5871C66.9308 85.5871 85.5871 66.9308 85.5871 43.998C85.5871 21.0651 66.9308 2.40884 43.998 2.40884Z"
              fill="#76777A"
            />
            <path d="M43.998 45.9267L53.2875 29.8419H34.7125L43.998 45.9267Z" fill="#3B6489" />
            <path d="M23.6286 46.9643H42.4721L33.0483 30.6476L23.6286 46.9643Z" fill="#3B6489" />
            <path d="M42.3337 48.8075H23.7629L33.0483 64.8881L42.3337 48.8075Z" fill="#003764" />
            <path d="M31.5224 65.9257L22.0987 49.6091L12.679 65.9257H31.5224Z" fill="#003764" />
            <path d="M34.5783 27.9987H53.4177L43.998 11.6861L34.5783 27.9987Z" fill="#7C94AE" />
            <path d="M54.9476 30.6476L45.5279 46.9643H64.3714L54.9476 30.6476Z" fill="#3B6489" />
            <path d="M64.233 48.8075H45.6622L54.9476 64.8881L64.233 48.8075Z" fill="#003764" />
            <path d="M65.8973 49.6091L56.4735 65.9257H75.321L65.8973 49.6091Z" fill="#003764" />
          </svg>
        </LogoContainer>
        <VendorPortalPanel subheadline={subtitle}>{children}</VendorPortalPanel>
      </AuthContainer>
    </Page>
  );
}
AuthPage.defaultProps = {
  subtitle: '',
} as Partial<AuthPageType>;
