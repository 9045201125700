import styled from 'styled-components/macro';
import Input from '../../Input';

const StyledInput = styled(Input)`
  width: ${({ theme }) => `${theme.getValue(290)}px`};
`;

const Container = styled.div`
  ${StyledInput} {
    margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(36)}px`};
`;

export default { StyledInput, Container, ButtonContainer };
