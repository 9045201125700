import { useState, useEffect, useCallback } from 'react';
import 'reactjs-popup/dist/index.css';
import { useParams, useNavigate } from 'react-router-dom';
import Components from './styles';
import UserForm, { defaultInitialValues } from '../../../components/admin/UserForm';
import BackArrow from '../../../components/BackArrow';
import AdminPage from '../../../components/Page/AdminPage';
import Headline from '../../../components/typography/Headline';
import { UserFormType } from '../../../components/admin/UserForm/types';
import DeleteUserModal from '../../../components/admin/DeleteUserModal';
import { UserScreenPropsType } from './types';
import Handle401Error from '../../../components/Handle401Error';

export default function UserScreen(props: UserScreenPropsType) {
  const { user: userFromProps } = props;
  const { jwt } = userFromProps;
  const navigate = useNavigate();
  const params = useParams();
  const { userId } = params;
  const [currentUserData, setCurrentUserData] = useState<UserFormType>(defaultInitialValues);
  const [loading, setLoading] = useState(false);
  const [shouldGetUserInfo, setShouldGetUserInfo] = useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [is401Error, setIs401Error] = useState<boolean>(false);

  const fetchUser = useCallback(
    async (id: number | string) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        const data = await response.json();
        const { error } = data;
        if (error?.message) {
          throw error;
        }
        return data;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error(error);
        if (error?.status === 401) {
          setIs401Error(true);
        }
        throw error;
      }
      return true;
    },
    [jwt],
  );

  const updateUser = async (userInfo: UserFormType) => {
    let data = null;

    try {
      setLoading(true);
      setErrorMsg('');
      const bodyData = JSON.stringify({ id: userId, ...userInfo });

      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${jwt}`,
          'content-type': 'application/json ',
        },
        body: bodyData,
      });

      data = await response.json();
      if (data.error) throw data.error;
      setShouldGetUserInfo(true);
      setLoading(false);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setErrorMsg(error?.message || `${error}` || 'Something went wrong');
      setLoading(false);
      throw error;
    }

    return data;
  };

  const deleteUser = async (id: number | string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
        method: 'delete',
      });
      const data = await response.json();
      navigate('/users/list');
      return data;
    } catch (error) {
      console.error(error);
    }
    return true;
  };

  useEffect(() => {
    const getUser = async (id: number | string) => {
      const newUser = await fetchUser(id);
      setCurrentUserData({ firstName: newUser?.firstName, lastName: newUser?.lastName, email: newUser?.email });
    };
    try {
      if (userId && shouldGetUserInfo) {
        getUser(userId);
        setShouldGetUserInfo(false);
      }
    } catch (error) {
      console.error(error);
    }
  }, [userId, shouldGetUserInfo, fetchUser]);

  useEffect(() => {
    setShouldGetUserInfo(true);
  }, []);

  return (
    <Handle401Error isActive={is401Error} user={userFromProps}>
      <AdminPage>
        <Components.Container>
          <BackArrow />
          <Headline>{`Edit ${currentUserData?.firstName} ${currentUserData?.lastName}`}</Headline>
          <Components.FormWrapper>
            <UserForm
              errorMsg={errorMsg}
              userData={currentUserData}
              userId={userId}
              updateUser={updateUser}
              loading={loading}
              setDeleteDialogState={setDeleteDialogOpen}
            />
          </Components.FormWrapper>
        </Components.Container>
      </AdminPage>
      <DeleteUserModal
        open={isDeleteDialogOpen}
        setDeleteDialogState={setDeleteDialogOpen}
        handleDeleteClick={() => {
          if (userId) {
            deleteUser(userId);
          }
        }}
      />
    </Handle401Error>
  );
}
