import { PrintLabel, PrintValue, PrintTitle } from '../../../PrintTemplate/styles';
import { ReviewStep7PropsType } from './types';

export default function ReviewStep7Print(props: ReviewStep7PropsType) {
  const { fieldValues: conflictOfInterestData } = props;
  const { conflictOfInterest, employeesFirstName, employeesLastName, employeesRelationship, employeesPhoneNumber } =
    conflictOfInterestData;
  return (
    <>
      <PrintTitle>{conflictOfInterest}</PrintTitle>
      {employeesFirstName ? (
        <>
          <PrintLabel>Employee&apos;s First Name</PrintLabel>
          <PrintValue>{employeesFirstName}</PrintValue>
        </>
      ) : null}
      {employeesLastName ? (
        <>
          <PrintLabel>Employee&apos;s Last Name</PrintLabel>
          <PrintValue>{employeesLastName}</PrintValue>
        </>
      ) : null}
      {employeesRelationship ? (
        <>
          <PrintLabel>Employee&apos;s Relationship or Position within the Company</PrintLabel>
          <PrintValue>{employeesRelationship}</PrintValue>
        </>
      ) : null}
      {employeesPhoneNumber ? (
        <>
          <PrintLabel>Employee&apos;s Telephone Number</PrintLabel>
          <PrintValue>{employeesPhoneNumber}</PrintValue>
        </>
      ) : null}
    </>
  );
}
