import styled from 'styled-components/macro';

export const Container = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.getValue(12)}px`} 0;
  min-width: ${({ theme }) => `${theme.getValue(295)}px`};
  position: relative;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    min-width: 0;
  }
  label {
    &.is-moved {
      position: relative;
    }
  }
  &.name-container {
    margin-top: ${({ theme }) => `${theme.getValue(7)}px`};
    margin-bottom: ${({ theme }) => `${theme.getValue(1)}px`};
  }
  &.input--phone {
    min-width: 0;
  }
`;

export const DisabledTextArea = styled.p`
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  padding-left: 0;
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  font-weight: ${({ theme }) => `${theme.weights.extrabold}`};
  line-height: ${({ theme }) => `${theme.getValue(16)}px`};
  padding-top: 0;
  padding-bottom: 0;
  margin: 0;
  white-space: pre-wrap;
  &::placeholder {
    color: transparent;
  }
  &:hover {
    cursor: not-allowed;
  }
`;

export const TextArea = styled.textarea`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => `${theme.getValue(10)}px`} ${({ theme }) => `${theme.getValue(12)}px`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  border: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
  border-radius: 4px;
  resize: none;
  &:focus,
  &:focus-visible {
    outline: none;
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 1;
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export const Error = styled.p`
  color: ${({ theme }) => `${theme.colors.orange1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export default { Container, TextArea, Label, Error, DisabledTextArea };
