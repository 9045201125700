/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import SustainabilityPolicyType from '../../content/Step5/types';

const initialState: SustainabilityPolicyType = {
  hasSustainanbilityPolicy: false,
  sustainabilityPolicyFiles: [],
  hasEnvironmentPolicy: false,
  environmentPolicyFiles: [],
  hasSocialResponsibilityPolicy: false,
  socialResponsibilityFiles: [],
  hasSustainabilityProcurementPolicy: false,
  sustainabilityProcurementFiles: [],
  hasNonePolicies: false,
  sustainabilityResponsibility: '',
  hasIdentifiedImpacts: '',
  impactsIdentifiedList: '',
  hasEnvironmentalManagementStandards: '',
  managementStandardsDetails: '',
};

export const sustainabilityPolicySlice = createSlice({
  name: 'sustainabilityPolicyState',
  initialState,
  reducers: {
    setSustainabilityPolicy: (state, action: PayloadAction<SustainabilityPolicyType>) => {
      return { ...action.payload };
    },
  },
});

export const { setSustainabilityPolicy } = sustainabilityPolicySlice.actions;
export default sustainabilityPolicySlice.reducer;
