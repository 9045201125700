import styled from 'styled-components/macro';

const Footer = styled.footer`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  height: ${({ theme }) => `${theme.getValue(80)}px`};
  padding: ${({ theme }) => `${theme.getValue(26)}px`} ${({ theme }) => `${theme.getValue(80)}px`};
  position: fixed;
  bottom: 0;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    position: static;
  }
  @media screen and (max-height: ${({ theme }) => `${theme.breakpoints.small}px`}) and (orientation: landscape) {
    position: static;
  }
  p {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-size: ${({ theme }) => `${theme.getValue(12)}px`};
    line-height: 1;
    color: ${({ theme }) => `${theme.colors.black}`};
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: ${({ theme }) => `${theme.colors.black}`};
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    height: ${({ theme }) => `${theme.getValue(68)}px`};
    padding: ${({ theme }) => `${theme.getValue(20)}px`} ${({ theme }) => `${theme.getValue(30)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    flex-direction: column;
    height: ${({ theme }) => `${theme.getValue(96)}px`};
    padding: ${({ theme }) => `${theme.getValue(16)}px`} ${({ theme }) => `${theme.getValue(20)}px`};
  }
  @media print {
    display: none;
  }
`;

const LeftContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: ${({ theme }) => `${theme.getValue(276)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: 100%;
  }
  p {
    text-align: left;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      text-align: center;
    }
  }
`;

const RightContainer = styled.div`
  p {
    text-align: right;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      text-align: center;
      font-size: ${({ theme }) => `${theme.getValue(10)}px`};
    }
  }
`;

export default { Footer, LeftContainer, RightContainer };
