import { ReviewGridInput as Input, SectionTitle as Title } from '../../ReviewGridStep/styles';
import { ReviewStep7PropsType } from './types';

export default function ReviewStep7(props: ReviewStep7PropsType) {
  const { fieldValues: conflictOfInterestData } = props;
  const { conflictOfInterest, employeesFirstName, employeesLastName, employeesRelationship, employeesPhoneNumber } =
    conflictOfInterestData;
  return (
    <>
      <Title>{conflictOfInterest}</Title>
      {employeesFirstName ? <Input disabled label="Employee's First Name" value={employeesFirstName} /> : null}
      {employeesLastName ? <Input disabled label="Employee's Last Name" value={employeesLastName} /> : null}
      {employeesRelationship ? (
        <Input disabled label="Employee's Relationship or Position within the Company" value={employeesRelationship} />
      ) : null}
      {employeesPhoneNumber ? (
        <Input disabled label="Employee’s Telephone Number*" value={employeesPhoneNumber} />
      ) : null}
    </>
  );
}
