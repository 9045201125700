import Page from '../../components/Page';
import Components from '../vendor/WelcomeScreen/styles';
import Logo from '../../assets/images/logo.png';

export default function NotFoundScreen() {
  return (
    <Page showHeader={false}>
      <Components.Wrapper>
        <Components.Container>
          <Components.Banner>
            <Components.Logo>
              <img src={Logo} alt="advantage solutions" />
            </Components.Logo>
          </Components.Banner>
          <Components.Hero>
            <Components.HeadlineContainer>
              <Components.Title>Hello!</Components.Title>
              <Components.Subheadline>Page not found!</Components.Subheadline>
            </Components.HeadlineContainer>
            <Components.Description>
              <p>If you need assistance, please contact support at:</p>
            </Components.Description>
          </Components.Hero>
        </Components.Container>
      </Components.Wrapper>
    </Page>
  );
}
