import { useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

function useDimensions() {
  const [containerDimensions, setContainerDimensions] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    function handleResize() {
      const { outerWidth, outerHeight } = window;
      setContainerDimensions({ width: outerWidth, height: outerHeight });
    }

    window.addEventListener('resize', debounce(handleResize, 300));
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return containerDimensions;
}

export default useDimensions;
