import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import Components from './styles';
import { Error } from '../../Input/styles';
import UserFormSchema, { UserFormType, UserFormPropsType } from './types';

export const defaultInitialValues: UserFormType = {
  firstName: '',
  lastName: '',
  email: '',
};

export default function UserForm(props: UserFormPropsType) {
  const navigate = useNavigate();
  const { userData, updateUser, loading, setDeleteDialogState, errorMsg } = props;
  const initialValues: UserFormType = {
    firstName: userData?.firstName || defaultInitialValues?.firstName,
    lastName: userData?.lastName || defaultInitialValues?.lastName,
    email: userData?.email || defaultInitialValues?.email,
  };

  const { values, touched, errors, handleBlur, setFieldValue, handleChange, isValid, handleSubmit } = useFormik({
    initialValues,
    validateOnMount: true,
    onSubmit: async (vals: UserFormType) => {
      try {
        if (typeof vals === 'object') {
          await updateUser(vals);
        }
      } catch (error) {
        console.error(error);
      }
    },
    validationSchema: UserFormSchema,
    enableReinitialize: true,
  });

  return (
    <Components.Container>
      <Components.StyledInput
        value={values.firstName}
        touched={touched.firstName}
        error={errors.firstName}
        name="firstName"
        label="First Name*"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Components.StyledInput
        name="lastName"
        value={values.lastName}
        touched={touched.lastName}
        error={errors.lastName}
        label="Last Name*"
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <Components.StyledInput
        name="email"
        value={values.email}
        touched={touched.email}
        error={errors.email}
        label="Email Address*"
        onChange={e => setFieldValue('email', e.target.value.toLowerCase())}
        onBlur={handleBlur}
      />
      <Components.ButtonContainer>
        <Components.Row>
          <Button variant="secondary" onClick={() => navigate('/users/list')}>
            Cancel
          </Button>
          <Button
            disabled={loading || !isValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>
        </Components.Row>
        <Button
          variant="secondary"
          size="wide"
          onClick={() => {
            setDeleteDialogState(true);
          }}
        >
          Delete User
        </Button>
      </Components.ButtonContainer>
      {errorMsg ? <Error>{errorMsg}</Error> : null}
    </Components.Container>
  );
}
