/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import Components from './styles';
import { CustomCheckboxPropsType } from './types';

function CustomCheckbox(props: CustomCheckboxPropsType) {
  const { id, label, onChange, isChecked, enableRadioTheme } = props;
  return (
    <Components.InputLabel htmlFor={id}>
      <Components.InputWrapper>
        <Components.CheckboxInput
          id={id}
          type="checkbox"
          onChange={e => {
            onChange?.(e);
          }}
        />
        <Components.CheckboxControl isChecked={isChecked} isRadio={enableRadioTheme} />
      </Components.InputWrapper>
      {label || null}
    </Components.InputLabel>
  );
}

export default React.memo(CustomCheckbox);
