import styled from 'styled-components/macro';

const Container = styled.div`
  margin-top: -${({ theme }) => `${theme.getValue(24)}px`};
  text-align: center;
  p {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  }
  a {
    color: ${({ theme }) => `${theme.colors.primaryBlue}`};
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-style: normal;
    font-weight: ${({ theme }) => `${theme.weights.bold}`};
    font-size: ${({ theme }) => `${theme.getValue(18)}px`};
    line-height: 1;
    text-align: center;
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    text-decoration: none;
    &:hover {
      cursor: pointer;
    }
  }
`;

export default { Container };
