import styled from 'styled-components/macro';

const Container = styled.div`
  p {
    max-width: ${({ theme }) => `${theme.getValue(480)}px`};
    margin-left: auto;
    margin-right: auto;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  justify-content: center;
`;

export default { Container, ButtonGroup };
