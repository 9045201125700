import React, { useEffect } from 'react';
import { Wrapper } from './styles';
import FormUtilType from './types';

export default function FormUtil(props: FormUtilType) {
  const { title } = props;
  useEffect(() => {
    const { search } = window.location;
    const key = 'signedPdfUrl=';
    const pdfUrl = search.substring(search.indexOf(key) + key.length);
    const localUrl = localStorage.getItem('PDF_URL');
    if (pdfUrl !== localUrl) {
      localStorage.setItem('PDF_URL', pdfUrl);
    }
    window.close();
  }, []);
  return <Wrapper>{title}</Wrapper>;
}

FormUtil.defaultProps = {
  title: 'Returning to Form',
};
