import { useFormik } from 'formik';
import Dropdown from '../../Dropdown';
import Components from './styles';
import filterOptions from './options';
import VendorFilterPill from '../VendorFilterPill';
import { VendorFilterProps } from './types';

const initialValues = {
  currentField: '',
  currentFieldValue: '',
};

function VendorFilter(props: VendorFilterProps) {
  const { onFilterApply, onTagClick, currentTags } = props;
  const { values, setFieldValue, handleChange, handleSubmit } = useFormik({
    initialValues,
    onSubmit: () => {
      const field = filterOptions.find(option => values.currentField === option.value);
      const valueField = field?.options
        ? field?.options.find(option => values.currentFieldValue === option.value)
        : null;
      onFilterApply({ label: field?.label || '', value: valueField?.label || '', filterField: valueField?.field });
      setFieldValue('currentField', '');
      setFieldValue('currentFieldValue', '');
    },
  });

  const renderValuesDropdown = (field: string) => {
    const options = filterOptions.find(opt => opt.value === field)?.options;
    if (!options) return null;
    return (
      <Dropdown
        id="currentFieldValue"
        name="currentFieldValue"
        value={values.currentFieldValue}
        variant="secondary"
        onChange={handleChange}
      >
        <option key={`${field}-default`} value="">
          Select
        </option>
        {options.map(opt => (
          <option key={`${field}-${opt.value}`} value={opt.value}>
            {opt.label}
          </option>
        ))}
      </Dropdown>
    );
  };

  return (
    <>
      <Components.Container>
        <Dropdown
          id="currentField"
          name="currentField"
          value={values.currentField}
          variant="secondary"
          onChange={handleChange}
        >
          {filterOptions.map(opt => (
            <option key={opt.value} value={opt.value}>
              {opt.label}
            </option>
          ))}
        </Dropdown>
        {values.currentField ? renderValuesDropdown(values.currentField) : null}
        {values.currentFieldValue ? (
          <Components.FilterBtn
            onClick={() => {
              handleSubmit();
            }}
            type="button"
          >
            Apply
          </Components.FilterBtn>
        ) : null}
      </Components.Container>
      {currentTags.length > 0 ? (
        <Components.PillsContainer>
          {currentTags.map(tag => (
            <VendorFilterPill
              onPillClick={() => onTagClick({ label: tag.label, value: tag.value })}
              key={`${tag.label}-${tag.value}`}
            >
              {`${tag.label}: `}
              <span>{tag.value}</span>
            </VendorFilterPill>
          ))}
        </Components.PillsContainer>
      ) : null}
    </>
  );
}

export default VendorFilter;
