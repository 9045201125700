export default [
  {
    id: 'hasSustainanbilityPolicy',
    title: 'Sustainability',
  },
  {
    id: 'hasEnvironmentPolicy',
    title: 'Environment',
  },
  {
    id: 'hasSocialResponsibilityPolicy',
    title: 'Social Responsibility',
  },
  {
    id: 'hasSustainabilityProcurementPolicy',
    title: 'Sustainability Procurement/Purchasing',
  },
  {
    id: 'hasNonePolicies',
    title: 'None of the Above',
  },
];
