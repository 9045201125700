import { useState } from 'react';
// eslint-disable-next-line import/no-named-default
import { default as Page } from '../../../components/Page/AuthPage';
import SignUpConfirmationModal from '../../../components/admin/SignUpConfirmationModal';
import SignUpForm from '../../../components/admin/SignUpForm';

export default function SignUpScreen() {
  const [showConfirmation, setShowConfirmation] = useState(false);
  return (
    <Page subtitle="Please create a password to complete setting up your account.">
      <SignUpForm onSuccess={() => setShowConfirmation(true)} />
      <SignUpConfirmationModal open={showConfirmation} setModalOpenState={setShowConfirmation} />
    </Page>
  );
}
