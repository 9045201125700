/* eslint-disable @typescript-eslint/no-explicit-any */
import { PrintLabel, PrintValue, PrintTitle, SemiboldPrintLabel } from '../../../PrintTemplate/styles';
import { Inner } from '../../ReviewGridStep/styles';
import policies from '../../../Step5/data/sustainabilityPolicies';
import sustainabilityLabels from '../../../Step5/data/sustainabilityLabels';
import { ReviewStep5PropsType } from './types';
import AttachedFilesList from '../../../../components/AttachedFilesList';

export default function ReviewStep5Print(props: ReviewStep5PropsType) {
  const { fieldValues: sustainabilityData } = props;
  const {
    hasSustainanbilityPolicy: sus,
    sustainabilityPolicyFiles,
    hasEnvironmentPolicy: env,
    environmentPolicyFiles: envFiles,
    hasSocialResponsibilityPolicy: sr,
    socialResponsibilityFiles: srFiles,
    hasSustainabilityProcurementPolicy: proc,
    sustainabilityProcurementFiles: procFiles,
    hasNonePolicies: none,
    sustainabilityResponsibility: responsible,
    hasIdentifiedImpacts: impactsIdentified,
    impactsIdentifiedList,
    hasEnvironmentalManagementStandards: managementStandars,
    managementStandardsDetails: managementStandarsDetails,
  } = sustainabilityData;

  const renderFileList = (fileList: Array<Record<string, unknown>> | undefined) => {
    const getFiles = () => {
      if (fileList) {
        if (Array.isArray(fileList)) {
          return fileList;
        }
        return Array.isArray((fileList as any).data)
          ? (fileList as any).data?.map((f: any) => f.attributes) || []
          : [(fileList as any).data.attributes];
      }
      return [];
    };
    const renderedFiles = getFiles()?.map((currentFile: Record<string, string | unknown>) => ({
      name: currentFile.name,
      url: '',
    }));
    return renderedFiles;
  };

  return (
    <>
      {sus && (
        <Inner showBorder>
          <PrintTitle>{policies[0].title}</PrintTitle>
          <AttachedFilesList files={renderFileList(sustainabilityPolicyFiles)} />
        </Inner>
      )}
      {env && (
        <Inner showBorder>
          <PrintTitle>{policies[1].title}</PrintTitle>
          <AttachedFilesList files={renderFileList(envFiles)} />
        </Inner>
      )}
      {sr && (
        <Inner showBorder>
          <PrintTitle>{policies[2].title}</PrintTitle>
          <AttachedFilesList files={renderFileList(srFiles)} />
        </Inner>
      )}
      {proc && (
        <Inner showBorder>
          <PrintTitle>{policies[3].title}</PrintTitle>
          <AttachedFilesList files={renderFileList(procFiles)} />
        </Inner>
      )}
      {none && (
        <Inner showBorder>
          <PrintTitle>{policies[4].title}</PrintTitle>
        </Inner>
      )}
      {responsible ? (
        <>
          <SemiboldPrintLabel>{sustainabilityLabels[0].title}</SemiboldPrintLabel>
          <Inner showBorder>
            <PrintTitle inner>{responsible ? 'Yes' : 'No'}</PrintTitle>
            <PrintLabel dangerouslySetInnerHTML={{ __html: sustainabilityLabels[0].label }} />
            <PrintValue>{responsible}</PrintValue>
          </Inner>
        </>
      ) : null}
      {impactsIdentified ? (
        <>
          <SemiboldPrintLabel>{sustainabilityLabels[1].title}</SemiboldPrintLabel>
          <Inner showBorder>
            <PrintTitle inner>{impactsIdentified}</PrintTitle>
            {impactsIdentified === 'Yes' && (
              <>
                <PrintLabel>{sustainabilityLabels[1].inputLabel}</PrintLabel>

                <PrintLabel>List of ways our company is improving our impact on sustainability.</PrintLabel>
                <PrintValue>{impactsIdentifiedList}</PrintValue>
              </>
            )}
          </Inner>
        </>
      ) : null}
      {managementStandars ? (
        <>
          <SemiboldPrintLabel>{sustainabilityLabels[2].title}</SemiboldPrintLabel>
          <Inner showBorder>
            <PrintTitle inner>{managementStandars}</PrintTitle>
            {managementStandars === 'Yes' && (
              <>
                <PrintLabel>{sustainabilityLabels[2].inputLabel}</PrintLabel>
                <PrintLabel>Green Office certification, ISO 14001, EMAS</PrintLabel>
                <PrintValue>{managementStandarsDetails}</PrintValue>
              </>
            )}
          </Inner>
        </>
      ) : null}
    </>
  );
}
