import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { RootState } from '../../store/store';
import ProtectedRoutePropsType from './types';

export default function ProtectedRoute(props: ProtectedRoutePropsType) {
  const sessionState = useSelector((state: RootState) => state.sessionState);
  const { children, isAllowed, redirectRoute } = props;
  const redirectQs = qs.stringify({
    ...(sessionState?.expired ? { expired: true } : null),
  });

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return !isAllowed ? <Navigate to={`${redirectRoute}?${redirectQs}`} replace /> : <>{children || <Outlet />}</>;
}

ProtectedRoute.defaultProps = {
  redirectRoute: '/login',
};
