import styled from 'styled-components/macro';
import Button from '../../components/Button';
import Headline from '../../components/typography/Headline';

const Title = styled(Headline)`
  margin-top: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
`;

const Subtext = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 1.5;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  margin: 0;
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  letter-spacing: -0.015em;
  margin-bottom: ${({ theme }) => `${theme.getValue(33)}px`};
`;

const Info = styled.p`
  position: relative;
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: ${({ theme }) => `${theme.getValue(15)}px`};
  padding-left: ${({ theme }) => `${theme.getValue(28)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(40)}px`};
  &:before {
    content: '';
    width: ${({ theme }) => `${theme.getValue(14)}px`};
    height: ${({ theme }) => `${theme.getValue(14)}px`};
    border-radius: 50%;
    background-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    background-position: center center;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='15' viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.49996 0.416748C3.58996 0.416748 0.416626 3.59008 0.416626 7.50008C0.416626 11.4101 3.58996 14.5834 7.49996 14.5834C11.41 14.5834 14.5833 11.4101 14.5833 7.50008C14.5833 3.59008 11.41 0.416748 7.49996 0.416748ZM8.20829 11.0417H6.79163V6.79175H8.20829V11.0417ZM8.20829 5.37508H6.79163V3.95841H8.20829V5.37508Z' fill='%23F36E21'/%3E%3C/svg%3E");
  }
`;
const Step1Btn = styled(Button)`
  margin-top: ${({ theme }) => `${theme.getValue(32)}px`};
  float: right;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.tabletlarge}px`}) {
    margin-bottom: ${({ theme }) => `${theme.getValue(120)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: 100%;
    float: none;
    margin-bottom: ${({ theme }) => `${theme.getValue(24)}px`};
  }
`;

export default {
  Title,
  Info,
  Step1Btn,
  Subtext,
};
