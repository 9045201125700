import styled from 'styled-components';
import Components from '../WelcomeScreen/styles';

const Wrapper = styled(Components.Wrapper)`
  align-items: flex-start;
  padding-top: ${({ theme }) => `${theme.getValue(108)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    padding-top: ${({ theme }) => `${theme.getValue(100)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    padding-top: ${({ theme }) => `${theme.getValue(30)}px`};
  }
`;

const Hero = styled(Components.Hero)`
  justify-content: flex-start;
  min-height: auto;
`;

const HeadlineContainer = styled(Components.HeadlineContainer)`
  width: 85%;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: 100%;
  }
`;
const Subheadline = styled(Components.Subheadline)`
  margin: 0 auto;
`;

export default { ...Components, Hero, Subheadline, HeadlineContainer, Wrapper };
