import styled from 'styled-components/macro';

const OrderedList = styled.ol`
  margin-left: 0;
  padding-left: 1em;
`;
const ListItem = styled.li`
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
`;

const FileName = styled.p`
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  margin: 0;
  line-height: 150%;
`;

const FilesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => `${theme.getValue(14)}px`};
`;

const UploadIcon = styled.img`
  width: ${({ theme }) => `${theme.getValue(13.3)}px`};
  height: ${({ theme }) => `${theme.getValue(16.6)}px`};
`;

const File = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => `${theme.getValue(9)}px`};
`;

export default { FileName, FilesContainer, UploadIcon, File, OrderedList, ListItem };
