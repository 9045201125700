import React from 'react';
// eslint-disable-next-line import/no-named-default
import { default as Page } from '../../../components/Page/AuthPage';
import LoginForm from '../../../components/admin/LoginForm';

export default function LogInScreen() {
  return (
    <Page subtitle="Enter your credentials to access your account.">
      <LoginForm />
    </Page>
  );
}
