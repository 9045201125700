import * as Yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { CustomPopUpPropsType } from '../../IVPPopup/types';
import tableSubmissionType from '../SubmissionsTable/types';
import { SubmissionInviteType } from '../../../types';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';

export const sendVendorFormSchema = Yup.object({
  email: Yup.string().email(EMAIL_VALIDATION_MSG).required(REQUIRED_VALIDATION_MSG),
  contactName: Yup.string().default(''),
  businessUnit: Yup.string().required(REQUIRED_VALIDATION_MSG),
});

export type vendorFormFieldsType = Yup.InferType<typeof sendVendorFormSchema>;

export type sendInviteProps = vendorFormFieldsType & {
  uuid?: string;
  submissionId?: string | number;
  senderEmail?: string;
  action?: SubmissionInviteType['action'];
};

export type SendVendorFormModalProps = {
  setSendNewFormDialogState: Dispatch<SetStateAction<boolean>>;
  handleSendClick: (vars: sendInviteProps) => void;
  formType: 'new' | 'resend' | 'resubmit' | undefined;
  setFormType: Dispatch<SetStateAction<SendVendorFormModalProps['formType']>>;
  serverError?: string;
  submissionInfo: tableSubmissionType | sendInviteProps | null;
} & Omit<CustomPopUpPropsType, 'children'>;
