import { PanelWrapper, FootnoteWrapper, Footnote, Note, ResourceTitle } from './styles';

export function VendorResource2() {
  return (
    <>
      <p>
        The IRS uses the taxpayer identification numbers provided on this form for identification purposes and to help
        verify the accuracy of your tax return. The IRS may also provide this information to the Department of Justice
        for civil and criminal litigation, and to cities, states, and the District of Columbia to carry out their tax
        laws. The information may also be disclosed to other countries under a tax treaty, or to federal and state
        agencies to enforce federal non-tax criminal laws and to combat terrorism.
      </p>
      <ResourceTitle style={{ textAlign: 'center' }}>What Name and Number To Give the Requester</ResourceTitle>
      <PanelWrapper>
        <table>
          <thead>
            <tr>
              <th>For this type of account:</th>
              <th>Give name and SSN of:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <strong>1.</strong>
                  <p>Individual</p>
                </div>
              </td>
              <td>The Individual</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>2.</strong>
                  </p>
                  <p>Two or more individuals (joint account) other than an account maintained by an FFI</p>
                </div>
              </td>
              <td>The actual owner of the account or, if combined funds, the first individual on the account 1</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>3.</strong>
                  </p>
                  <p>Two or more U.S. persons (joint account maintained by an FFI)</p>
                </div>
              </td>
              <td>Each holder of the account</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>4.</strong>
                  </p>
                  <p>Custodial account of a minor (Uniform Gift to Minors Act)</p>
                </div>
              </td>
              <td>The minor</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>5.</strong>
                  </p>
                  <p>
                    <b>a. </b>The usual revocable savings trust (grantor is also trustee)
                  </p>
                </div>{' '}
              </td>
              <td>
                The grantor-trustee <sup>1</sup>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong> </strong>
                  </p>
                  <p>
                    <b>b. </b>So-called trust account that is not a legal or valid trust under state law
                  </p>
                </div>
              </td>
              <td>
                The actual owner <sup>1</sup>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>6.</strong>
                  </p>
                  <p>Sole proprietorship or disregarded entity owned by an individual</p>
                </div>
              </td>
              <td>
                The owner <sup>3</sup>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>7.</strong>
                  </p>
                  <p>
                    Grantor trust filing under Optional Form 1099 Filing Method 1 (see Regulations section
                    1.671-4(b)(2)(i)(A))
                  </p>
                </div>
              </td>
              <td>
                The grantor <sup>*</sup>
              </td>
            </tr>
          </tbody>
        </table>
        <table>
          <thead>
            <tr>
              <th>For this type of account:</th>
              <th>Give name and EIN of:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>8.</strong>
                  </p>
                  <p>Disregarded entity not owned by an individual</p>
                </div>
              </td>
              <td>The owner</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>9.</strong>
                  </p>
                  <p>A valid trust, estate, or pension trust</p>
                </div>
              </td>
              <td>
                Legal entity <sup>4</sup>
              </td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>10.</strong>
                  </p>
                  <p>Corporation or LLC electing corporate status on Form 8832 or Form 2553</p>
                </div>
              </td>
              <td>The corporation</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>11.</strong>
                  </p>
                  <p>Association, club, religious, charitable, educational, or other tax-exempt organization</p>
                </div>
              </td>
              <td>The organization</td>
            </tr>
            <tr>
              <td>
                <div>
                  <strong>
                    <p>12.</p>
                  </strong>
                  <p>Partnership or multi-member LLC</p>
                </div>
              </td>
              <td>The partnership</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>13.</strong>
                  </p>
                  <p>A broker or registered nominee</p>
                </div>
              </td>
              <td>The broker or nominee</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>14.</strong>
                  </p>
                  <p>
                    Account with the Department of Agriculture in the name of a public entity (such as a state or local
                    government, school district, or prison) that receives agricultural program payments
                  </p>
                </div>
              </td>
              <td>The public entity</td>
            </tr>
            <tr>
              <td>
                <div>
                  <p>
                    <strong>15.</strong>
                  </p>
                  <p>
                    Grantor trust filing under the Form 1041 Filing Method or the Optional Form 1099 Filing Method 2
                    (see Regulation section 1.671-4(b)(2)(i)(B))
                  </p>
                </div>
              </td>
              <td>The trust</td>
            </tr>
          </tbody>
        </table>
      </PanelWrapper>
      <FootnoteWrapper>
        <Footnote>
          List first and circle the name of the person whose number you furnish. If only one person on a joint account
          has an SSN, that person’s number must be furnished.
        </Footnote>
        <Footnote>Circle the minor’s name and furnish the minor’s SSN.</Footnote>
        <Footnote>
          You must show your individual name and you may also enter your business or DBA name on the “Business
          name/disregarded entity” name line. You may use either your SSN or EIN (if you have one), but the IRS
          encourages you to use your SSN.
        </Footnote>
        <Footnote>
          List first and circle the name of the trust, estate, or pension trust. (Do not furnish the TIN of the personal
          representative or trustee unless the legal entity itself is not designated in the account title.) Also see
          Special rules for partnerships earlier.
        </Footnote>
      </FootnoteWrapper>
      <Note>
        <strong>*Note:</strong> Grantor also must provide a Form W-9 to trustee of trust.
      </Note>
      <Note>
        <strong>Note:</strong> If no name is circled when more than one name is listed, the number will be considered to
        be that of the first name listed.
      </Note>
    </>
  );
}

export default {
  title: 'Privacy Act Notice',
  content: <VendorResource2 />,
};
