import { useFormik } from 'formik';
import { useState } from 'react';
import Button from '../../Button';
import Input from '../../Input';
import { Error } from '../../Input/styles';
import ProfileFormPropsType, { ProfileFormFieldsType, ProfileFormSchema } from './types';
import Components from './styles';

function ProfileForm(props: ProfileFormPropsType) {
  const { handleSaveChanges, errorMsg, clearErrorMsg } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const { firstName, lastName, email } = props;

  const initialValues: ProfileFormFieldsType = {
    firstName,
    lastName,
    email,
  };

  const { values, touched, errors, handleChange, setFieldValue, handleBlur, handleSubmit, resetForm, isValid } =
    useFormik({
      initialValues,
      onSubmit: async vals => {
        setIsLoading(true);
        try {
          await handleSaveChanges?.(vals);
          setIsEditable(false);
        } catch (error) {
          console.error('Error in form submit', error);
        }
        setIsLoading(false);
      },
      enableReinitialize: true,
      validateOnMount: true,
      validationSchema: ProfileFormSchema,
    });

  return (
    <>
      <Input
        label="First Name*"
        id="firstName"
        name="firstName"
        value={values.firstName}
        error={errors.firstName}
        touched={touched.firstName}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!isEditable}
      />
      <Input
        label="Last Name*"
        id="lastName"
        name="lastName"
        value={values.lastName}
        error={errors.lastName}
        touched={touched.lastName}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={!isEditable}
      />
      <Input
        label="Advantage Email*"
        id="email"
        name="email"
        value={values.email.toLowerCase()}
        error={errors.email}
        touched={touched.email}
        onChange={e => setFieldValue('email', e.target.value.toLowerCase())}
        onBlur={handleBlur}
        disabled={!isEditable}
      />
      <Components.ButtonWrapper>
        {isEditable ? (
          <Components.ButtonWrapperRow>
            <Button
              disabled={isLoading}
              variant="secondary"
              onClick={() => {
                clearErrorMsg?.();
                resetForm();
                setIsEditable(false);
              }}
            >
              Cancel
            </Button>
            <Button
              disabled={!isValid || isLoading}
              onClick={() => {
                handleSubmit();
              }}
            >
              Save Changes
            </Button>
          </Components.ButtonWrapperRow>
        ) : (
          <Button
            variant="secondary"
            onClick={() => {
              setIsEditable(true);
            }}
          >
            Edit
          </Button>
        )}
      </Components.ButtonWrapper>
      {errorMsg ? <Error>{errorMsg}</Error> : null}
    </>
  );
}

export default ProfileForm;
