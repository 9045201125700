import Input from '../components/Input';

export default [
  {
    title: 'Medical Services',
    description: 'Including Dentistry, Podiatry, Psychotherapy, Optometry, Chiropractic, etc.',
  },
  {
    title: 'Attorney Fees',
    description: '',
  },
  {
    title: 'Services',
    description: 'Non-Medical',
  },
  {
    title: 'Equipment/Supplies',
    description: '',
  },
  {
    title: 'Rent',
    description: '',
  },
  {
    title: 'Non-Employee Compensation',
    description: 'Including services, Maintenance, Construction, etc.',
  },
  {
    title: 'Interest',
    description: '',
  },
  {
    title: 'Royalties',
    description: '',
  },
  {
    title: 'Prizes & Awards',
    description: '',
  },
  {
    title: 'Legal Settlement',
    description: '',
  },
  {
    title: 'Other',
    description: '',
    content: (
      <>
        <p>test</p>
        <Input />
      </>
    ),
  },
];
