import styled from 'styled-components/macro';
import Input from '../../components/Input';
import TextArea from '../../components/TextArea';
import Dropdown from '../../components/Dropdown';
import Headline from '../../components/typography/Headline';
import { DisabledTextArea, Label } from '../../components/TextArea/styles';
import Button from '../../components/Button';

export const StyledHeadline = styled(Headline)`
  margin-top: 0;
`;

export const StyledInput = styled(Input)`
  .review-grid & {
    input,
    label {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        font-size: ${({ theme }) => `${theme.getValue(16)}px`};
        line-height: 1;
      }
    }
  }
`;

export const StyledDropdown = styled(Dropdown)``;

export const FormWrapper = styled.div`
  ${StyledInput} {
    margin-top: ${({ theme }) => `${theme.getValue(7)}px`};
  }
  ${StyledDropdown} {
    margin-top: ${({ theme }) => `${theme.getValue(7)}px`};
  }
`;

export const FormSection = styled.fieldset`
  padding: 0;
  border: 0;
  margin: 0;
  margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
  .review-grid & {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      font-size: ${({ theme }) => `${theme.getValue(16)}px`};
      margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
    }
  }
  legend {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-style: normal;
    font-weight: ${({ theme }) => `${theme.weights.bold}`};
    font-size: ${({ theme }) => `${theme.getValue(14)}px`};
    line-height: ${({ theme }) => `${theme.getValue(17)}px`};
    .review-grid & {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        font-size: ${({ theme }) => `${theme.getValue(16)}px`};
        margin-bottom: ${({ theme }) => `${theme.getValue(2)}px`};
      }
    }
  }
`;

export const PhoneInputWrapper = styled.div`
  .review-grid & {
    input,
    label {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        font-size: ${({ theme }) => `${theme.getValue(16)}px`};
        line-height: 1;
      }
    }
  }
`;

export const FormFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  .grid-container--is-print & {
    column-gap: 0;
    justify-content: space-between;
  }
  .review-grid & {
    column-gap: ${({ theme }) => `${theme.getValue(76)}px`};
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
      column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
    }
  }
  ${StyledInput}, ${PhoneInputWrapper} {
    width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
    min-width: 0;

    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      width: 100%;
    }
    .review-grid & {
      width: calc(50% - ${({ theme }) => `${theme.getValue(38)}px`});
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
        width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
      }
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        width: 100%;
      }
      &.half {
        width: calc(25% - ${({ theme }) => `${theme.getValue(48)}px`});
        @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
          width: calc(25% - ${({ theme }) => `${theme.getValue(15)}px`});
        }
        @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
          width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
        }
        &:last-child {
          margin-left: ${({ theme }) => `${theme.getValue(-20)}px`};
          @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
            margin-left: 0;
          }
        }
      }
    }
    &.half {
      width: calc(25% - ${({ theme }) => `${theme.getValue(15)}px`});
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
      }
    }
  }
  ${StyledDropdown} {
    width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
    min-width: 0;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      width: 100%;
    }
    &.half {
      width: calc(25% - ${({ theme }) => `${theme.getValue(15)}px`});
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
      }
    }
  }
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(48)}px`};
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    column-gap: 0;
    flex-direction: column-reverse;
    row-gap: ${({ theme }) => `${theme.getValue(10)}px`};
  }
  ${Button} {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      height: ${({ theme }) => `${theme.getValue(36)}px`};
      padding-top: ${({ theme }) => `${theme.getValue(10)}px`};
      padding-bottom: ${({ theme }) => `${theme.getValue(10)}px`};
    }
  }
`;

export const InputWrapperPrint = styled.div<{ widthPercentage?: number }>`
  width: calc(${({ widthPercentage }) => widthPercentage || 50}%);
  display: flex;
  flex-direction: column;
`;
export const StyledTextArea = styled(TextArea)`
  ${Label} {
    .review-grid & {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        font-size: ${({ theme }) => `${theme.getValue(16)}px`};
      }
    }
  }
  ${DisabledTextArea} {
    padding-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
    border-bottom: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
    .review-grid & {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        font-size: ${({ theme }) => `${theme.getValue(16)}px`};
      }
    }
  }
`;

export default {
  FormWrapper,
  FormSection,
  FormFlexContainer,
  ButtonSection,
  StyledInput,
  InputWrapperPrint,
  StyledHeadline,
  StyledDropdown,
  StyledTextArea,
};
