import styled from 'styled-components/macro';
import Button from '../../Button';
import Input from '../../Input';

const StyledInput = styled(Input)`
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
  }
`;

const Container = styled.div``;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.getValue(38)}px`};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ theme }) => `${theme.getValue(16)}px`};
  ${Button} {
    width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
  }
`;

export default { StyledInput, Container, ButtonContainer, Row };
