import styled from 'styled-components/macro';
import LandingStyles from '../../../components/admin/Landing/styles';

const Title = styled(LandingStyles.Title)`
  margin-top: 0;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
`;

const HeaderRightSide = styled(LandingStyles.HeaderRightSide)`
  gap: ${({ theme }) => `${theme.getValue(36)}px`};
`;

const CounterWrapper = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.secondary}px`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  font-weight: ${({ theme }) => `${theme.weights.medium}`};
  line-height: calc(14 / 12);
  text-align: right;
  text-transform: capitalize;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  margin: 0;
`;

export default { Title, HeaderRightSide, CounterWrapper };
