import { useSelector } from 'react-redux';
import Page from '../../../components/Page';
import ReviewGrid from '../../../content/ReviewGrid';
import { RootState } from '../../../store/store';
import Components from './style';
import { ReviewFormScreenProps } from './types';

export default function Review(props: ReviewFormScreenProps) {
  const { businessUnit } = props;
  const gBI = useSelector((state: RootState) => state.gBI);
  const vendorActivity = useSelector((state: RootState) => state.vendorActivity);
  const businessEntity = useSelector((state: RootState) => state.businessEntity);
  const conflictOfInterest = useSelector((state: RootState) => state.conflictOfInterest);
  const businessClassification = useSelector((state: RootState) => state.businessClassification);
  const irsForm = useSelector((state: RootState) => state.irsForm);
  const sustainabilityPolicy = useSelector((state: RootState) => state.sustainabilityPolicy);
  const responsabilityCertification = useSelector((state: RootState) => state.responsabilityCertification);

  return (
    <Page layout="review-grid">
      <Components.PageHeader>
        <Components.Title>Review Vendor Form</Components.Title>
        <Components.Subtext>
          Please review all of your submissions. You can edit as needed by clicking the edit symbol next to any section
          or click the <strong>Edit Form</strong> button below to go back to the first page of the form. If your data is
          correct, please click the <strong>Submit</strong> button below.
        </Components.Subtext>
      </Components.PageHeader>
      <ReviewGrid
        variant="vendor"
        showButtons
        submission={{
          gBI,
          vendorActivity,
          businessEntity,
          conflictOfInterest,
          businessClassification,
          irsForm,
          sustainabilityPolicy,
          responsabilityCertification,
          businessUnit,
        }}
      />
    </Page>
  );
}
