import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useState } from 'react';
import _ from 'lodash';
import { useTheme } from 'styled-components';
import { FileObjType } from '../../types';
import Components, { StyledButtonSection as ButtonSection } from './styles';
import steps from '../stepList';
import Step2Form from '../Step2/Step2Form';
import { setCurrentStep } from '../../store/slices/stepSlice';
import ReviewStep3 from './steps/ReviewStep3';
import ReviewStep4 from './steps/ReviewStep4';
import ReviewStep5 from './steps/ReviewStep5';
import ReviewStep6 from './steps/ReviewStep6';
import ReviewStep7 from './steps/ReviewStep7';
import ReviewStep8 from './steps/ReviewStep8';
import ReviewStep9 from './steps/ReviewStep9';
import Button from '../../components/Button';
import { RootState } from '../../store/store';
import { conflictOfInterestState } from '../../store/slices/conflictOfInterestSlice';
import { generalBusinessInfoState } from '../../store/slices/generalBusinessInfoSlice';
import { responsabilityCertificationState } from '../../store/slices/responsabilityCertificationSlice';
import { vendorActivityState } from '../../store/slices/vendorActivitySlice';
import { ReviewGridPropsType, ReviewGridSubmissionType } from './types';
import { Error as ErrorMsg } from '../../components/Input/styles';
import useSubmissionInfoWithUuid from '../../hooks/useSubmissionInfoWithUuid';
import { businessClassificationState } from '../../store/slices/businessClassificationSlice';
import Pill from '../../components/Pill';
import useDimensions from '../../hooks/useDimensions';
import GBIType from '../Step2/types';

const formData = new FormData();

const getFileObj = async (fileObj: Record<string, unknown>) => {
  const blob = await fetch(`${fileObj?.url}`).then(r => r.blob());
  const fetchedFile = new File([blob], `${fileObj?.name}`, { type: `${blob.type}` });
  return fetchedFile;
};

const attachFileToFormData = (file: File, componentName: string, fieldName: string) => {
  formData.append(`files.${componentName}.${fieldName}`, file, file.name);
};

const parseFilesInField = async (fieldParentName: string, fieldName: string, field: Array<unknown>) => {
  /**
  [{name:'John', relatedFiles:[{file:null, url:'someurl', name:'name'}]}, {name: 'Jane', relatedFiles:[file:null, url:'someurl', name:'name']}]
  or
  [{file:null, url:'someurl', name:'name'}, {file:null, url:'someurl', name:'name'}]
   */
  const parsedArray = await Promise.all(
    field.map(async (subField: unknown | FileObjType, index: number) => {
      // If value in array is not an object, return value untouched:
      if (typeof subField !== 'object') return subField;

      // If array's value is an object, an value has key 'field' as one of its properties, process for file extraction:
      if ('file' in subField) {
        const fetchedFile = await getFileObj(subField);
        attachFileToFormData(fetchedFile, fieldParentName, fieldName);
        return null;
      }
      // If values does not have a 'file' key, send value for field processing:
      // eslint-disable-next-line no-use-before-define
      const parsedSubfield = await parseFieldsInDataField(`${fieldParentName}.${fieldName}[${index}]`, subField);

      return parsedSubfield;
    }),
  );
  return parsedArray;
};

const parseFieldsInDataField = async (
  fieldName: string,
  field:
    | generalBusinessInfoState
    | vendorActivityState
    | conflictOfInterestState
    | responsabilityCertificationState
    | businessClassificationState
    | { [s: string]: unknown }
    | ArrayLike<unknown>,
) => {
  /**
  {
    firstName: 'John',
    gbiFiles: [{...FileObj}, {...FileObj}],
    businessCats: [{...StrapiComponent}, {...StrapiComponent}]
  }
   */
  const newField = await Promise.all(
    Object.entries(field).map(async entry => {
      const [key, value] = entry;
      // If field's value is an array, try to parse the files from it
      if (Array.isArray(value) && value.length > 0) {
        const returnVal: unknown = await parseFilesInField(fieldName, key, value);
        // Here is the problem
        if (_.isArray(returnVal) && returnVal?.indexOf(null) !== -1) {
          return [key, []];
        }
        return [key, returnVal];
      }
      return entry;
    }),
  );

  const returnVal = Object.fromEntries(newField);
  delete returnVal.relatedFiles;
  return returnVal;
};

const parseFieldsInData = async (data: Omit<RootState, 'userInfo' | 'step' | 'sessionState' | 'businessUnits'>) => {
  /* {
    uuid: asdas,
    gBI: {...},
    proc: {...}
  } */

  const newData = await Promise.all(
    Object.entries(data).map(async ([key, value]) => {
      if (!_.isObject(value)) return [key, value];
      const parsedField = await parseFieldsInDataField(key, value);
      return [key, parsedField];
    }),
  );

  const newDataObj: Omit<RootState, 'userInfo' | 'step'> = Object.fromEntries(newData);
  return newDataObj;
};

const SUBMISSIONS_API_URL = `${process.env.REACT_APP_API_URL}/submissions`;
const parseGBI = (gBIData: GBIType) => ({ gBI: { ...gBIData, stp: gBIData?.stpOther || gBIData?.stp } });
const parseSubmission = (submissionToParse: ReviewGridSubmissionType) => ({
  ...submissionToParse,
  ...parseGBI(submissionToParse.gBI),
});
export default function ReviewGrid(props: ReviewGridPropsType) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverErrorMsg, setServerErrorMsg] = useState<string>('');
  const { submission, showButtons, variant } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParams] = useSearchParams();
  const storedBusinessUnits = useSelector((state: RootState) => state.businessUnits.businessUnits);
  const uuid = searchParams.get('uuid') || '';
  const formInfo = useSubmissionInfoWithUuid(uuid, `${process.env.REACT_APP_API_TOKEN}`);
  const { data: formInfoData, error } = formInfo;

  if (error) {
    console.error(error);
  }

  const handleSubmit = async () => {
    try {
      // eslint-disable-next-line no-throw-literal
      if (!uuid) throw 'Missing identifier';

      const originalData = {
        uuid,
        ...parseSubmission(submission),
      };

      const fieldsParsedData = await parseFieldsInData(originalData);

      const invite = {
        ...formInfoData?.invite,
        nameOfBusiness: fieldsParsedData.gBI.nameOfBusiness,
        completed: true,
        completedDate: new Date().toISOString(),
        action: 'submit',
      };

      const fullParsedData = { ...fieldsParsedData, invite };

      formData.append('data', JSON.stringify(fullParsedData));

      // Get submission to update using uuid
      const targetSubmissionResponse = await fetch(`${SUBMISSIONS_API_URL}?filters[uuid][$eq]=${uuid}`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          'Content-type': 'application/json',
        },
      });
      const targetSubmissionData = await targetSubmissionResponse.json();

      const { data: targetSubmissionInfo, error: targetSubmissionError } = targetSubmissionData;

      if (targetSubmissionError) throw targetSubmissionError;

      // eslint-disable-next-line no-throw-literal
      if (!targetSubmissionInfo.length) throw 'Submission not found';

      // Get submission ID from response
      const { id: submissionId } = targetSubmissionInfo[0];

      // Send data to corresponding submission
      const response = await fetch(
        `${SUBMISSIONS_API_URL}/${submissionId}?populate[businessClassification][populate][businessClassificationCategories][populate]=*&populate=invite`,
        {
          method: 'PUT',
          mode: 'cors',
          body: formData,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
          },
        },
      );

      const responseData = await response?.json();
      if (!responseData?.error) {
        navigate(`/thank-you${search}`);
      } else {
        throw responseData?.error;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setServerErrorMsg(err?.message || err || 'Something went wrong. Please try again later.');
      console.error(err);
    }
  };

  const screenDimensions = useDimensions();
  const theme = useTheme();
  const isMobile = screenDimensions.width <= theme.breakpoints.xsmall;

  const Step2 = (
    <Components.Step1
      title={steps[1]?.title}
      onButtonClick={() => {
        dispatch(setCurrentStep(1));
        navigate(`/form${search}`);
      }}
    >
      <Step2Form fieldValues={submission.gBI} disableAll />
    </Components.Step1>
  );
  const Step3 = (
    <Components.Step2
      title={steps[2]?.title}
      showBorder
      onButtonClick={() => {
        dispatch(setCurrentStep(2));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep3 fieldValues={submission.vendorActivity} />
    </Components.Step2>
  );
  const Step4 = (
    <Components.Step3
      title={steps[3]?.title}
      showBorder
      onButtonClick={() => {
        dispatch(setCurrentStep(3));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep4 fieldValues={submission.businessEntity} />
    </Components.Step3>
  );
  const Step5 = (
    <Components.Step4
      title={steps[4]?.title}
      onButtonClick={() => {
        dispatch(setCurrentStep(4));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep5 fieldValues={submission.sustainabilityPolicy} />
    </Components.Step4>
  );
  const Step6 = (
    <Components.Step5
      title={steps[5]?.title}
      showBorder
      onButtonClick={() => {
        dispatch(setCurrentStep(5));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep6 fieldValues={submission.irsForm} />
    </Components.Step5>
  );
  const Step7 = (
    <Components.Step6
      title={steps[6]?.title}
      showBorder
      onButtonClick={() => {
        dispatch(setCurrentStep(6));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep7 fieldValues={submission.conflictOfInterest} />
    </Components.Step6>
  );
  const Step8 = (
    <Components.Step7
      title={steps[7]?.title}
      showBorder
      onButtonClick={() => {
        dispatch(setCurrentStep(7));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep8 fieldValues={submission?.businessClassification} />
    </Components.Step7>
  );
  const Step9 = (
    <Components.Step9
      title={steps[8]?.title}
      showBorder
      onButtonClick={() => {
        dispatch(setCurrentStep(8));
        navigate(`/form${search}`);
      }}
    >
      <ReviewStep9 fieldValues={submission.responsabilityCertification} />
    </Components.Step9>
  );
  return (
    <Components.GridContainer className={`review-grid ${variant === 'admin' ? 'is-admin' : ''}`}>
      <Components.PillContainer>
        <Pill>{submission.businessUnit || storedBusinessUnits[1]}</Pill>
      </Components.PillContainer>
      <Components.Col1>
        {!isMobile ? (
          <>
            {Step2}
            {Step5}
            {Step8}
          </>
        ) : (
          <>
            {Step2}
            {Step3}
            {Step4}
            {Step5}
            {Step6}
            {Step7}
            {Step8}
            {Step9}
          </>
        )}
      </Components.Col1>
      {!isMobile ? (
        <Components.Col2 className="review-grid__col2">
          {Step3}
          {Step4}
          {Step6}
          {Step7}
          {Step9}
        </Components.Col2>
      ) : null}

      {showButtons ? (
        <ButtonSection>
          <div>
            <Components.ButtonsWrapper>
              <Button
                variant="secondary"
                onClick={() => {
                  dispatch(setCurrentStep(1));
                  navigate(`/form${search}`);
                }}
              >
                Edit Form
              </Button>
              <Button
                disabled={isLoading}
                onClick={async () => {
                  setIsLoading(true);
                  await handleSubmit();
                  setIsLoading(false);
                }}
              >
                {isLoading ? '...Processing' : 'Submit'}
              </Button>
            </Components.ButtonsWrapper>
            {serverErrorMsg ? <ErrorMsg>{serverErrorMsg}</ErrorMsg> : null}
          </div>
        </ButtonSection>
      ) : null}
    </Components.GridContainer>
  );
}
