import styled from 'styled-components/macro';

const Container = styled.div`
  width: ${({ theme }) => `${theme.getValue(420)}px`};
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.getValue(24)}px`};
`;

export default { Container, ButtonContainer };
