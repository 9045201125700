/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface BusinessUnitsState {
  businessUnits: string[];
}

const initialState: BusinessUnitsState = {
  businessUnits: [''],
};

export const businessUnitsSlice = createSlice({
  name: 'businessUnitsSlice',
  initialState,
  reducers: {
    setBusinessUnits: (state, action: PayloadAction<string[]>) => {
      state.businessUnits = action.payload;
    },
  },
});

export const { setBusinessUnits } = businessUnitsSlice.actions;
export default businessUnitsSlice.reducer;
