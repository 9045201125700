/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-explicit-any */
import qs from 'qs';
import JsPDF from 'jspdf';
import { PDFDocument } from 'pdf-lib';
import PDFMerger from 'pdf-merger-js/browser';
import { useState, useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { isTablet } from 'react-device-detect';
import { useSelector } from 'react-redux';
import BackArrow from '../../../components/BackArrow';
import AdminPage from '../../../components/Page/AdminPage';
import ReviewGrid from '../../../content/ReviewGrid';
import { ReviewGridSubmissionType } from '../../../content/ReviewGrid/types';
import { ReviewScreenPropsType } from './types';
import Components from './styles';
import Handle401Error from '../../../components/Handle401Error';
import GBIType from '../../../content/Step2/types';
import { PAYMENT_TERMS } from '../../../constants';
import PDFScreen from '../PDFScreen/PDFScreen';
import { RootState } from '../../../store/store';
import useScrollToTop from '../../../content/stepUtils';

const query = qs.stringify(
  {
    populate: [
      'gBI',
      'vendorActivity',
      'businessEntity',
      'conflictOfInterest',
      'businessClassification.businessClassificationCategories.relatedFiles',
      'irsForm',
      'sustainabilityPolicy',
      'sustainabilityPolicy.sustainabilityPolicyFiles',
      'sustainabilityPolicy.environmentPolicyFiles',
      'sustainabilityPolicy.socialResponsibilityFiles',
      'sustainabilityPolicy.sustainabilityProcurementFiles',
      'responsabilityCertification',
      'responsabilityCertification.vendorCertifyFiles',
      'invite.businessUnit',
    ],
  },
  {
    encodeValuesOnly: true, // prettify URL
  },
);
const parseGBI = (gBIData: GBIType) => ({
  gBI: {
    ...gBIData,
    stp: PAYMENT_TERMS.includes(gBIData.stp) ? gBIData.stp : PAYMENT_TERMS[PAYMENT_TERMS.length - 1],
    stpOther: PAYMENT_TERMS.includes(gBIData.stp) ? '' : gBIData.stp,
  },
});
const parseSubmissionInfo = (submissionToParse: ReviewGridSubmissionType) => ({
  ...submissionToParse,
  ...parseGBI(submissionToParse.gBI),
});
function ReviewScreen(props: ReviewScreenPropsType) {
  useScrollToTop();
  const [currentSubmission, setCurrentSubmission] = useState<ReviewGridSubmissionType | null>(null);
  const [isPrintContentVisible, setPrintContentVisible] = useState(false);
  const [is401Error, setIs401Error] = useState<boolean>(false);
  const params = useParams();
  const { search } = useLocation();
  const { id: submissionId } = params;
  const { user } = props;
  const { jwt } = user;
  const storedBusinessUnits = useSelector((state: RootState) => state.businessUnits.businessUnits);

  const queryString = useMemo(() => new URLSearchParams(search), [search]);
  const navigate = useNavigate();

  const generatePDF = useCallback(
    async (callback = () => {}) => {
      const isValidUrl = (urlString: string) => {
        const urlPattern = new RegExp(
          '^(https?:\\/\\/)?' + // validate protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
            '(\\#[-a-z\\d_]*)?$',
          'i',
        ); // validate fragment locator
        return !!urlString && !!urlPattern.test(urlString);
      };

      setPrintContentVisible(true);
      setTimeout(async () => {
        const report = new JsPDF('portrait', 'pt', 'a4');
        await report.html(document.querySelector('#report') as any, {
          autoPaging: 'text',
          margin: [32, 0, 32, 0],
        });
        const generatedReport = await report.output('blob');
        const merger = new PDFMerger();

        const filesList: (Blob | string)[] = [generatedReport];

        //  Signature
        if (isValidUrl(currentSubmission?.irsForm?.signature || '')) {
          try {
            const fileResponse = await fetch(currentSubmission?.irsForm?.signature || '');
            const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
            const pages = pdfDoc.getPages();

            // Get the first page of the document
            const firstPage = pages[0];
            // Get the width and height of the first page
            const { height } = firstPage.getSize();
            pages[0].drawText(`Substitute IRS Form W-9 Certification Signature`, {
              x: 5,
              y: height - 12,
              size: 10,
            });
            const pdfBytes = await pdfDoc.save();
            filesList.push(pdfBytes as any);
          } catch (e) {
            console.error(e, 'error parsing signature document');
          }
        }

        // Sustainability Policy
        await Promise.all(
          ((currentSubmission as any)?.sustainabilityPolicy?.sustainabilityPolicyFiles?.data || []).map(
            async (file: any) => {
              if (isValidUrl(file.attributes?.url || '')) {
                const fileResponse = await fetch(file?.attributes?.url || '');
                const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
                const pages = pdfDoc.getPages();

                // Get the first page of the document
                const firstPage = pages[0];
                // Get the width and height of the first page
                const { height } = firstPage.getSize();
                pages[0].drawText(`Sustainability Policies - ${file.attributes?.name}`, {
                  x: firstPage.getRotation().angle === 90 ? 12 : 5,
                  y: firstPage.getRotation().angle === 90 ? 5 : height - 12,
                  size: 10,
                  rotate: firstPage.getRotation(),
                });
                const pdfBytes = await pdfDoc.save();
                filesList.push(pdfBytes as any);
              }
            },
          ),
        );
        await Promise.all(
          ((currentSubmission as any)?.sustainabilityPolicy?.environmentPolicyFiles?.data || []).map(
            async (file: any) => {
              if (isValidUrl(file.attributes?.url || '')) {
                const fileResponse = await fetch(file?.attributes?.url || '');
                const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
                const pages = pdfDoc.getPages();

                // Get the first page of the document
                const firstPage = pages[0];
                // Get the width and height of the first page
                const { height } = firstPage.getSize();
                pages[0].drawText(`Environment Policies - ${file.attributes?.name}`, {
                  x: firstPage.getRotation().angle === 90 ? 12 : 5,
                  y: firstPage.getRotation().angle === 90 ? 5 : height - 12,
                  size: 10,
                  rotate: firstPage.getRotation(),
                });
                const pdfBytes = await pdfDoc.save();
                filesList.push(pdfBytes as any);
              }
            },
          ),
        );
        await Promise.all(
          ((currentSubmission as any)?.sustainabilityPolicy?.socialResponsibilityFiles?.data || []).map(
            async (file: any) => {
              if (isValidUrl(file.attributes?.url || '')) {
                const fileResponse = await fetch(file?.attributes?.url || '');
                const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
                const pages = pdfDoc.getPages();

                // Get the first page of the document
                const firstPage = pages[0];
                // Get the width and height of the first page
                const { height } = firstPage.getSize();
                pages[0].drawText(`Social Responsibility Policies - ${file.attributes?.name}`, {
                  x: firstPage.getRotation().angle === 90 ? 12 : 5,
                  y: firstPage.getRotation().angle === 90 ? 5 : height - 12,
                  size: 10,
                  rotate: firstPage.getRotation(),
                });
                const pdfBytes = await pdfDoc.save();
                filesList.push(pdfBytes as any);
              }
            },
          ),
        );
        await Promise.all(
          ((currentSubmission as any)?.sustainabilityPolicy?.sustainabilityProcurementFiles?.data || []).map(
            async (file: any) => {
              if (isValidUrl(file.attributes?.url || '')) {
                const fileResponse = await fetch(file?.attributes?.url || '');
                const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
                const pages = pdfDoc.getPages();

                // Get the first page of the document
                const firstPage = pages[0];
                // Get the width and height of the first page
                const { height } = firstPage.getSize();
                pages[0].drawText(`Sustainability Policies - ${file.attributes?.name}`, {
                  x: firstPage.getRotation().angle === 90 ? 12 : 5,
                  y: firstPage.getRotation().angle === 90 ? 5 : height - 12,
                  size: 10,
                  rotate: firstPage.getRotation(),
                });
                const pdfBytes = await pdfDoc.save();
                filesList.push(pdfBytes as any);
              }
            },
          ),
        );

        //  Business Classification
        await Promise.all(
          ((currentSubmission as any)?.businessClassification?.businessClassificationCategories || []).map(
            async (cat: any) => {
              try {
                await Promise.all(
                  cat.relatedFiles?.data?.map(async (file: any) => {
                    if (isValidUrl(file.attributes?.url || '')) {
                      const fileResponse = await fetch(file?.attributes?.url || '');
                      const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
                      const pages = pdfDoc.getPages();

                      // Get the first page of the document
                      const firstPage = pages[0];
                      // Get the width and height of the first page
                      const { height } = firstPage.getSize();
                      pages[0].drawText(`Business Classification Certification - ${file.attributes?.name}`, {
                        x: firstPage.getRotation().angle === 90 ? 12 : 5,
                        y: firstPage.getRotation().angle === 90 ? 5 : height - 12,
                        size: 10,
                        rotate: firstPage.getRotation(),
                      });
                      const pdfBytes = await pdfDoc.save();
                      filesList.push(pdfBytes as any);
                    }
                  }),
                );
              } catch (error) {
                console.error(error);
              }
            },
          ),
        );

        // Responsability Certification
        await Promise.all(
          ((currentSubmission as any)?.responsabilityCertification?.vendorCertifyFiles?.data || []).map(
            async (file: any) => {
              if (isValidUrl(file.attributes?.url || '')) {
                const fileResponse = await fetch(file?.attributes?.url || '');
                const pdfDoc = await PDFDocument.load(await fileResponse.arrayBuffer());
                const pages = pdfDoc.getPages();

                // Get the first page of the document
                const firstPage = pages[0];
                // Get the width and height of the first page
                const { height } = firstPage.getSize();
                pages[0].drawText(
                  `Debarment, Suspension, and Other Responsibility Matters Certification - ${file.attributes?.name}`,
                  {
                    x: firstPage.getRotation().angle === 90 ? 12 : 5,
                    y: firstPage.getRotation().angle === 90 ? 5 : height - 12,
                    size: 10,
                    rotate: firstPage.getRotation(),
                  },
                );
                const pdfBytes = await pdfDoc.save();
                filesList.push(pdfBytes as any);
              }
            },
          ),
        );

        // eslint-disable-next-line no-restricted-syntax
        for (const file of filesList) {
          // eslint-disable-next-line no-await-in-loop
          await merger.add(file);
        }

        // Save & Download PDF
        await merger.save(
          `Vendor Form - ${
            currentSubmission?.gBI.nameOfBusiness ||
            `${currentSubmission?.gBI.firstName} ${currentSubmission?.gBI.lastName}`
          }`,
        );
        setPrintContentVisible(false);
        callback();
      }, 500);
    },
    [setPrintContentVisible, currentSubmission],
  );

  const fetchSubmissionInfo = useCallback(async () => {
    try {
      const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/submissions/${submissionId}?${query}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      const fetchData = await fetchResponse.json();
      const { data, error } = fetchData;
      if (error) throw error;
      const { attributes } = data;
      const {
        gBI,
        vendorActivity,
        businessEntity,
        conflictOfInterest,
        businessClassification,
        irsForm,
        sustainabilityPolicy,
        responsabilityCertification,
        invite,
      } = attributes;

      return {
        gBI,
        vendorActivity,
        businessEntity,
        conflictOfInterest,
        businessClassification,
        irsForm,
        sustainabilityPolicy,
        responsabilityCertification,
        businessUnit: invite?.businessUnit,
      };
    } catch (error: any) {
      console.error('Error fetching Review info', error);
      if (error?.status === 401) {
        setIs401Error(true);
      }
      throw error;
    }
  }, [jwt, submissionId]);

  useEffect(() => {
    const getSubmissionInfo = async () => {
      try {
        const submissionInfo = await fetchSubmissionInfo();
        setCurrentSubmission(parseSubmissionInfo(submissionInfo));
      } catch (error) {
        console.error(error);
      }
    };
    getSubmissionInfo();
  }, [fetchSubmissionInfo]);

  useEffect(() => {
    const handleDownload = async () => {
      await generatePDF(() => {
        window.opener = null;
        window.open('', '_self');
        window.close();
      });
    };
    const isDownloadMode = queryString.get('download');
    if (isDownloadMode && currentSubmission) {
      handleDownload();
    }
  }, [queryString, currentSubmission, generatePDF]);

  return (
    <AdminPage>
      {currentSubmission ? (
        <Handle401Error isActive={is401Error} user={user}>
          <Components.Container>
            <BackArrow onClick={() => navigate('/home')} />
            <Components.DownloadContainer>
              <Components.DownloadButton disabled={isTablet || isPrintContentVisible} onClick={() => generatePDF()}>
                {isPrintContentVisible ? 'Processing' : 'Download'}
              </Components.DownloadButton>
              {isTablet && (
                <Components.Description>Files cannot be downloaded to a tablet device.</Components.Description>
              )}
            </Components.DownloadContainer>
            <Components.Title>
              {currentSubmission?.gBI.nameOfBusiness ||
                `${currentSubmission?.gBI.firstName} ${currentSubmission?.gBI.lastName}`}
            </Components.Title>
          </Components.Container>
          <ReviewGrid variant="admin" submission={currentSubmission} />
          {isPrintContentVisible && (
            <PDFScreen
              submission={currentSubmission}
              title={
                currentSubmission?.gBI.nameOfBusiness ||
                `${currentSubmission?.gBI.firstName} ${currentSubmission?.gBI.lastName}`
              }
              businessUnit={currentSubmission.businessUnit || storedBusinessUnits[1]}
            />
          )}
        </Handle401Error>
      ) : null}
    </AdminPage>
  );
}

export default ReviewScreen;
