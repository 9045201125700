import { PillPropsType } from './types';
import Components from './styles';

function Pill(props: PillPropsType) {
  const { children, className } = props;
  return (
    <Components.Container className={className}>
      <Components.Label>{children}</Components.Label>
    </Components.Container>
  );
}

export default Pill;
