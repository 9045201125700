/* eslint-disable @typescript-eslint/no-explicit-any */
import AttachedFilesList from '../../../../components/AttachedFilesList';
import { PrintLabel } from '../../../PrintTemplate/styles';
import { Container } from './styles';
import { ReviewStep8PropsType } from './types';

export default function ReviewStep8(props: ReviewStep8PropsType) {
  const { fieldValues: businessClassificationData } = props;
  const { businessClassificationCategories } = businessClassificationData;

  if (!businessClassificationCategories.length) {
    return null;
  }
  return (
    <>
      {businessClassificationCategories.map(cat => {
        return (
          <Container key={cat.internalId}>
            <h2>{cat.title}</h2>
            {cat.description ? <PrintLabel dangerouslySetInnerHTML={{ __html: cat.description }} /> : null}
            {(cat.relatedFiles as any)?.data?.length ? (
              <AttachedFilesList
                files={(cat.relatedFiles as any).data.map((file: Record<string, any>) => ({
                  name: file.attributes.name,
                  url: '',
                }))}
              />
            ) : null}
          </Container>
        );
      })}
    </>
  );
}
