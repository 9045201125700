import Components from './styles';
import { ReviewStep3PropsType } from './types';
import { PrintLabel, PrintValue } from '../../../PrintTemplate/styles';

export function ReviewStep3Print(props: ReviewStep3PropsType) {
  const { fieldValues: vendorActivity } = props;

  const { vendorActivity: activity, other } = vendorActivity;
  return (
    <>
      <Components.PrintActivityLabel>{activity}</Components.PrintActivityLabel>
      {other ? (
        <>
          <PrintLabel>Please specify</PrintLabel>
          <PrintValue>{other}</PrintValue>
        </>
      ) : null}
    </>
  );
}

export default ReviewStep3Print;
