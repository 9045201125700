import { useFormik } from 'formik';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useState } from 'react';
import _ from 'lodash';
import { RootState } from '../../store/store';
import { decrement, increment } from '../../store/slices/stepSlice';
import { setBusinessClassification } from '../../store/slices/businessClassificationSlice';
import { StyledHeadline as Headline, ButtonSection } from '../Step2/styles';
import Button from '../../components/Button';
import CheckboxUpload from '../../components/CheckboxUpload';
import businessCategories from './content/businessClassificationCategories';
import { Error } from '../../components/Input/styles';
import BusinessClassificationType, {
  BusinessClassificationCategoryType,
  Step8Schema as validationSchema,
} from './types';
import { Container, Description, BusinessTypeContainer } from './styles';
import { FileArrayType } from '../../types';
import useScrollToTop from '../stepUtils';

const localUuid = uuid();
function Step8() {
  useScrollToTop();
  const [currentlyExpandedCategories, setCurrentlyExpandedCategories] = useState<string[]>([]);

  const businessClasificationData: BusinessClassificationType = useSelector(
    (state: RootState) => state.businessClassification,
  );

  const dispatch = useDispatch();

  const { values, errors, touched, handleSubmit, setFieldValue, setFieldTouched, isValid } = useFormik({
    initialValues: businessClasificationData,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: vals => {
      dispatch(setBusinessClassification(vals));
      dispatch(increment());
    },
    validationSchema,
  });

  const addToSelectedCategories = useCallback(
    (categoryObj: BusinessClassificationCategoryType) => {
      const isInState = values.businessClassificationCategories.some(
        (cat: BusinessClassificationCategoryType) => cat.internalId === categoryObj.internalId,
      );
      if (isInState) return;
      const newBusinessClassificationArr = [...values.businessClassificationCategories, categoryObj];
      setFieldValue('businessClassificationCategories', newBusinessClassificationArr);
    },
    [setFieldValue, values.businessClassificationCategories],
  );

  const removeFromSelectedCategories = useCallback(
    (id: string) => {
      const isInState = values.businessClassificationCategories.some(cat => cat?.internalId === id);
      if (!isInState) return;
      const newBusinessClassificationArr = values.businessClassificationCategories.filter(cat => cat.internalId !== id);
      setFieldValue('businessClassificationCategories', newBusinessClassificationArr);
    },
    [setFieldValue, values.businessClassificationCategories],
  );

  const handleCategorySelection = useCallback(
    (isChecked: boolean, index: number) => {
      const selectedCategory = businessCategories[index];
      setFieldTouched(`businessClassificationCategories[${index}]`);
      if (!isChecked) {
        // add to categoriesArray
        addToSelectedCategories({
          ...selectedCategory,
          internalId: `${selectedCategory.internalId}`,
          relatedFiles: [],
        });
      } else {
        // remove from categoriesArray
        removeFromSelectedCategories(`${selectedCategory.internalId}`);
      }
    },
    [addToSelectedCategories, removeFromSelectedCategories, setFieldTouched],
  );

  const handleCategoryAccordionBtnClick = useCallback(
    (catId: string) => {
      const isExpanded = currentlyExpandedCategories.includes(catId);
      if (isExpanded) {
        setCurrentlyExpandedCategories(currentlyExpandedCategories.filter(categoryId => catId !== categoryId));
      } else {
        setCurrentlyExpandedCategories([...currentlyExpandedCategories, catId]);
      }
    },
    [currentlyExpandedCategories],
  );

  const handleSaveFiles = (id: string, filesArr: FileArrayType) => {
    const targetCategory = values.businessClassificationCategories.find(cat => cat.internalId === id);
    // if related category is currently selected, attach files to it and update currently selected categories.
    if (typeof targetCategory !== 'undefined') {
      targetCategory.relatedFiles = filesArr;
      setFieldValue(
        'businessClassificationCategories',
        values.businessClassificationCategories.map(cat => (cat.internalId === id ? targetCategory : cat)),
      );
    } else {
      // if related category is not currently selected, get data from content, attach files and add to currently selected categories.
      const targetCatData = businessCategories.find(cat => cat.internalId === id);

      addToSelectedCategories({
        internalId: targetCatData?.internalId || '',
        description: targetCatData?.description || '',
        title: targetCatData?.title || '',
        relatedFiles: filesArr,
        requireFiles: targetCatData?.requireFiles,
      });
    }
  };

  return (
    <Container>
      <Headline>Business Classification</Headline>
      <Description>
        Please select the appropriate business classification(s) below. If needed, expand the title to learn more about
        the classification. Any classification marked with (*) requires a PDF copy of certification(s). If you are an
        individual and none of the options apply to you, please check the box marked “Other”.
      </Description>
      <BusinessTypeContainer>
        {businessCategories.map((businessCategory, index) => {
          const isChecked = values.businessClassificationCategories.some(
            cat => `${cat.internalId}` === `${businessCategory.internalId}`,
          );
          return (
            <CheckboxUpload
              key={`${localUuid}-${businessCategory.title.replace(' ', '-').toLowerCase()}`}
              id={`businessClassificationCategories[${index}]`}
              name={`businessClassificationCategories[${index}]`}
              title={`${businessCategory.title} ${businessCategory.requireFiles ? '(*)' : ''}`}
              description={businessCategory.description}
              enableUploadControl={businessCategory.requireFiles && isChecked}
              uploadLabel={businessCategory.uploadLabel}
              collapsible={businessCategory.title !== 'Other'}
              onChange={() => {
                handleCategorySelection(
                  values.businessClassificationCategories.some(
                    cat => `${cat.internalId}` === `${businessCategory.internalId}`,
                  ),
                  index,
                );
              }}
              filesUploaded={
                values.businessClassificationCategories.find(cat => cat.internalId === businessCategory.internalId)
                  ?.relatedFiles
              }
              filesLocation={`${businessCategory.internalId}`}
              saveFiles={handleSaveFiles}
              isChecked={isChecked}
              isOpen={currentlyExpandedCategories.includes(`${businessCategory.internalId}`)}
              onAccordionBtnClick={() => {
                handleCategoryAccordionBtnClick(`${businessCategory.internalId}`);
              }}
              touched={!!(touched?.businessClassificationCategories || [])[index] || false}
              error={
                _.isArray(errors.businessClassificationCategories) &&
                typeof (
                  errors.businessClassificationCategories[
                    values.businessClassificationCategories.findIndex(
                      cat => cat.internalId === businessCategory.internalId,
                    )
                  ] as BusinessClassificationCategoryType
                )?.relatedFiles === 'string'
                  ? (
                      errors.businessClassificationCategories[
                        values.businessClassificationCategories.findIndex(
                          cat => cat.internalId === businessCategory.internalId,
                        )
                      ] as BusinessClassificationCategoryType
                    )?.relatedFiles
                  : ''
              }
              filesErrors={
                _.isArray(errors.businessClassificationCategories) &&
                _.isArray(
                  (
                    errors.businessClassificationCategories[
                      values.businessClassificationCategories.findIndex(
                        cat => cat.internalId === businessCategory.internalId,
                      )
                    ] as BusinessClassificationCategoryType
                  )?.relatedFiles,
                )
                  ? (
                      errors.businessClassificationCategories[
                        values.businessClassificationCategories.findIndex(
                          cat => cat.internalId === businessCategory.internalId,
                        )
                      ] as BusinessClassificationCategoryType
                    )?.relatedFiles
                  : []
              }
            />
          );
        })}
      </BusinessTypeContainer>
      {errors.businessClassificationCategories && typeof errors.businessClassificationCategories === 'string' ? (
        <Error>{errors.businessClassificationCategories}</Error>
      ) : null}
      <ButtonSection>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Back
        </Button>
        <Button
          disabled={!isValid}
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </ButtonSection>
    </Container>
  );
}

export default Step8;
