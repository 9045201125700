import styled from 'styled-components/macro';
import { StyledInput as Input } from '../Step2/styles';
import Button from '../../components/Button';

const Container = styled.div``;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(48)}px`};
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: ${({ theme }) => `${theme.getValue(18)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  margin: ${({ theme }) => `${theme.getValue(14)}px 0`};
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInput = styled(Input)`
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    margin-top: 0;
  }
`;

export const PhoneInputWrapper = styled.div`
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: 100%;
  }
  & > div {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      margin-top: 0;
    }
  }
`;

export const FormFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  ${StyledInput}, ${PhoneInputWrapper} {
    width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
    min-width: 0;
  }
`;

export const SentContainer = styled.div`
  max-width: 40%;
  text-align: right;
`;

export const SignButton = styled(Button)`
  padding-top: 1px;
  padding-bottom: 1px;
  height: ${({ theme }) => `${theme.getValue(40)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(30)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    margin-top: 0;
    margin-left: ${({ theme }) => `${theme.getValue(-32)}px`};
    width: calc(100% + ${({ theme }) => `${theme.getValue(32)}px`});
  }
`;
export default { Container, ButtonSection, Label, InputContainer, SentContainer, SignButton };
