import { useParams } from 'react-router-dom';
import Page from '../../../../components/Page';
import resources from '../../../../content/vendor-resources';
import Components from './styles';

export default function ResourceScreen() {
  const params = useParams();

  const resourceIndex = params.number;
  const renderContent = () => {
    if (!resourceIndex)
      return (
        <Components.Wrapper>
          <p>Resource not found</p>
        </Components.Wrapper>
      );
    try {
      const currentIndex = parseInt(resourceIndex, 10) - 1;
      const currentResource = resources[currentIndex];
      return (
        <Components.Wrapper>
          <Components.Title>{currentResource?.title}</Components.Title>
          <Components.Container>{currentResource?.content}</Components.Container>
        </Components.Wrapper>
      );
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  return <Page>{renderContent()}</Page>;
}
