import styled from 'styled-components/macro';
import { Title as ReviewTitle } from '../ReviewScreen/styles';
import Pill from '../../../components/Pill';

const PrintWrapper = styled.div`
  width: 550px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  * {
    font-family: ${({ theme }) => `${theme.fonts.print}`} !important;
  }
`;

const Title = styled(ReviewTitle)`
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  margin-top: 24px;
  margin-bottom: 12px;
`;

const PrintHeader = styled.div`
  background-color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  height: 54px;
  display: flex;
  justify-content: space-between;
  padding: 15px 34px;
`;

const PrintLogo = styled.div`
  width: 117px;
  img {
    display: block;
    width: 100%;
  }
`;

const StyledPill = styled(Pill)`
  span {
    font-size: ${({ theme }) => `${theme.getValue(8)}px`};
    line-height: 2;
    display: block;
  }
`;

export default {
  PrintWrapper,
  Title,
  PrintHeader,
  PrintLogo,
  StyledPill,
};
