import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { decrement, increment } from '../../store/slices/stepSlice';
import { setSubsituteIrsFormState } from '../../store/slices/irsFormSlice';
import CheckboxItem from '../../components/CheckboxItem';
import Headline from '../../components/typography/Headline';
import certificates from './data/witholding';
import Button from '../../components/Button';
import SignedDocument from '../../components/SignedDocument';
import IrsFormType, { Step6Schema as validationSchema } from './types';
import { ButtonSection, FormFlexContainer } from '../Step2/styles';
import { Label, InputContainer, SignButton, PhoneInputWrapper, StyledInput as Input } from './styles';
import { RootState } from '../../store/store';
import PhoneInput from '../../components/Input/PhoneInput';
import { formatDate } from '../../utils';
import { formatAccountNumber } from '../../utils/formating';
import useScrollToTop from '../stepUtils';

export default function Step6() {
  useScrollToTop();
  const dispatch = useDispatch();
  const irsCertData: IrsFormType = useSelector((state: RootState) => state.irsForm);
  const { email } = useSelector((state: RootState) => state.gBI);
  const { accountNumber, businessEntity } = useSelector((state: RootState) => state.businessEntity);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, setFieldTouched, isValid } =
    useFormik({
      initialValues: { ...irsCertData, date: formatDate(new Date(irsCertData.date)) },
      validateOnChange: true,
      onSubmit: vals => {
        dispatch(setSubsituteIrsFormState({ ...vals, date: new Date(vals.date).toISOString() }));
        dispatch(increment());
      },
      validationSchema,
    });
  const [isSigning, setSigning] = useState(false);
  const handleSign = async (template?: string) => {
    setSigning(true);
    const body = {
      template_uuid: template,
      signature_request: {
        subject: 'Substitute IRS Form W-9 Certification',
        message: 'Substitute IRS Form W-9 Certification Signature',
        redirect_url: `${window.location.origin}/util`,
      },
      template_recipients: [
        {
          email: email.toLowerCase(),
          role: 'Signer',
          fields: [
            { name: 'Date Signed', value: values.date },
            { name: 'Tax Number', value: formatAccountNumber(accountNumber, businessEntity) },
            { name: 'Signer Title', value: values.title },
            { name: 'Phone', value: values.phoneNumber },
          ],
        },
      ],
    };
    const windowReference = window.open();
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/sign/`, {
        method: 'POST',
        headers: {
          Authorization: `bearer ${process.env.REACT_APP_API_TOKEN}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();

      if (data.view_url && windowReference) {
        setTimeout(() => {
          windowReference.location = data.view_url;
          // window.open(data.view_url, '_blank', 'noreferrer');
        }, 100);
        // window.open(data.view_url, '_blank', 'noreferrer');
      }
      setSigning(false);
    } catch (error) {
      console.error(error);
      setSigning(false);
    }
  };

  const getLink = useCallback(
    async (url: string) => {
      setSigning(true);
      const body = {
        url,
      };
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/signed`, {
          method: 'POST',
          headers: {
            Authorization: `bearer ${process.env.REACT_APP_API_TOKEN}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(body),
        });
        const data = await response.json();
        if (data.url) {
          setFieldValue('signature', data.url);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setSigning(false);
      }
    },
    [setFieldValue],
  );

  useEffect(() => {
    window.onstorage = event => {
      if (event.key !== 'PDF_URL') return;
      const baseUrl = localStorage.getItem('PDF_URL');
      if (!baseUrl) return;
      getLink(baseUrl);
      localStorage.removeItem('PDF_URL');
    };
  }, [getLink]);

  useEffect(() => {
    setFieldValue('signature', undefined);
  }, [setFieldValue]);

  const changeValues = (value: string) => {
    if (!values.withholding.startsWith(value)) {
      setFieldValue('withholding', value);
      setFieldValue('title', '');
      setFieldTouched('title', false);
      setFieldValue('phoneNumber', '');
      setFieldTouched('phoneNumber', false);
      setFieldValue('signature', '');
      setFieldTouched('signature', false);
    }
  };

  return (
    <>
      <Headline>Substitute IRS Form W-9 Certification</Headline>
      <div>
        {certificates.map(certificate => {
          return (
            <CheckboxItem
              key={certificate.title}
              name="withholding"
              title={certificate?.title}
              description={certificate?.description}
              value={`${certificate?.title}|${certificate.certify}`}
              onClick={() => {
                changeValues(`${certificate?.title}|${certificate.certify}`);
              }}
              onBlur={handleBlur}
              isChecked={`${certificate?.title}|${certificate.certify}` === values.withholding}
              touched={touched.withholding}
              error={errors.withholding}
            >
              {values.withholding && (
                <InputContainer>
                  <Label>{certificate.certify}</Label>
                  <FormFlexContainer>
                    <Input
                      type="date"
                      id="date"
                      name="date"
                      label="Date*"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.date}
                      error={errors.date}
                    />
                    <Input
                      type="text"
                      id="title"
                      name="title"
                      label="Title*"
                      value={values.title}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.title}
                      error={errors.title}
                    />
                    <PhoneInputWrapper>
                      <PhoneInput
                        id="phoneNumber"
                        name="phoneNumber"
                        label="Phone Number*"
                        value={values.phoneNumber}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        touched={touched.phoneNumber}
                        error={errors.phoneNumber}
                      />
                    </PhoneInputWrapper>
                    {values.signature ? (
                      <SignedDocument link={values.signature} />
                    ) : (
                      <SignButton
                        disabled={!values.title || !values.phoneNumber || !values.date || isSigning}
                        onClick={() => {
                          handleSign(certificate.template);
                        }}
                      >
                        {isSigning ? 'Signing...' : 'Sign Form'}
                      </SignButton>
                    )}
                  </FormFlexContainer>
                </InputContainer>
              )}
            </CheckboxItem>
          );
        })}
      </div>
      <ButtonSection>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Back
        </Button>
        <Button
          disabled={!isValid || !values.withholding || !values.signature}
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </ButtonSection>
    </>
  );
}
