import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUser, userState } from '../../store/slices/userSlice';
import { setSessionExpired } from '../../store/slices/sessionStateSlice';

type HandleError401Props = {
  isActive: boolean;
  user: userState;
  children: React.ReactNode | React.ReactElement | null;
};

function Handle401Error(props: HandleError401Props) {
  const { user, isActive, children } = props;
  const { user: userObj } = user;
  const dispatch = useDispatch();

  useEffect(() => {
    if (isActive && userObj) {
      // Invalidate current credentials
      dispatch(setUser({ jwt: '', user: null }));
      dispatch(setSessionExpired(true));
    }
  }, [isActive, dispatch, userObj]);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
}

export default Handle401Error;
