import { useSelector } from 'react-redux';
import Page from '../../../components/Page';
import type { RootState } from '../../../store/store';
import stepList from '../../../content/stepList';
import MenuDropdown from '../../../components/MenuDropdown';
import StepList from '../../../components/StepList/StepList';
import { FormScreenPropsType } from './types';

export default function FormScreen(props: FormScreenPropsType) {
  const { businessUnit } = props;
  const currentStep = useSelector((state: RootState) => state.step.currentStep);
  return (
    <Page
      headerContent={currentStep > 0 ? <MenuDropdown /> : null}
      layout="sidebar-col"
      sidebar={<StepList currentStep={currentStep} businessUnit={businessUnit} />}
    >
      {stepList[currentStep].content}
    </Page>
  );
}
