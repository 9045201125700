import styled from 'styled-components/macro';

export const Container = styled.div`
  line-height: ${({ theme }) => `${theme.getValue(12)}px`};
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(48)}px`};
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export const Label = styled.p`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: normal;
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  margin: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export const PoliciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => `${theme.getValue(19)}px`};
  gap: ${({ theme }) => `${theme.getValue(10)}px`};
`;

export const Headline2 = styled.h3`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: ${({ theme }) => `${theme.getValue(17)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  margin-top: ${({ theme }) => `${theme.getValue(32)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(6)}px`};
`;

export default { Container, ButtonSection, Label, PoliciesContainer, Headline2 };
