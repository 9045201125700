import styled from 'styled-components/macro';

export const Container = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.getValue(12)}px`} 0;
  min-width: ${({ theme }) => `${theme.getValue(295)}px`};
  position: relative;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    min-width: 0;
  }
  label {
    &.is-moved {
      position: relative;
      top: 24px;
    }
  }
  &.name-container {
    margin-top: ${({ theme }) => `${theme.getValue(7)}px`};
    margin-bottom: ${({ theme }) => `${theme.getValue(1)}px`};
  }
  &.input--phone {
    min-width: 0;
  }
`;

export const Input = styled.input`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  width: 100%;
  height: ${({ theme }) => `${theme.getValue(36)}px`};
  padding: ${({ theme }) => `${theme.getValue(10)}px`} ${({ theme }) => `${theme.getValue(12)}px`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  border: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
  border-radius: 4px;

  &:focus,
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 0;
    font-weight: ${({ theme }) => `${theme.weights.extrabold}`};
    line-height: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(24)}px`};
    padding-top: 0;
    padding-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
    &::placeholder {
      color: transparent;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: calc(16 / 14);
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export const Error = styled.p`
  color: ${({ theme }) => `${theme.colors.orange1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 1;
  margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
  margin-bottom: 0;
`;

export default { Container, Input, Label, Error };
