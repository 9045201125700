/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import IrsFormType from '../../content/Step6/types';
import { formatDate } from '../../utils';

const initialState: IrsFormType = {
  withholding: '',
  signature: '',
  title: '',
  phoneNumber: '',
  date: formatDate(new Date()),
};

export const subsituteIrsFormStateSlice = createSlice({
  name: 'withholding',
  initialState,
  reducers: {
    setSubsituteIrsFormState: (state, action: PayloadAction<IrsFormType>) => {
      return { ...action.payload };
    },
  },
});

export const { setSubsituteIrsFormState } = subsituteIrsFormStateSlice.actions;
export default subsituteIrsFormStateSlice.reducer;
