import styled from 'styled-components/macro';

const StepsContainer = styled.div`
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    display: flex;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    display: none;
  }
`;
const PillContainer = styled.div`
  margin-bottom: ${({ theme }) => `${theme.getValue(20)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    margin-bottom: ${({ theme }) => `${theme.getValue(10)}px`};
  }
`;
const MobileStepsContainer = styled.div`
  margin-bottom: ${({ theme }) => `${theme.getValue(20)}px`};
  display: none;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    display: block;
  }
`;
const StepsProgress = styled.div<{ currentProgress: string }>`
  width: 100%;
  border-radius: 12px;
  background-color: ${({ theme }) => `${theme.colors.gray4}`};
  height: 6px;
  position: relative;
  &:after {
    width: ${props => props.currentProgress};
    top: 0;
    left: 0;
    content: '';
    display: block;
    border-radius: 12px;
    position: absolute;
    height: 100%;
    transition: width 0.5s ease-out;
    background-size: cover;
    background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 280 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M280 3C280 4.65685 278.657 6 277 6L3 6C1.34314 6 0 4.65685 0 3C0 1.34315 1.34314 0 3 0L277 0C278.657 0 280 1.34315 280 3Z' fill='url(%23paint0_linear_1745_37808)'/%3E%3Cdefs%3E%3ClinearGradient id='paint0_linear_1745_37808' x1='280' y1='3' x2='-11.2' y2='2.99991' gradientUnits='userSpaceOnUse'%3E%3Cstop stop-color='%230090BA'/%3E%3Cstop offset='1' stop-color='%2310B981'/%3E%3C/linearGradient%3E%3C/defs%3E%3C/svg%3E");
    background-repeat: no-repeat;
  }
`;
const StepsCounter = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.medium}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  text-align: right;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  margin: 0;
  margin-top: ${({ theme }) => `${theme.getValue(10)}px`};
`;

export default { PillContainer, StepsContainer, MobileStepsContainer, StepsProgress, StepsCounter };
