import styled from 'styled-components/macro';
import { Label } from '../Input/styles';

export const Container = styled.div<{ variant?: string }>`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.getValue(12)}px`} 0;
  min-width: ${({ theme, variant }) => (variant === 'secondary' ? '0' : `${theme.getValue(295)}px`)};
  position: relative;

  label {
    &.is-moved {
      position: relative;
      top: 24px;
    }
  }
`;

export const Icon = styled.img`
  width: ${({ theme }) => `${theme.getValue(10)}px`};
  height: ${({ theme }) => `${theme.getValue(6)}px`};
  position: absolute;
  right: ${({ theme }) => `${theme.getValue(10)}px`};
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
`;

export const Select = styled.select`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  width: 100%;
  height: ${({ theme }) => `${theme.getValue(36)}px`};
  padding: ${({ theme }) => `${theme.getValue(8)}px`} ${({ theme }) => `${theme.getValue(12)}px`};
  padding-right: ${({ theme }) => `${theme.getValue(24)}px`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  border: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
  border-radius: 4px;
  appearance: none;

  &:focus,
  &:focus-visible {
    outline: none;
  }
  &:disabled {
    border-left: 0;
    border-top: 0;
    border-right: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-left: 0;
    font-weight: ${({ theme }) => `${theme.weights.extrabold}`};
    line-height: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(24)}px`};
    padding-top: 0;
    padding-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
    &::placeholder {
      color: transparent;
    }
    &:hover {
      cursor: not-allowed;
    }
  }
`;
export const SelectLabel = Label;

export const Error = styled.p`
  color: ${({ theme }) => `${theme.colors.orange1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
`;

export default { Container, SelectLabel, Error, Select, Icon };
