import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import useUserInfo from '../../../hooks/useUserInfo';
import Button from '../../Button';
import IVPPopup from '../../IVPPopup';
import Input from '../../Input';
import Dropdown from '../../Dropdown';
import { SendVendorFormModalProps, sendVendorFormSchema, vendorFormFieldsType } from './types';
import Components from './styles';
import { SubmissionInviteType } from '../../../types';
import { RootState } from '../../../store/store';

export default function SendVendorFormModal(props: SendVendorFormModalProps) {
  const { user } = useUserInfo();
  const { setSendNewFormDialogState, handleSendClick, formType, setFormType, serverError, submissionInfo } = props;
  const storedBusinessUnits = useSelector((state: RootState) => state.businessUnits.businessUnits);
  const initialValues: vendorFormFieldsType = {
    email: submissionInfo?.email || '',
    contactName: submissionInfo?.contactName || '',
    businessUnit: submissionInfo?.businessUnit || '',
  };

  const FORMTYPES_ACTIONS_MAP: Record<string | number | symbol, SubmissionInviteType['action']> = {
    new: 'invite',
    resend: 'resend',
    resubmit: 'resubmit',
  };

  const { values, errors, touched, handleBlur, handleChange, isValid, handleSubmit } = useFormik({
    initialValues,
    validationSchema: sendVendorFormSchema,
    onSubmit: () => {
      const resolveAction = (type: keyof typeof FORMTYPES_ACTIONS_MAP): SubmissionInviteType['action'] => {
        return FORMTYPES_ACTIONS_MAP[type];
      };
      handleSendClick({
        ...submissionInfo,
        email: values.email,
        contactName: values?.contactName || submissionInfo?.contactName || '',
        businessUnit: values?.businessUnit || submissionInfo?.businessUnit || storedBusinessUnits[1],
        senderEmail: user?.email || '',
        action: resolveAction(formType || 'new'),
      });
    },
    validateOnMount: true,
    enableReinitialize: true,
  });

  const renderTitle = (type: SendVendorFormModalProps['formType']) => {
    switch (type) {
      case 'new':
        return 'Send New Vendor Form';
      case 'resend':
        return 'Resend Vendor Form';
      case 'resubmit':
        return 'Resubmit Vendor Form';
      default:
        return 'Send New Vendor Form';
    }
  };

  const renderContent = (type: SendVendorFormModalProps['formType']) => {
    switch (type) {
      case 'new':
        return (
          <p>
            Type the new vendor’s information below. Only email is required – the email you provide will be where the
            New Vendor Form is sent.
          </p>
        );
      case 'resend':
        return (
          <p>
            Please check the vendor information below and update as required. Only email is required – the email you
            provide will be where the New Vendor Form is sent. If the vendor is having trouble finding the email please
            ensure they check their spam folder.
          </p>
        );
      case 'resubmit':
        return (
          <p>
            You are sending the vendor their former New Vendor Form to review, update and resubmit. Any update will
            override the vendor’s current, completed New Vendor Form. If you are certain you would like to proceed,
            please check the vendor email below and click “Send”.
          </p>
        );
      default:
        return (
          <p>
            Type the new vendor’s information below. Only email is required – the email you provide will be where the
            New Vendor Form is sent.
          </p>
        );
    }
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IVPPopup centered title={renderTitle(formType)} {...props}>
      <Components.Container>
        {renderContent(formType)}
        <Components.FormContainer>
          {formType !== 'resubmit' ? (
            <Input
              id="contactName"
              name="contactName"
              value={values.contactName}
              touched={touched.contactName}
              error={errors.contactName}
              onChange={handleChange}
              onBlur={handleBlur}
              label="Vendor Business Name or Contact Name"
            />
          ) : null}
          <Input
            id="email"
            name="email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Vendor's Email*"
          />
          <Dropdown
            id="businessUnit"
            name="businessUnit"
            label="Business Unit*"
            value={values.businessUnit}
            error={errors.businessUnit}
            touched={touched.businessUnit}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            {storedBusinessUnits.map(bUnit => (
              <option key={bUnit}>{bUnit}</option>
            ))}
          </Dropdown>
        </Components.FormContainer>
        <Components.ButtonGroup>
          <Button
            variant="secondary"
            onClick={() => {
              setFormType('new');
              setSendNewFormDialogState(false);
            }}
          >
            Cancel
          </Button>
          <Button
            disabled={!isValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Send
          </Button>
        </Components.ButtonGroup>
        {serverError ? <Components.StyledError>{serverError}</Components.StyledError> : null}
      </Components.Container>
    </IVPPopup>
  );
}
