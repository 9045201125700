import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import thunk from 'redux-thunk';
import userReducer from './slices/userSlice';
import stepReducer from './slices/stepSlice';
import gbiReducer from './slices/generalBusinessInfoSlice';
import vendorActivityReducer from './slices/vendorActivitySlice';
import businessEntityReducer from './slices/businessEntitySlice';
import conflictOfInterestReducer from './slices/conflictOfInterestSlice';
import businessClassificationReducer from './slices/businessClassificationSlice';
import irsFormReducer from './slices/irsFormSlice';
import sustainabilityPolicyReducer from './slices/sustainabilityPolicySlice';
import responsabilityCertificationReducer from './slices/responsabilityCertificationSlice';
import sessionStateReducer from './slices/sessionStateSlice';
import businessUnitsReducer from './slices/businessUnitsSlice';

const persistConfig = {
  key: 'root',
  storage,
  stateReconcilier: hardSet,
};

const userReducerPersisted = persistReducer(persistConfig, userReducer);

export const store = configureStore({
  reducer: {
    userInfo: userReducerPersisted,
    step: stepReducer,
    gBI: gbiReducer,
    vendorActivity: vendorActivityReducer,
    businessEntity: businessEntityReducer,
    conflictOfInterest: conflictOfInterestReducer,
    businessClassification: businessClassificationReducer,
    irsForm: irsFormReducer,
    sustainabilityPolicy: sustainabilityPolicyReducer,
    responsabilityCertification: responsabilityCertificationReducer,
    sessionState: sessionStateReducer,
    businessUnits: businessUnitsReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [thunk],
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const persistor = persistStore(store);
