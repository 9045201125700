import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import Button from '../../Button';
import Input from '../../Input';
import { Label as PillLabel, Container as PillContainer } from '../../Pill/styles';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => `${theme.getValue(50)}px ${theme.getValue(70)}px 0`};
  .sticky-wrapper {
    height: calc(100vh - ${({ theme }) => `${theme.getValue(160 + 113 + 50 + 29 + 76)}px`}) !important;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    margin: ${({ theme }) => `${theme.getValue(28)}px ${theme.getValue(40)}px 0`};
  }
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &.homepage-header {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      flex-direction: column;
      justify-content: start;
      row-gap: ${({ theme }) => `${theme.getValue(12)}px`};
    }
  }
`;

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: ${({ theme }) => `${theme.getValue(29)}px 0 0`};
`;

const HeaderLeftSide = styled.div`
  display: flex;
  flex-direction: column;
`;

const HeaderRightSide = styled.div`
  display: flex;
  flex-direction: column;

  align-content: flex-end;
  align-items: flex-end;
  flex-wrap: wrap;

  input {
    width: ${({ theme }) => `${theme.getValue(309)}px`};
  }
  button {
    width: fit-content;
    margin-bottom: ${({ theme }) => `${theme.getValue(28)}px`};
  }
`;

const BackContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => `${theme.getValue(10)}px`};
`;

const Title = styled.h2`
  color: ${({ theme }) => `${theme.colors.black}`};
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(24)}px`};
  line-height: ${({ theme }) => `${theme.getValue(29)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  margin: ${({ theme }) => `${theme.getValue(20)}px 0 0`};
`;

const Subtitle = styled.h3`
  font-size: ${({ theme }) => `${theme.getValue(18)}px`};
  line-height: ${({ theme }) => `${theme.getValue(22)}px`};

  text-transform: capitalize;

  color: ${({ theme }) => `${theme.colors.black}`};
  margin: 0;
`;

const Description = styled.p`
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(21)}px`};
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  margin: ${({ theme }) => `${theme.getValue(8)}px 0 ${theme.getValue(36)}px `};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    margin: 0;
  }
`;

const BackLabel = styled.a`
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(18)}px`};
  line-height: ${({ theme }) => `${theme.getValue(27)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  margin-top: ${({ theme }) => `${theme.getValue(2)}px`};
`;

const BackIcon = styled.img`
  width: ${({ theme }) => `${theme.getValue(16.5)}px`};
`;

const StyledInput = styled.input``;

const StyledButton = styled(Button)``;

const StyledTable = styled.table`
  width: 100%;
  table-layout: fixed;
  text-align: left;
  border-collapse: collapse;
  &.submissions-table {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.tabletlarge}px`}) {
      width: auto;
    }
  }
`;

const TableHeader = styled.thead`
  height: ${({ theme }) => `${theme.getValue(53)}px`};
  position: sticky;
  z-index: 10;
`;

const HeaderRow = styled.tr`
  background: #efefef;
`;

const HeaderColumn = styled.th<{ width?: string }>`
  width: ${({ width }) => width};
  cursor: pointer;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(21)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  padding: ${({ theme }) => `${theme.getValue(16)}px`} ${({ theme }) => `${theme.getValue(21)}px`};
  white-space: nowrap;
  .submissions-table & {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      text-align: justify;
    }
    &:last-child {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    white-space: normal;
    font-size: ${({ theme }) => `${theme.getValue(10)}px`};
    padding: ${({ theme }) => `${theme.getValue(12)}px`} ${({ theme }) => `${theme.getValue(16)}px`};
    line-height: 150%;
  }
`;

const TableBody = styled.tbody`
  background: #f9f9f9;
`;

const BodyRow = styled.tr`
  height: ${({ theme }) => `${theme.getValue(61)}px`};
  border-bottom: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
`;

const BodyColumn = styled.td`
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(21)}px`};
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  padding: ${({ theme }) => `${theme.getValue(16)}px`} ${({ theme }) => `${theme.getValue(21)}px`};
  &:not(&:last-child) {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    font-size: ${({ theme }) => `${theme.getValue(11)}px`};
    line-height: 150%;
    padding: ${({ theme }) => `${theme.getValue(12)}px`} ${({ theme }) => `${theme.getValue(16)}px`};
  }
  &:last-child {
    .users-table & {
      text-align: right;
    }
    .submissions-table & {
      padding-left: 0;
      padding-right: 0;
    }
  }
  ${PillLabel} {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      font-size: ${({ theme }) => `${theme.getValue(9)}px`};
    }
  }
  ${PillContainer} {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      padding: ${({ theme }) => `${theme.getValue(4)}px`} ${({ theme }) => `${theme.getValue(10)}px`};
    }
  }
`;

const TableLink = styled(NavLink)`
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 150%;
  letter-spacing: -0.015em;
  color: ${({ theme }) => `${theme.colors.primaryBlue}`};
`;

const SearchInput = styled(Input)`
  margin: 0;
`;

const SortArrow = styled.img<{ direction: 'desc' | 'asc' }>`
  object-fit: contain;
  width: ${({ theme }) => `${theme.getValue(24)}px`};
  height: ${({ theme }) => `${theme.getValue(6)}px`};
  position: relative;
  transform: rotate(${({ direction }) => (direction === 'desc' ? 0 : 180)}deg);
`;

const SendFormWrapperSm = styled.div`
  width: 40%;
`;

export default {
  Container,
  Header,
  HeaderLeftSide,
  HeaderRightSide,
  BackContainer,
  BackLabel,
  BackIcon,
  Title,
  Subtitle,
  Description,
  TableContainer,
  StyledButton,
  StyledInput,
  StyledTable,
  TableHeader,
  HeaderRow,
  HeaderColumn,
  TableBody,
  BodyRow,
  BodyColumn,
  TableLink,
  SortArrow,
  SearchInput,
  SendFormWrapperSm,
};
