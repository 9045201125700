export default [
  {
    label: 'I certify the above.',
    description: '',
    uploadLabel: '',
    requireFiles: false,
  },
  {
    label: 'I do <strong>not</strong> certify the above.',
    description:
      'Where the vendor is unable to certify to any of the statements in this certification, he or she shall attach an explanation to this information form – please do so below.*',
    uploadLabel: 'Upload Certification Documents',
    requireFiles: true,
  },
];
