/**
 * Capitalizes the first letter of each word in a given string.
 * @param {string} str - The input string.
 * @returns {string} - The capitalized string.
 */
export const capitalizeString = (str: string) => {
  if (!str) return '';
  // Split the string into an array of words
  const words = str.split(' ');

  // Capitalize the first letter of each word and join them back into a string
  return words.map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
};
export default { capitalizeString };
