import styled from 'styled-components/macro';
import ReviewGridStep from './ReviewGridStep';
import { ButtonSection } from '../Step2/styles';

const PillContainer = styled.div`
  width: 100%;
  margin-bottom: ${({ theme }) => `-${theme.getValue(35)}px`};
`;

const GridContainer = styled.div`
  width: ${({ theme }) => `${theme.getValue(1165)}px`};
  margin: 0 auto;
  display: flex;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  row-gap: ${({ theme }) => `${theme.getValue(35)}px`};
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(860)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(140)}px`});
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(40)}px`});
  }
  @media print {
    width: auto;
    display: block;
  }
`;

const StyledStep = styled(ReviewGridStep)`
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(35)}px`};
  }
`;

const Col1 = styled.div`
  width: ${({ theme }) => `${theme.getValue(720)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(529)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: ${({ theme }) => `${theme.getValue(364)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: 100%;
  }
  @media print {
    width: auto;
  }
`;

const Col2 = styled.div`
  width: ${({ theme }) => `${theme.getValue(425)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(310)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: ${({ theme }) => `${theme.getValue(242)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: 100%;
  }
  @media print {
    width: auto;
    margin-top: ${({ theme }) => `${theme.getValue(30)}px`};
  }
`;

const Step1 = styled(StyledStep)``;
const Step2 = styled(StyledStep)``;
const Step3 = styled(StyledStep)``;
const Step4 = styled(StyledStep)``;
const Step5 = styled(StyledStep)``;
const Step6 = styled(StyledStep)``;
const Step7 = styled(StyledStep)``;
const Step8 = styled(StyledStep)``;
const Step9 = styled(StyledStep)``;

export const StyledButtonSection = styled(ButtonSection)`
  width: 100%;
  margin-top: 0;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    flex-direction: column;
    row-gap: ${({ theme }) => `${theme.getValue(10)}px`};
  }
`;

export default {
  PillContainer,
  GridContainer,
  Col1,
  Col2,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  StyledButtonSection,
  ButtonsWrapper,
};
