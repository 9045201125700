import { useSelector } from 'react-redux';
import { StyledHeadline as Headline } from './styles';
import Step2Form from './Step2Form';
import { RootState } from '../../store/store';

export default function Step2() {
  const storeValues = useSelector((state: RootState) => state.gBI);
  return (
    <>
      <Headline>General Business Information</Headline>
      <p>
        Please provide the following general information about your business. Fields marked with (*) are required.{' '}
        <strong>All information must match your W-9.</strong>
      </p>
      <Step2Form fieldValues={storeValues} />
    </>
  );
}
