import { useState } from 'react';
import { useDispatch } from 'react-redux';
import Accordion from '../../components/Accordion';
import Components from './styles';
import resources from '../vendor-resources';
import { increment } from '../../store/slices/stepSlice';
import useScrollToTop from '../stepUtils';

export default function Step1() {
  useScrollToTop();
  const dispatch = useDispatch();
  const [accordionsState, setAccordionsState] = useState(resources.map(() => false));

  return (
    <>
      <Components.Title>Review Vendor Information Resources</Components.Title>
      <Components.Subtext>
        You must expand and review all resources below before you can begin to fill out the form.
      </Components.Subtext>
      {resources.map((resource, index) => (
        <Accordion
          onClick={() => {
            setAccordionsState(
              accordionsState.map((acc, idx) => {
                if (index === idx) return true;
                return acc;
              }),
            );
          }}
          key={resource?.title.replace(' ', '-').toLowerCase()}
          title={resource.title}
        >
          {resource.content}
        </Accordion>
      ))}

      <Components.Info>
        Once you begin the form, you can always return to see this information by clicking the &quot;Vendor Form
        Resources&quot; dropdown at the top right of your screen.
      </Components.Info>
      <Components.Step1Btn
        disabled={accordionsState.some(acc => acc === false)}
        onClick={() => {
          dispatch(increment());
        }}
      >
        Begin
      </Components.Step1Btn>
    </>
  );
}
