/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface generalBusinessInfoState {
  nameOfBusiness: string;
  dba: string | undefined;
  street1: string;
  street2: string | undefined;
  city: string;
  state: string;
  zip: string;
  mailingStreet1: string;
  mailingStreet2: string | undefined;
  mailingCity: string;
  mailingState: string;
  mailingZip: string;
  phone: string;
  faxNumber: string | undefined;
  tollFreeNumber: string | undefined;
  firstName: string;
  lastName: string;
  title: string;
  email: string;
  webAddress: string | undefined;
  stp: string;
  stpOther: string | undefined;
}

const initialState: generalBusinessInfoState = {
  nameOfBusiness: '',
  dba: '',
  street1: '',
  street2: '',
  city: '',
  state: '',
  zip: '',
  mailingStreet1: '',
  mailingStreet2: '',
  mailingCity: '',
  mailingState: '',
  mailingZip: '',
  phone: '',
  faxNumber: '',
  tollFreeNumber: '',
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  webAddress: '',
  stp: '',
};

export const generalBusinessInfoSlice = createSlice({
  name: 'gbiSlice',
  initialState,
  reducers: {
    setGBI: (state, action: PayloadAction<generalBusinessInfoState>) => {
      const newState = { ...action.payload };
      return newState;
    },
  },
});

export const { setGBI } = generalBusinessInfoSlice.actions;
export default generalBusinessInfoSlice.reducer;
