import styled from 'styled-components/macro';
import Button from '../../Button';

const Container = styled.div`
  p {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-size: ${({ theme }) => `${theme.getValue(16)}px`};
    line-height: 150%;
  }
`;

const FormContainer = styled.div`
  width: ${({ theme }) => `${theme.getValue(290)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(24)}px`};
`;

const ButtonGroup = styled.div`
  display: flex;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  justify-content: flex-end;
  margin-bottom: 0;
  margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
  ${Button} {
    padding-left: ${({ theme }) => `${theme.getValue(24)}px`};
    padding-right: ${({ theme }) => `${theme.getValue(24)}px`};
    min-width: ${({ theme }) => `${theme.getValue(90)}px`};
  }
`;
const Confirmation = styled.div`
  min-height: ${({ theme }) => `${theme.getValue(220)}px`};
  display: flex;
  flex-direction: column;
  width: 85%;
  margin: 0 auto;
`;

export default { Container, ButtonGroup, FormContainer, Confirmation };
