import { useNavigate } from 'react-router-dom';
import Components from './styles';
import LeftArrow from './assets/LeftArrow.svg';
import { BackArrowPropsType } from './types';

export default function BackArrow(props: BackArrowPropsType) {
  const { onClick } = props;
  const navigate = useNavigate();
  return (
    <Components.BackContainer>
      <button
        type="button"
        onClick={() => {
          if (!onClick) {
            navigate(-1);
          } else {
            onClick?.();
          }
        }}
      >
        <Components.BackIcon src={LeftArrow} />
        <Components.BackLabel>Back</Components.BackLabel>
      </button>
    </Components.BackContainer>
  );
}
