import * as yup from 'yup';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';
import { CustomPopUpPropsType } from '../../IVPPopup/types';

export const forgotPasswordModalSchema = yup.object({
  email: yup.string().email(EMAIL_VALIDATION_MSG).required(REQUIRED_VALIDATION_MSG),
});

type ForgotPasswordModalFormType = yup.InferType<typeof forgotPasswordModalSchema>;

export type ForgotPasswordModalProps = Omit<CustomPopUpPropsType, 'children'>;

export default ForgotPasswordModalFormType;
