import React from 'react';
import Step from '../Step';
import stepList from '../../content/stepList';
import StepType from '../Step/types';
import Pill from '../Pill';
import { StepListPropsType } from './types';
import Components from './styles';

function StepList(props: StepListPropsType) {
  const { currentStep, businessUnit } = props;
  const renderSteps = () =>
    stepList.map((step, index) => {
      let status: StepType['status'] = 'pending';
      if (index === currentStep) {
        status = 'current';
      } else if (index < currentStep) {
        status = 'completed';
      }
      return (
        <Step
          key={step.title.replace(' ', '-').toLowerCase()}
          title={step.title}
          stepNumber={index + 1}
          status={status}
        />
      );
    });
  return (
    <>
      <Components.PillContainer>
        <Pill>{businessUnit}</Pill>
      </Components.PillContainer>
      <Components.StepsContainer>{renderSteps()}</Components.StepsContainer>
      <Components.MobileStepsContainer>
        <Components.StepsProgress currentProgress={`${(currentStep + 1) * 10}%`} />
        <Components.StepsCounter>Step {currentStep + 1}/10</Components.StepsCounter>
      </Components.MobileStepsContainer>
    </>
  );
}

export default StepList;
