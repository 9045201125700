import styled, { css } from 'styled-components/macro';

const InputLabel = styled.label`
  margin: 0;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: ${({ theme }) => `${theme.getValue(16)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  &:hover {
    cursor: pointer;
  }
  display: inline-flex;
  gap: ${({ theme }) => `${theme.getValue(16)}px`};
`;

const InputWrapper = styled.div`
  position: relative;
`;

const CheckboxInput = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const CheckboxControl = styled.div<{ isChecked?: boolean; isRadio?: boolean }>`
  position: relative;
  width: ${({ theme }) => `${theme.getValue(16)}px`};
  height: ${({ theme }) => `${theme.getValue(16)}px`};
  border: solid 1px ${({ theme }) => `${theme.colors.gray6}`};
  border-radius: 2px;
  ${({ isRadio }) =>
    isRadio &&
    css`
      border-radius: 0;
      border: 0;
      box-sizing: border-box;
      &:before {
        position: absolute;
        content: '';
        border-radius: 50%;
        top: 50%;
        left: 50%;
        width: ${({ theme }) => `${theme.getValue(10)}px`};
        height: ${({ theme }) => `${theme.getValue(10)}px`};
        transform: translate(-50%, -50%);
        box-sizing: border-box;
        transition: background-color 0.2s linear;
      }
      &:after {
        position: absolute;
        content: '';
        border-radius: 50%;
        border: solid 1px ${({ theme }) => `${theme.colors.gray6}`};
        top: 0.1px;
        left: 0.3px;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
      }
    `}
  ${({ isRadio, isChecked }) =>
    isRadio &&
    isChecked &&
    css`
      &:before {
        background-color: ${({ theme }) => `${theme.colors.primaryBlue}`};
      }
      &:after {
        border-color: ${({ theme }) => `${theme.colors.primaryBlue}`};
      }
    `}
  ${({ isRadio, isChecked }) =>
    !isRadio &&
    isChecked &&
    css`
      border-color: ${({ theme }) => theme.colors.primaryBlue};
      background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.14406 5.1238C2.04701 5.02833 1.89142 5.02805 1.79404 5.12318L0.825305 6.06949C0.777259 6.11642 0.750119 6.18071 0.75 6.24788C0.749882 6.31505 0.776794 6.37943 0.824674 6.42654L4.6382 10.1782C4.73549 10.2739 4.89157 10.2739 4.98886 10.1782L13.1753 2.12452C13.2231 2.07753 13.25 2.01332 13.25 1.94631C13.25 1.8793 13.2231 1.81509 13.1753 1.76809L12.2134 0.821784C12.1162 0.726104 11.9602 0.726067 11.8628 0.821701L4.81361 7.75005L2.14406 5.1238Z' fill='%230090BA' stroke='%230090BA' stroke-width='0.5' stroke-linejoin='round'/%3E%3C/svg%3E");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: ${({ theme }) => `${theme.getValue(12)}px`} ${({ theme }) => `${theme.getValue(9)}px`};
    `}
`;

export default {
  InputWrapper,
  InputLabel,
  CheckboxInput,
  CheckboxControl,
};
