import styled from 'styled-components/macro';

const ActivityLabel = styled.h2`
  margin: 0;
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
`;

const PrintActivityLabel = styled.h2`
  margin: 0;
  font-size: 10px !important;
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
`;

export default { ActivityLabel, PrintActivityLabel };
