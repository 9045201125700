import qs from 'qs';
import { useEffect, useState } from 'react';
import { SubmissionType } from '../types';

const useSubmissionInfoWithUuid = (uuid: string, authToken: string) => {
  const [submissionInfo, setSubmissionInfo] = useState<null | SubmissionType>(null);
  const [isSubmissionFound, setIsSubmissionFound] = useState<null | boolean>(null);
  const [fetchError, setFetchError] = useState<null | string>(null);

  const filterQs = qs.stringify({
    filters: {
      uuid: {
        $eq: uuid,
      },
    },
    populate: '*',
  });

  const SUBMISSIONS_URL = `${process.env.REACT_APP_API_URL}/submissions/?${filterQs}`;

  useEffect(() => {
    const getSubmission = async () => {
      try {
        if (!uuid) {
          return;
        }
        const response = await fetch(SUBMISSIONS_URL, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        });

        const responseData = await response.json();
        const { data, error } = responseData;

        if (error) {
          setIsSubmissionFound(false);
          setFetchError(error);
          throw error;
        }

        if (!data.length) {
          setIsSubmissionFound(false);
          const errorMsg = 'Submission not found';
          setFetchError(errorMsg);
          throw errorMsg;
        }

        const { id, attributes } = data[0];
        setSubmissionInfo({ id, ...attributes });
        setIsSubmissionFound(true);

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        console.error(err);
        setFetchError(err || 'Something went wrong. Please try again later');
        setIsSubmissionFound(false);
      }
    };
    getSubmission();
  }, [SUBMISSIONS_URL, authToken, uuid]);

  return { data: submissionInfo, error: fetchError, submissionFound: isSubmissionFound };
};

export default useSubmissionInfoWithUuid;
