import { formatAccountNumber } from '../../../../utils/formating';
import Components from './styles';
import { ReviewGridInput as Input } from '../../ReviewGridStep/styles';
import { ReviewStep4PropsType } from './types';

export default function ReviewStep4(props: ReviewStep4PropsType) {
  const { fieldValues: businessEntityData } = props;
  const { accountNumber, businessEntity, firstName, lastName, resident, taxClassification } = businessEntityData;

  return (
    <>
      <Components.Title>{businessEntity}</Components.Title>
      {accountNumber ? (
        <Input
          disabled
          label="Federal Employee Identification Number"
          value={formatAccountNumber(accountNumber, businessEntity)}
        />
      ) : null}
      {firstName ? <Input disabled label="First Name" value={firstName} /> : null}
      {lastName ? <Input disabled label="Last Name" value={lastName} /> : null}
      {taxClassification ? <Input disabled label="Tax Classification" value={taxClassification} /> : null}
      {resident ? <Input disabled label="Resident" value={resident} /> : null}
    </>
  );
}
