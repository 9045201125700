/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface responsabilityCertificationState {
  vendorCertify: string;
  vendorDescription: string | undefined;
  requireFiles: boolean | undefined;
  vendorCertifyFiles: any[] | undefined;
}

const initialState: responsabilityCertificationState = {
  vendorCertify: '',
  vendorDescription: '',
  requireFiles: false,
  vendorCertifyFiles: [],
};

export const responsabilityCertificationSlice = createSlice({
  name: 'responsabilityCertificationState',
  initialState,
  reducers: {
    setResponsabilityCertification: (state, action: PayloadAction<responsabilityCertificationState>) => {
      return { ...action.payload };
    },
  },
});

export const { setResponsabilityCertification } = responsabilityCertificationSlice.actions;
export default responsabilityCertificationSlice.reducer;
