import { ReviewGridStepType } from './types';
import Components from './styles';

export default function ReviewGridStep(props: ReviewGridStepType) {
  const { title, children, className, onButtonClick, showBorder } = props;
  return (
    <div className={className}>
      <Components.Title>
        <span>{title}</span>
        <button type="button" onClick={onButtonClick}>
          <span>Edit</span>
        </button>
      </Components.Title>
      <Components.Inner showBorder={showBorder}>{children}</Components.Inner>
    </div>
  );
}

ReviewGridStep.defaultProps = {
  title: 'Title',
  children: null,
  className: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onButtonClick: () => {},
  showBorder: false,
};
