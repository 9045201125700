import styled from 'styled-components/macro';
import Components from '../DeleteUserModal/styles';

const Container = styled(Components.Container)`
  text-align: left;
  p {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    text-align: left;
    margin-left: 0;
  }
`;

const FormWrapper = styled.div`
  width: ${({ theme }) => `${theme.getValue(310)}px`};
`;

const ButtonGroup = styled(Components.ButtonGroup)`
  margin-top: ${({ theme }) => `${theme.getValue(32)}px`};
  justify-content: flex-end;
  button {
    &:last-of-type {
      margin-left: ${({ theme }) => `${theme.getValue(20)}px`};
    }
  }
`;

const ButtonGroupWrapper = styled.div``;

export default { ...Components, Container, FormWrapper, ButtonGroup, ButtonGroupWrapper };
