import Popup from 'reactjs-popup';
import styled from 'styled-components/macro';
import Headline from '../typography/Headline';
import Button from '../Button';

const StyledPopup = styled(Popup)`
  // use your custom style for ".popup-content"
  &-content {
    padding: ${({ theme }) => `${theme.getValue(30)}px`};
    max-width: ${({ theme }) => `${theme.getValue(640)}px`};
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      width: 74%;
    }
    ${Button} {
      padding-left: ${({ theme }) => `${theme.getValue(24)}px`};
      padding-right: ${({ theme }) => `${theme.getValue(24)}px`};
      min-width: ${({ theme }) => `${theme.getValue(90)}px`};
    }
  }
`;
const Container = styled.div<{ centered?: boolean }>`
  text-align: ${({ centered }) => (centered ? 'center' : 'left')};
`;

const StyledHeadline = styled(Headline)`
  margin: 0;
`;

export default { StyledPopup, Container, StyledHeadline };
