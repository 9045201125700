import * as yup from 'yup';
import 'yup-phone-lite';
import { PHONE_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../constants';

export const Step6Schema = yup.object({
  withholding: yup.string().required(REQUIRED_VALIDATION_MSG),
  signature: yup.string().default(''),
  date: yup.string().required(REQUIRED_VALIDATION_MSG),
  title: yup.string().required(REQUIRED_VALIDATION_MSG),
  phoneNumber: yup.string().required(REQUIRED_VALIDATION_MSG).phone('US', PHONE_VALIDATION_MSG),
});

export type IrsFormType = yup.InferType<typeof Step6Schema>;

export default IrsFormType;
