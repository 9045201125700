import styled from 'styled-components/macro';
import ReviewGridStep from '../ReviewGrid/ReviewGridStep';
import { Inner } from '../ReviewGrid/ReviewGridStep/styles';

const GridContainer = styled.div`
  margin: 0 auto;
  margin-left: 14px;
  margin-right: 14px;

  h2 > button {
    display: none;
  }
  h2 {
    font-size: 10px;
  }
`;

const StyledStep = styled(ReviewGridStep)`
  & + & {
    margin-top: 32px;
  }
`;

const Col1 = styled.div``;

const Col2 = styled.div``;

const Step1 = styled(StyledStep)``;
const Step2 = styled(StyledStep)``;
const Step3 = styled(StyledStep)``;
const Step4 = styled(StyledStep)``;
const Step5 = styled(StyledStep)``;
const Step6 = styled(StyledStep)``;
const Step7 = styled(StyledStep)``;
const Step8 = styled(StyledStep)``;
const Step9 = styled(StyledStep)``;

export const ButtonSection = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 16px;
  margin-bottom: 48px;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  column-gap: 20px;
`;

export const PrintLabel = styled.div`
  margin-top: 8px;
  font-size: 8px;
  font-weight: 500;
  p,
  a,
  strong {
    font-size: 8px;
    line-height: 150%;
  }
`;

export const PrintValue = styled.div`
  margin-top: 4px;
  font-size: 7px;
  font-weight: 800;
  padding-bottom: 4px;
  border-bottom: 1px solid #d1d5db;
`;

export const PrintTitle = styled.h2<{ inner?: boolean }>`
  margin: ${({ inner }) => (inner ? 0 : `0 0 8px 0`)};
  font-size: 9px !important;
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
`;

export const PrintTitle2 = styled.h2<{ inner?: boolean }>`
  margin: ${({ inner }) => (inner ? 0 : `0 0 8px 0`)};
  font-size: 14px !important;
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
`;

export const SemiboldPrintLabel = styled.div`
  margin-top: 8px;
  font-size: 9px;
  font-weight: 600;
  & + ${Inner} {
    margin-top: 6px;
  }
  ${Inner} + & {
    margin-top: 10px;
  }
`;

export const PrintSectionDescription = styled.p`
  font-size: 12px;
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  line-height: 150%;
  color: ${({ theme }) => `${theme.colors.gray2}`};
`;

export const ListItem = styled.li`
  font-size: 8px;
  line-height: 150%;
`;

export default {
  GridContainer,
  Col1,
  Col2,
  Step1,
  Step2,
  Step3,
  Step4,
  Step5,
  Step6,
  Step7,
  Step8,
  Step9,
  ButtonSection,
  ButtonsWrapper,
  PrintSectionDescription,
};
