import { useFormik } from 'formik';
import { useState } from 'react';
import Button from '../../Button';
import Input from '../../Input';
import IVPPopup from '../../IVPPopup';
import Components from './styles';
import ForgotPasswordModalFormType, { ForgotPasswordModalProps, forgotPasswordModalSchema } from './types';

const initialValues: ForgotPasswordModalFormType = {
  email: '',
};

export default function ForgotPasswordModal(props: ForgotPasswordModalProps) {
  const [showSuccessMsg, setShowSuccessMsg] = useState(false);
  const { setModalOpenState } = props;
  const { values, errors, touched, isValid, setFieldValue, handleBlur, handleSubmit, setFieldError, resetForm } =
    useFormik({
      validationSchema: forgotPasswordModalSchema,
      validateOnMount: true,
      initialValues,
      enableReinitialize: true,
      onSubmit: async (vals: ForgotPasswordModalFormType) => {
        try {
          const { email } = vals;
          // check if there's a user with the email
          const fetchUserResponse = await fetch(`${process.env.REACT_APP_API_URL}/users?filters[email][$eq]=${email}`, {
            headers: {
              Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
            },
          });
          const fetchedUserData = await fetchUserResponse.json();
          if (fetchedUserData.error) throw fetchedUserData.error;
          if (fetchedUserData.length) {
            // request password change email
            const requestPassResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth/forgot-password`, {
              method: 'POST',
              body: JSON.stringify({ email }),
              headers: {
                Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
                'Content-type': 'application/json',
              },
            });
            const requestPassData = await requestPassResponse.json();
            const { error } = requestPassData;
            if (error) throw error;

            setShowSuccessMsg(true);
          } else {
            // eslint-disable-next-line no-throw-literal
            throw 'This email address is not associated to an account or is invalid. ';
          }

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          console.error(error);
          setFieldError(
            'email',
            error?.message || error || 'Something went wrong. Please try again later or contact support',
          );
          // set error message in email form
        }
      },
    });

  const handleCloseModal = () => {
    resetForm();
    setShowSuccessMsg(false);
    setModalOpenState?.(false);
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IVPPopup centered={showSuccessMsg} title={!showSuccessMsg ? 'Forgot Password?' : 'Sent'} {...props}>
      <Components.Container>
        {!showSuccessMsg ? (
          <>
            <p>Verify the email associated to your account and we will send you a reset password link.</p>
            <Components.FormContainer>
              <Input
                id="email"
                name="email"
                value={values.email.toLowerCase()}
                error={errors.email}
                touched={touched.email}
                onChange={e => setFieldValue('email', e.target.value.toLowerCase())}
                onBlur={handleBlur}
                label="Advantage Email*"
                type="email"
              />
            </Components.FormContainer>
            <Components.ButtonGroup>
              <>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseModal();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Send
                </Button>
              </>
            </Components.ButtonGroup>
          </>
        ) : (
          <Components.Confirmation>
            <p>
              An email to reset your password has been sent! <br /> Please check your email to continue the process.
            </p>
            <p>If you did not receive the email, please double check the email provided or check your spam folder. </p>
            <Components.ButtonGroup style={{ justifyContent: 'center' }}>
              <Button
                variant="primary"
                onClick={() => {
                  handleCloseModal();
                }}
              >
                OK
              </Button>
            </Components.ButtonGroup>
          </Components.Confirmation>
        )}
      </Components.Container>
    </IVPPopup>
  );
}
