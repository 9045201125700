import styled from 'styled-components/macro';
import { visuallyHidden } from '../../styles/mixins';
import StepType from './types';

const StepWrapper = styled.div<StepType>`
  display: flex;
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
  min-height: ${({ theme }) => `${theme.getValue(80)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    flex-direction: column;
    flex: 1 1 auto;
    align-items: center;
    min-height: 0;
    margin-bottom: ${({ theme }) => `${theme.getValue(36)}px`};
    margin-left: ${({ theme }) => `${theme.getValue(-12)}px`};
  }
`;

const IndicatorWrapper = styled.div`
  position: relative;
  width: ${({ theme }) => `${theme.getValue(28)}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: ${({ theme }) => `${theme.getValue(16)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    margin-right: 0;
    flex-direction: row;
    width: ${({ theme }) => `${theme.getValue(60)}px`};
    transform: translateX(25%);
  }
`;

const Icon = styled.img`
  width: ${({ theme }) => `${theme.getValue(19.5)}px`};
  height: ${({ theme }) => `${theme.getValue(19.5)}px`};
  position: absolute;
`;

const Indicator = styled.div<{ status: StepType['status'] }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => `${theme.getValue(28)}px`};
  height: ${({ theme }) => `${theme.getValue(28)}px`};
  border: solid 1px transparent;
  border-radius: 50%;
  flex: 0 0 auto;
  border-color: ${({ theme, status }) => {
    switch (status) {
      case 'pending':
        return 'transparent';
      case 'current':
        return theme.colors.primaryBlue;
      case 'completed':
        return theme.colors.green1;
      default:
        return 'transparent';
    }
  }};
  &:before {
    content: '';
    width: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(16)}px`};
    border-radius: 50%;
    background: transparent;
    border: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
    border-color: ${({ theme, status }) => {
      switch (status) {
        case 'pending':
          return theme.colors.gray4;
        case 'current':
          return theme.colors.primaryBlue;
        case 'completed':
          return theme.colors.green1;
        default:
          return theme.colors.gray4;
      }
    }};
    background-color: ${({ theme, status }) => {
      switch (status) {
        case 'pending':
          return 'transparent';
        case 'current':
          return theme.colors.primaryBlue;
        case 'completed':
          return 'transparent';
        default:
          return theme.colors.gray4;
      }
    }};
  }
`;

const Separator = styled.div<{ status: StepType['status'] }>`
  margin-top: ${({ theme }) => `${theme.getValue(6)}px`};
  width: 2px;
  background-color: ${({ theme }) => `${theme.colors.gray4}`};
  height: 100%;
  border-radius: 6px;
  background-color: ${({ theme, status }) => {
    switch (status) {
      case 'pending':
        return theme.colors.gray4;
      case 'current':
        return theme.colors.primaryBlue;
      case 'completed':
        return theme.colors.green1;
      default:
        return theme.colors.gray4;
    }
  }};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    text-align: center;
    width: ${({ theme }) => `${theme.getValue(28)}px`};
    height: 2px;
    margin-top: 0;
    margin-left: ${({ theme }) => `${theme.getValue(6)}px`};
    margin-right: ${({ theme }) => `${theme.getValue(6)}px`};
  }
  ${StepWrapper}:last-child & {
    background-color: transparent;
  }
`;

const Content = styled.div`
  width: ${({ theme }) => `${theme.getValue(210)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: 100%;
    margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
    text-align: center;
  }
`;

const Title = styled.h2`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 1.1428571429;
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  margin: 0;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    ${visuallyHidden}
  }
`;

const Subtitle = styled.h3`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  text-transform: uppercase;
  font-weight: normal;
  margin: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(4)}px`};
`;

export default { StepWrapper, IndicatorWrapper, Indicator, Separator, Content, Title, Subtitle, Icon };
