import styled from 'styled-components/macro';

const Trigger = styled.button`
  width: ${({ theme }) => `${theme.getValue(300)}px`};
  height: ${({ theme }) => `${theme.getValue(45)}px`};
  background-color: ${({ theme }) => `${theme.colors.gray3}`};
  border-radius: 6px;
  border: 0;
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 0.94;
  position: relative;
  padding: 0;
  text-align: left;
  padding-left: ${({ theme }) => `${theme.getValue(26)}px`};
  .is-open & {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: ${({ theme }) => `${theme.getValue(18)}px`};
    width: ${({ theme }) => `${theme.getValue(16)}px`};
    height: ${({ theme }) => `${theme.getValue(9)}px`};
    background-image: url("data:image/svg+xml,%3Csvg  viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.59379 0.305786L8.2796 5.26745L13.9654 0.305786L15.712 1.83329L8.2796 8.33329L0.847168 1.83329L2.59379 0.305786Z' fill='%23253746'/%3E%3C/svg%3E");
    .is-open & {
      transform: rotate(180deg) translateY(50%);
    }
  }
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  position: relative;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    display: none;
  }
`;

const Panel = styled.div`
  top: 100%;
  position: absolute;
  width: 100%;
  padding: ${({ theme }) => `${theme.getValue(20)}px`};
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: ${({ theme }) => `${theme.colors.gray10}`};
  z-index: 99;
`;

const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const ListItem = styled.li`
  a {
    display: block;
    text-transform: capitalize;
    text-decoration: none;
    font-family: ${({ theme }) => theme.fonts.primary};
    font-style: normal;
    font-weight: ${({ theme }) => theme.weights.bold};
    font-size: ${({ theme }) => `${theme.getValue(14)}px`};
    color: ${({ theme }) => `${theme.colors.blue2}`};
    line-height: normal;
    line-height: 1.21;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      font-size: ${({ theme }) => `${theme.getValue(16)}px`};
    }
    &:hover {
      text-decoration: underline;
    }
  }
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
  }
`;

const MobileTrigger = styled.div`
  display: none;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    display: block;
  }
  ${Panel} {
    right: 0;
    width: 75vw;
    top: ${({ theme }) => `${theme.getValue(50)}px`};
    height: calc(100vh - ${({ theme }) => `${theme.getValue(50)}px`});
  }
`;

const MobileTriggerBtn = styled.button`
  padding: 0;
  border: 0;
  margin: 0;
  background-color: transparent;
`;

const MobileTriggerLogo = styled.img`
  width: ${({ theme }) => `${theme.getValue(23)}px`};
  height: ${({ theme }) => `${theme.getValue(23)}px`};
`;

const MobilePanelTitle = styled.h2`
  padding: ${({ theme }) => `${theme.getValue(19)}px`} ${({ theme }) => `${theme.getValue(10)}px`};
  margin: 0;
  background-color: ${({ theme }) => theme.colors.gray3};
  font-family: ${({ theme }) => theme.fonts.primary};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: ${({ theme }) => `${theme.getValue(18)}px`};
  line-height: ${({ theme }) => `${theme.getValue(15)}px`};

  text-align: center;

  color: ${({ theme }) => theme.colors.gray12};
  margin-left: ${({ theme }) => `${theme.getValue(-20)}px`};
  margin-right: ${({ theme }) => `${theme.getValue(-20)}px`};
  margin-top: ${({ theme }) => `${theme.getValue(-20)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export default {
  Trigger,
  Wrapper,
  Panel,
  List,
  ListItem,
  MobileTrigger,
  MobileTriggerLogo,
  MobileTriggerBtn,
  MobilePanelTitle,
};
