/* eslint-disable react/jsx-props-no-spreading */
import { Container, Input as StyledInput, Label, Error } from './styles';
import InputType from './types';

export type InputBoxType = InputType & React.InputHTMLAttributes<HTMLInputElement>;

export default function Input(props: InputBoxType) {
  const { label, error, touched, className, variant, disabled, value, ...rest } = props;
  return (
    <Container variant={variant} className={className}>
      {label && <Label className={disabled && !value ? 'is-moved' : ''}>{label}</Label>}
      <StyledInput disabled={disabled} value={value} {...rest} />
      {!disabled && touched && error && <Error>{error}</Error>}
    </Container>
  );
}

Input.defaultProps = {
  variant: 'primary',
};
