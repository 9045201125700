import * as yup from 'yup';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';

export const ProfileFormSchema = yup.object({
  firstName: yup.string().required('Please enter a name'),
  lastName: yup.string().required('Please enter a name'),
  email: yup
    .string()
    .email(EMAIL_VALIDATION_MSG)
    .required(REQUIRED_VALIDATION_MSG)
    .matches(
      /^[A-Za-z0-9.]+@advantagesolutions\.net$/gm,
      'Only users with an @advantagesolutions.net email address can be invited to this platform.',
    ),
});
export type ProfileFormFieldsType = yup.InferType<typeof ProfileFormSchema>;

export type ProfileFormPropsType = ProfileFormFieldsType & {
  handleSaveChanges?: (newInfo: ProfileFormFieldsType) => Promise<unknown>;
  errorMsg?: string;
  clearErrorMsg?: () => void;
};

export default ProfileFormPropsType;
