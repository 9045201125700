// export default {};
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface userState {
  jwt: string;
  user: {
    id: number | null,
    email: string,
    firstName: string,
    lastName: string,
    userName: string,
    role?: {
      id: number | string,
      name: string,
      description: string,
      type: string,
    } | null,
  } | null;
}

const initialState: userState = {
  jwt: '',
  user: null,
};

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<userState>) => {
      return { ...action.payload };
    },
  },
});

export const { setUser } = userSlice.actions;

export default userSlice.reducer;
