import { ResourceUl } from './styles';

export function VendorResource4() {
  return (
    <>
      <p>
        Each corporation, individual/sole proprietor, partnership, estate or trust doing business in the State of
        California must indicate their residency status along with their taxpayer identification number.
      </p>
      <p>
        A <strong>corporation</strong> will be considered a “resident” if it has a permanent place of business in
        California. The corporation has a permanent place of business in California if it is organized and existing
        under the laws of this state or, if a foreign corporation has qualified to transact intrastate business. A
        corporation that has not qualified to transact intrastate business (e.g., a corporation engaged exclusively in
        interstate commerce) will be considered as having a permanent place of business in this state only if it
        maintains a permanent office in this state that is permanently staffed by its employees.
      </p>
      <p>
        For <strong>individuals/sole proprietors</strong>, the term “resident” includes every individual who is in
        California for other than a temporary or transitory purpose and any individual domiciled in California who is
        absent for a temporary or transitory purpose. Generally, an individual who comes to California for a purpose
        that will extend over a long or indefinite period will be considered a resident. However, an individual who
        comes to perform a particular contract of short duration will be considered a nonresident.
      </p>
      <p>
        For withholding purposes, a <strong>partnership</strong> is considered a resident partnership if it has a
        permanent place of business in California. An <strong>estate</strong> is considered a California estate if the
        decedent was a California resident at the time of death and at least one trustee is a California resident.
      </p>
      <p>
        More information on residency status may be obtained by calling the Franchise Tax Board at the numbers listed
        below:
      </p>
      <ResourceUl>
        <li>From within the United States, call: 1-800-852-5711</li>
        <li>From outside the United States, call: 1-800-854-6500</li>
        <li>For hearing impaired with TDD, call: 1-800-822-6568</li>
      </ResourceUl>
    </>
  );
}

export default {
  title: 'determine state of california residency status – Resident or nonresident',
  content: <VendorResource4 />,
};
