import * as yup from 'yup';
import 'yup-phone-lite';
import { PHONE_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../constants';

export const Step7Schema = yup.object({
  conflictOfInterest: yup.string().required(REQUIRED_VALIDATION_MSG),
  employeesFirstName: yup
    .string()
    .when('conflictOfInterest', {
      is: 'Yes',
      then: yup.string().required(REQUIRED_VALIDATION_MSG),
      otherwise: yup.string(),
    })
    .default(''),
  employeesLastName: yup
    .string()
    .when('conflictOfInterest', {
      is: 'Yes',
      then: yup.string().required(REQUIRED_VALIDATION_MSG),
      otherwise: yup.string(),
    })
    .default(''),
  employeesRelationship: yup
    .string()
    .when('conflictOfInterest', {
      is: 'Yes',
      then: yup.string().required(REQUIRED_VALIDATION_MSG),
      otherwise: yup.string(),
    })
    .default(''),
  employeesPhoneNumber: yup
    .string()
    .when('conflictOfInterest', {
      is: 'Yes',
      then: yup.string().required(REQUIRED_VALIDATION_MSG).phone('US', PHONE_VALIDATION_MSG),
      otherwise: yup.string().phone('US', PHONE_VALIDATION_MSG),
    })
    .default(''),
});

export type ConflictOfInterestType = yup.InferType<typeof Step7Schema>;

export default ConflictOfInterestType;
