import qs from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import LandingComponents from '../../../components/admin/Landing/styles';
import SendConfirmationModal from '../../../components/admin/SendConfirmationModal';
import SendVendorFormModal from '../../../components/admin/SendVendorFormModal';
import { sendInviteProps, SendVendorFormModalProps } from '../../../components/admin/SendVendorFormModal/types';
import SubmissionsTable from '../../../components/admin/SubmissionsTable';
import tableSubmissionType, { ResponseSubmissionType } from '../../../components/admin/SubmissionsTable/types';
import Handle401Error from '../../../components/Handle401Error';
import AdminPage from '../../../components/Page/AdminPage';
import ScreenComponents from './styles';
import { HomeScreenPropsType } from './types';
import VendorFilter from '../../../components/admin/VendorFilter';
import { VendorFilterTag } from '../../../components/admin/VendorFilter/types';
import parseFilterQuery from '../../../utils/filtering';
import useDimensions from '../../../hooks/useDimensions';
import { RootState } from '../../../store/store';
import SearchBar from '../../../components/SearchBar/SearchBar';

const Components = { ...LandingComponents, ...ScreenComponents };

const parseInvites = (data: ResponseSubmissionType[], defaultBusinessUnit: string) => {
  return data?.map((submission: ResponseSubmissionType) => {
    const { id, attributes } = submission;
    const { invite, uuid } = attributes;
    const { email, nameOfBusiness, contactName, sendDate, completedDate, sender, completed, businessUnit } = invite;

    const parsedInvite: tableSubmissionType = {
      submissionId: id,
      uuid,
      nameOfBusiness,
      email: email || '',
      completedDate,
      contactName,
      sendDate: sendDate || '',
      sender: sender || '',
      completed: completed || false,
      businessUnit: businessUnit || defaultBusinessUnit,
    };

    return parsedInvite;
  });
};

const upsertVendorFormInvite = async (
  method: 'post' | 'put',
  params: sendInviteProps,
  authToken: string,
  senderName: string,
  defaultBusinessUnit: string,
) => {
  try {
    const apiRootURL = `${process.env.REACT_APP_API_URL}/submissions`;
    const submissionTarget = method === 'put' ? `/${params.submissionId}` : '';
    const upsertInviteResponse = await fetch(`${apiRootURL}${submissionTarget}?populate=invite`, {
      method,
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${authToken}`,
      },
      body: JSON.stringify({
        data: {
          uuid: params?.uuid || uuidv4(),
          invite: {
            email: params?.email || '',
            contactName: params?.contactName || '',
            sender: senderName,
            sendDate: new Date(),
            senderEmail: params?.senderEmail || '',
            action: params?.action,
            businessUnit: params?.businessUnit || defaultBusinessUnit,
          },
        },
      }),
    });
    const newInviteData = await upsertInviteResponse.json();
    const { error, data } = newInviteData;
    if (error) {
      throw error;
    }
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getSearchQuery = (searchQuery: string) => ({
  $or: [
    { invite: { email: { $containsi: searchQuery } } },
    {
      invite: { contactName: { $containsi: searchQuery } },
    },
    {
      invite: { nameOfBusiness: { $containsi: searchQuery } },
    },
  ],
});
function ResultsCounter(props: { total: number }) {
  const { total } = props;
  return total ? <Components.CounterWrapper>{total} items</Components.CounterWrapper> : null;
}

export default function HomeScreen(props: HomeScreenPropsType) {
  const theme = useTheme();
  const screenDimensions = useDimensions();
  const isSmall = screenDimensions.width <= theme.breakpoints.small;
  const storedBusinessUnits = useSelector((state: RootState) => state.businessUnits.businessUnits);
  const { user: userFromProps } = props;
  const { user, jwt } = userFromProps;
  const [currentSubmissionInvites, setCurrentSubmissionInvites] = useState<Array<tableSubmissionType>>([]);
  const [resultsIndex, setResultsIndex] = useState<number>(0);
  const [resultsTotal, setResultsTotal] = useState<number>(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [searchFilterQuery, setSearchFilterQuery] = useState('');
  const [selectedSortingField, setSelectedSortingField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [currentFilteringTags, setCurrentFilteringTags] = useState<VendorFilterTag[]>([]);
  const [currentAdvancedFilterQuery, setCurrentAdvancedFilterQuery] = useState<Record<string, unknown>[]>([]);
  const [showNewFormModal, setShowNewFormModal] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [serverErrorMsg, setServerErrorMsg] = useState('');
  const [sendFormModalType, setSendFormModalType] = useState<SendVendorFormModalProps['formType']>('new');
  const [currentRow, setCurrentRow] = useState<sendInviteProps | tableSubmissionType | null>(null);
  const [is401Error, setIs401Error] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Query strapi for submissions
  const fetchSubmissionInvites = useCallback(async (qsParams: string, authToken: string) => {
    try {
      const invitesResponse = await fetch(`${process.env.REACT_APP_API_URL}/submissions?${qsParams}`, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const invitesData = await invitesResponse.json();
      const { data, meta, error } = invitesData;
      if (error?.message) {
        throw error;
      }
      return { data, meta };
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error('error fetching submissions:', error);
      if (error?.status === 401) {
        setIs401Error(true);
      }
      throw error;
    }
  }, []);

  // Get submissions and save them in the state
  const getSubmissionInvites = useCallback(
    async (authToken: string, append = false, startIndex = 0) => {
      try {
        const qsObj = {
          populate: ['invite', 'sustainabilityPolicy', 'businessClassification.businessClassificationCategories'],
          filters: {
            invite: { id: { $notNull: 'true' } },
            $and: [{ ...getSearchQuery(searchFilter) }, ...currentAdvancedFilterQuery],
          },
          sort: [`${selectedSortingField}:${sortOrder}`],
          pagination: { start: startIndex, limit: '20' },
        };

        const qsString1 = qs.stringify(qsObj, {
          encodeValuesOnly: true,
        });
        setIsLoading(true);
        const fetchedInvitesObj = await fetchSubmissionInvites(qsString1, authToken);
        if (fetchedInvitesObj?.data.length) {
          if (!append) {
            setCurrentSubmissionInvites(parseInvites(fetchedInvitesObj?.data, storedBusinessUnits[1]));
          } else {
            setCurrentSubmissionInvites(prevResults => [
              ...prevResults,
              ...parseInvites(fetchedInvitesObj?.data, storedBusinessUnits[1]),
            ]);
          }
        } else {
          setCurrentSubmissionInvites([]);
        }
        setIsLoading(false);
        const total = fetchedInvitesObj?.meta?.pagination?.total;
        setResultsTotal(total);
      } catch (error) {
        setIsLoading(false);
        console.error('getSubmissionsInvites', error);
      }
    },
    [
      searchFilter,
      currentAdvancedFilterQuery,
      selectedSortingField,
      sortOrder,
      fetchSubmissionInvites,
      storedBusinessUnits,
    ],
  );

  const handleSearch = (searchQuery: string) => {
    setSearchFilter(searchQuery);
  };

  const handleChangeSorting = (field: string) => {
    if (selectedSortingField !== field) {
      setSortOrder('desc');
      setSelectedSortingField(field);
    }
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const handleSubmissionUpsert = async (params: sendInviteProps, method: 'post' | 'put') => {
    try {
      await upsertVendorFormInvite(method, params, jwt, `${user?.firstName} ${user?.lastName}`, storedBusinessUnits[1]);
      setShowNewFormModal(false);
      setShowConfirmationModal(true);
      getSubmissionInvites(jwt, false, 0);
    } catch (error) {
      console.error(error);
      setServerErrorMsg('The email could not be sent due to an internal problem. Please try again!');
    }
  };

  const handleSendClick = async (submissionInfo: sendInviteProps) => {
    handleSubmissionUpsert(submissionInfo, 'post');
  };
  const handleSendNewClick = () => {
    setCurrentRow(null);
    setSendFormModalType('new');
    setShowNewFormModal(true);
  };
  const handleResubmitClick = (submissionInfo: sendInviteProps) => {
    setCurrentRow(submissionInfo);
    setSendFormModalType('resubmit');
    setShowNewFormModal(true);
  };

  const handleResendClick = (submissionInfo: tableSubmissionType) => {
    setCurrentRow(submissionInfo);
    setSendFormModalType('resend');
    setShowNewFormModal(true);
  };

  const handleResubmit = async (submissionInfo: sendInviteProps) => {
    handleSubmissionUpsert(submissionInfo, 'put');
  };

  const getMoreResults = () => {
    getSubmissionInvites(jwt, true, resultsIndex);
  };

  // Load submissions
  useEffect(() => {
    getSubmissionInvites(jwt, false, 0);
  }, [getSubmissionInvites, jwt]);

  // Update results index
  useEffect(() => {
    if (currentSubmissionInvites) {
      setResultsIndex(currentSubmissionInvites.length);
    }
  }, [currentSubmissionInvites]);

  // Handle advanced filtering
  useEffect(() => {
    const tags = parseFilterQuery(currentFilteringTags);
    setCurrentAdvancedFilterQuery(tags);
  }, [currentFilteringTags]);

  // UI Components
  const Title = <Components.Title>Welcome Back, {user?.firstName}!</Components.Title>;
  const Description = (
    <Components.Description>
      Here you can manage all vendor forms and send new forms for completion.
    </Components.Description>
  );
  const Subtitle = <Components.Subtitle>Vendor forms</Components.Subtitle>;
  const vendorFilter = (
    <VendorFilter
      onTagClick={filterToRemove => {
        const newTags = currentFilteringTags.filter(
          tag => tag.label !== filterToRemove.label || tag.value !== filterToRemove.value,
        );
        setCurrentFilteringTags(newTags);
      }}
      onFilterApply={filterToApply => {
        setCurrentFilteringTags(currentTags => [...currentTags, filterToApply]);
      }}
      currentTags={currentFilteringTags}
    />
  );
  const sendFormBtn = (
    <Components.StyledButton
      onClick={() => {
        handleSendNewClick();
      }}
    >
      Send New Vendor Form
    </Components.StyledButton>
  );
  const seachBar = (
    <SearchBar
      value={searchFilterQuery}
      handleChange={e => setSearchFilterQuery(e.target.value)}
      handleKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
          handleSearch(searchFilterQuery);
          e.currentTarget.blur();
        }
      }}
      handleResetClick={() => {
        setSearchFilterQuery('');
        handleSearch('');
      }}
    />
  );
  const counter = <ResultsCounter total={resultsTotal} />;
  return (
    <Handle401Error isActive={is401Error} user={userFromProps}>
      <AdminPage>
        <Components.Container>
          <Components.Header className="homepage-header">
            {!isSmall ? (
              <>
                <Components.HeaderLeftSide>
                  {Title}
                  {Description}
                  {Subtitle}
                  {vendorFilter}
                </Components.HeaderLeftSide>
                <Components.HeaderRightSide>
                  {sendFormBtn}
                  {seachBar}
                  {counter}
                </Components.HeaderRightSide>
              </>
            ) : (
              <>
                {Title}
                {Description}

                <Components.SendFormWrapperSm>{sendFormBtn}</Components.SendFormWrapperSm>

                {Subtitle}
                {seachBar}
                {vendorFilter}
              </>
            )}
          </Components.Header>
          <SubmissionsTable
            submissions={currentSubmissionInvites}
            onResendClick={rowInfo => {
              handleResendClick(rowInfo);
            }}
            onSort={handleChangeSorting}
            sortingValues={{ field: selectedSortingField, order: sortOrder }}
            onResubmitClick={(rowInfo: sendInviteProps) => {
              handleResubmitClick(rowInfo);
            }}
            hasMore={!!(resultsIndex < resultsTotal)}
            next={getMoreResults}
            isLoading={isLoading}
          />
        </Components.Container>
      </AdminPage>
      <SendVendorFormModal
        formType={sendFormModalType}
        setFormType={setSendFormModalType}
        centered={false}
        open={showNewFormModal}
        setSendNewFormDialogState={setShowNewFormModal}
        handleSendClick={(params: sendInviteProps) => {
          switch (sendFormModalType) {
            case 'new':
              handleSendClick(params);
              break;
            case 'resend':
              handleResubmit(params);
              break;
            case 'resubmit':
              handleResubmit(params);
              break;
            default:
              break;
          }
        }}
        serverError={serverErrorMsg}
        submissionInfo={currentRow}
      />
      <SendConfirmationModal open={showConfirmationModal} setModalOpenState={setShowConfirmationModal} />
    </Handle401Error>
  );
}
