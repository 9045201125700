/* eslint-disable @typescript-eslint/no-explicit-any */
import Components from './styles';
import { Inner, ReviewGridTextArea as TextArea } from '../../ReviewGridStep/styles';
import policies from '../../../Step5/data/sustainabilityPolicies';
import sustainabilityLabels from '../../../Step5/data/sustainabilityLabels';
import { ReviewStep5PropsType } from './types';
import AttachedFilesList from '../../../../components/AttachedFilesList';

export default function ReviewStep5(props: ReviewStep5PropsType) {
  const { fieldValues: sustainabilityData } = props;
  const {
    hasSustainanbilityPolicy: sus,
    sustainabilityPolicyFiles,
    hasEnvironmentPolicy: env,
    environmentPolicyFiles: envFiles,
    hasSocialResponsibilityPolicy: sr,
    socialResponsibilityFiles: srFiles,
    hasSustainabilityProcurementPolicy: proc,
    sustainabilityProcurementFiles: procFiles,
    hasNonePolicies: none,
    sustainabilityResponsibility: responsible,
    hasIdentifiedImpacts: impactsIdentified,
    impactsIdentifiedList,
    hasEnvironmentalManagementStandards: managementStandars,
    managementStandardsDetails: managementStandarsDetails,
  } = sustainabilityData;

  const renderFileList = (fileList: Array<Record<string, unknown>> | undefined) => {
    const getFiles = () => {
      if (fileList) {
        if (Array.isArray(fileList)) {
          return fileList;
        }
        return Array.isArray((fileList as any).data)
          ? (fileList as any).data?.map((f: any) => f.attributes) || []
          : [(fileList as any).data.attributes];
      }
      return [];
    };

    const renderedFiles = getFiles()?.map((currentFile: Record<string, string | unknown>) => ({
      name: currentFile?.name || '',
      url: currentFile?.url || '',
    }));
    return renderedFiles;
  };

  return (
    <>
      {sus && (
        <Inner showBorder>
          <Components.Title>{policies[0].title}</Components.Title>
          <AttachedFilesList files={renderFileList(sustainabilityPolicyFiles)} />
        </Inner>
      )}

      {env && (
        <Inner showBorder>
          <Components.Title>{policies[1].title}</Components.Title>
          <AttachedFilesList files={renderFileList(envFiles)} />
        </Inner>
      )}

      {sr && (
        <Inner showBorder>
          <Components.Title>{policies[2].title}</Components.Title>
          <AttachedFilesList files={renderFileList(srFiles)} />
        </Inner>
      )}

      {proc && (
        <Inner showBorder>
          <Components.Title>{policies[3].title}</Components.Title>
          <AttachedFilesList files={renderFileList(procFiles)} />
        </Inner>
      )}

      {none && (
        <Inner showBorder>
          <Components.Title>{policies[4].title}</Components.Title>
        </Inner>
      )}

      {responsible ? (
        <>
          <Components.SubTitle>{sustainabilityLabels[0].title}</Components.SubTitle>
          <Inner showBorder>
            <Components.Title inner>{responsible ? 'Yes' : 'No'}</Components.Title>
            <Components.Description dangerouslySetInnerHTML={{ __html: sustainabilityLabels[0].label }} />
            <TextArea label="" value={responsible} disabled />
          </Inner>
        </>
      ) : null}
      {impactsIdentified ? (
        <>
          <Components.SubTitle>{sustainabilityLabels[1].title}</Components.SubTitle>
          <Inner showBorder>
            <Components.Title inner>{impactsIdentified}</Components.Title>
            {impactsIdentified === 'Yes' && (
              <>
                <Components.Description>{sustainabilityLabels[1].inputLabel}</Components.Description>
                <TextArea
                  label="List of ways our company is improving our impact on sustainability."
                  value={impactsIdentifiedList}
                  disabled
                />
              </>
            )}
          </Inner>
        </>
      ) : null}
      {managementStandars ? (
        <>
          <Components.SubTitle>{sustainabilityLabels[2].title}</Components.SubTitle>
          <Inner showBorder>
            <Components.Title inner>{managementStandars}</Components.Title>
            {managementStandars === 'Yes' && (
              <>
                <Components.Description>{sustainabilityLabels[2].inputLabel}</Components.Description>
                <TextArea
                  label="Green Office certification, ISO 14001, EMAS"
                  value={managementStandarsDetails}
                  disabled
                />
              </>
            )}
          </Inner>
        </>
      ) : null}
    </>
  );
}
