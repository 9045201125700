/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Components from './styles';
import ToggleType from './types';

const toggleId = uuidv4();

export default function Toggle(props: ToggleType & React.HtmlHTMLAttributes<HTMLInputElement>) {
  const { label, ...rest } = props;
  return (
    <Components.Container>
      <Components.Label htmlFor={toggleId}>
        {label}
        <Components.Checkbox {...rest} id={toggleId} type="checkbox" />
        <Components.ToggleContainer />
      </Components.Label>
    </Components.Container>
  );
}
