/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import BusinessEntityType from '../../content/Step4/types';

export interface businessEntityState {
  data: {
    businessEntity: string,
    accountNumber: string,
    firstName?: string,
    lastName?: string,
    taxClassification?: string,
    resident: string,
  };
}

const initialState: BusinessEntityType = {
  businessEntity: '',
  firstName: '',
  lastName: '',
  taxClassification: '',
  accountNumber: '',
  resident: '',
};

export const businessEntitySlice = createSlice({
  name: 'businessEntity',
  initialState,
  reducers: {
    setBusinessEntity: (state, action: PayloadAction<BusinessEntityType>) => {
      return { ...action.payload };
    },
  },
});

export const { setBusinessEntity } = businessEntitySlice.actions;
export default businessEntitySlice.reducer;
