import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { increment, decrement } from '../../store/slices/stepSlice';
import Button from '../../components/Button';
import { setGBI } from '../../store/slices/generalBusinessInfoSlice';
import { GBISchema as validationSchema, Step2FormType } from './types';
import { FormWrapper, FormSection, FormFlexContainer, ButtonSection, InputWrapperPrint } from './styles';
import { SemiboldPrintLabel, PrintLabel, PrintValue } from '../PrintTemplate/styles';
import { capitalizeString } from '../../utils/text';

export default function Step2FormPrint(props: Step2FormType) {
  const { disableAll, fieldValues } = props;
  const dispatch = useDispatch();

  const { values, handleSubmit, isValid } = useFormik({
    initialValues: fieldValues,
    validateOnMount: true,
    onSubmit: vals => {
      dispatch(setGBI(vals));
      dispatch(increment());
    },
    validationSchema,
  });

  return (
    <FormWrapper>
      <PrintLabel>Name of Business, Organization, or Name of Person (as shown on your tax return)</PrintLabel>
      <PrintValue>{values.nameOfBusiness}</PrintValue>
      <PrintLabel>
        Business Name (DBA)/disregarded entity name, if different from above. (Ensure your input exactly matches your
        business name in the “Payment Information” section of your contract. If you do not have a contract, ensure it
        exactly matches your company or bank letterhead.)
      </PrintLabel>
      <PrintValue>{values.dba}</PrintValue>
      <FormSection>
        <SemiboldPrintLabel>Physical Address</SemiboldPrintLabel>
        <FormFlexContainer>
          <InputWrapperPrint>
            <PrintLabel>Street 1*</PrintLabel>
            <PrintValue>{values.street1}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Street 2</PrintLabel>
            <PrintValue>{values.street2}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>City/Town*</PrintLabel>
            <PrintValue>{values.city}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Zip Code*</PrintLabel>
            <PrintValue>{values.zip}</PrintValue>
          </InputWrapperPrint>
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <SemiboldPrintLabel>Mailing Address</SemiboldPrintLabel>
        <FormFlexContainer>
          <InputWrapperPrint>
            <PrintLabel>Street 1*</PrintLabel>
            <PrintValue>{values.mailingStreet1}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Street 2</PrintLabel>
            <PrintValue>{values.mailingStreet2}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>City/Town*</PrintLabel>
            <PrintValue>{values.mailingCity}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Zip Code*</PrintLabel>
            <PrintValue>{values.mailingZip}</PrintValue>
          </InputWrapperPrint>
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <SemiboldPrintLabel>Phone Numbers</SemiboldPrintLabel>
        <FormFlexContainer>
          <InputWrapperPrint>
            <PrintLabel>Telephone Number*</PrintLabel>
            <PrintValue>{values.phone}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Fax Number</PrintLabel>
            <PrintValue>{values.faxNumber}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Toll Free Number</PrintLabel>
            <PrintValue>{values.tollFreeNumber}</PrintValue>
          </InputWrapperPrint>
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <SemiboldPrintLabel>Contact Person</SemiboldPrintLabel>
        <FormFlexContainer>
          <InputWrapperPrint>
            <PrintLabel>First Name*</PrintLabel>
            <PrintValue>{values.firstName}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Last Name*</PrintLabel>
            <PrintValue>{values.lastName}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Title*</PrintLabel>
            <PrintValue>{values.title}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Email Address*</PrintLabel>
            <PrintValue>{values.email}</PrintValue>
          </InputWrapperPrint>
          <InputWrapperPrint>
            <PrintLabel>Web Address</PrintLabel>
            <PrintValue>{values.webAddress}</PrintValue>
          </InputWrapperPrint>
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <PrintLabel>Payment Terms*</PrintLabel>
        <InputWrapperPrint widthPercentage={100}>
          <PrintValue>{capitalizeString(values.stp)}</PrintValue>
        </InputWrapperPrint>
        {values.stpOther ? (
          <>
            <PrintLabel>Please describe your payment terms*</PrintLabel>
            <InputWrapperPrint widthPercentage={100}>
              <PrintValue>{values.stpOther}</PrintValue>
            </InputWrapperPrint>
          </>
        ) : null}
      </FormSection>
      {!disableAll ? (
        <ButtonSection>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(decrement());
            }}
          >
            Back
          </Button>
          <Button
            disabled={!isValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Next
          </Button>
        </ButtonSection>
      ) : null}
    </FormWrapper>
  );
}

Step2FormPrint.defaultProps = {
  disableAll: false,
};
