import { ResourceTitle, ResourceOl } from './styles';

export function VendorResource3() {
  return (
    <>
      <ResourceTitle>U.S. Person</ResourceTitle>
      <p>
        Use this form <u>only if you are</u> a U.S. person (including a resident alien) to provide your correct TIN to
        the requestor, and when applicable, to:
      </p>
      <ResourceOl>
        <li>Certify that the TIN you are giving is correct (or you are waiting for a number to be issued).</li>
        <li>Certify that you are not subject to backup withholding, or</li>
        <li>Claim exemption from backup withholding if you are a U.S. exempt payee.</li>
      </ResourceOl>

      <ResourceTitle>Nonresident Alien</ResourceTitle>
      <p>
        Do <u>not</u> use this form. Instead, use IRS form W-8, Certification of Foreign Status of Beneficial owner for
        U.S. Tax Withholding. See IRS Publication 515, “Withholding of Tax on Nonresident Aliens and Foreign Entities”
        for more information.
      </p>
    </>
  );
}

export default {
  title: 'Determine U.S. Vs Foreign Status',
  content: <VendorResource3 />,
};
