import React from 'react';
import Components from './styles';
import StepType from './types';
import checkIcon from './checkIcon.svg';

export default function Step(props: StepType) {
  const { title, stepNumber, status } = props;
  return (
    <Components.StepWrapper status={status}>
      <Components.IndicatorWrapper>
        <Components.Indicator status={status}>
          {status === 'completed' && <Components.Icon src={checkIcon} />}
        </Components.Indicator>
        <Components.Separator status={status} />
      </Components.IndicatorWrapper>
      <Components.Content>
        <Components.Subtitle>
          Step {stepNumber?.toString().length === 1 ? '0' : ''}
          {stepNumber}
        </Components.Subtitle>
        <Components.Title>{title}</Components.Title>
      </Components.Content>
    </Components.StepWrapper>
  );
}

Step.defaultProps = {
  title: 'Review Vendor Information Resources',
  stepNumber: 1,
  status: 'pending',
} as Partial<StepType>;
