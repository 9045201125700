import * as yup from 'yup';
import { fileArraySchema } from '../../types';

export const BusinessClassificationSchema = yup.object({
  internalId: yup.string(),
  title: yup.string().required(),
  description: yup.string(),
  requireFiles: yup.boolean(),
  relatedFiles: yup.array().when('requireFiles', {
    is: true,
    then: fileArraySchema,
  }),
});

export const Step8Schema = yup.object({
  businessClassificationCategories: yup
    .array(BusinessClassificationSchema)
    .min(1, 'Please select at least one category')
    .required(),
});

export type BusinessClassificationCategoryType = yup.InferType<typeof BusinessClassificationSchema>;

export type BusinessClassificationType = yup.InferType<typeof Step8Schema>;

export default BusinessClassificationType;
