import styled from 'styled-components/macro';

export const PanelWrapper = styled.div`
  table {
    border-spacing: ${({ theme }) => `${theme.getValue(12)}px`} 0;
    margin-bottom: ${({ theme }) => `${theme.getValue(15)}px`};
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      border-spacing: ${({ theme }) => `${theme.getValue(4)}px`} 0;
    }
  }
  th {
    text-align: left;
    width: 50%;
    font-weight: ${({ theme }) => `${theme.weights.semibold}`};
    font-size: ${({ theme }) => `${theme.getValue(12)}px`};
    line-height: ${({ theme }) => `${theme.getValue(16)}px`};
    padding: ${({ theme }) => `${theme.getValue(16)}px`};
    border: solid 1px ${({ theme }) => `${theme.colors.black}`};
    border-bottom: 0;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      padding: ${({ theme }) => `${theme.getValue(12)}px`} ${({ theme }) => `${theme.getValue(8)}px`};
    }
  }
  tr {
    &:last-child {
      td {
        border-bottom: solid 1px ${({ theme }) => `${theme.colors.black}`};
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }
  td {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    width: 50%;
    vertical-align: top;
    font-size: ${({ theme }) => `${theme.getValue(12)}px`};
    line-height: 1;
    color: ${({ theme }) => `${theme.colors.gray2}`};
    padding: 0 ${({ theme }) => `${theme.getValue(16)}px`} ${({ theme }) => `${theme.getValue(12)}px`};
    border-left: solid 1px ${({ theme }) => `${theme.colors.black}`};
    border-right: solid 1px ${({ theme }) => `${theme.colors.black}`};
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      padding: ${({ theme }) => `${theme.getValue(4)}px`} ${({ theme }) => `${theme.getValue(8)}px`};
    }
    p {
      @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
        line-height: 1;
        font-size: ${({ theme }) => `${theme.getValue(12)}px`};
      }
    }
    div {
      display: flex;
      align-items: baseline;
      strong {
        display: inline-block;
        font-family: ${({ theme }) => `${theme.fonts.primary}`};
        font-size: ${({ theme }) => `${theme.getValue(12)}px`};
        line-height: 1;
        color: ${({ theme }) => `${theme.colors.gray2}`};
        font-weight: ${({ theme }) => `${theme.weights.bold}`};
        width: ${({ theme }) => `${theme.getValue(20)}px`};
      }
    }
  }
`;

export const FootnoteWrapper = styled.ol`
  margin-top: ${({ theme }) => `${theme.getValue(22)}px`};
  margin-bottom: 0;
  padding-left: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export const Footnote = styled.li`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => `${theme.getValue(10)}px`};
  line-height: 150%;
  letter-spacing: -0.015em;
`;

export const Note = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-style: normal;
  font-weight: 400;
  font-size: ${({ theme }) => `${theme.getValue(10)}px`};
  line-height: 150%;
  letter-spacing: -0.015em;
  ${'.accordion__accordion-content'} & {
    margin-bottom: 0;
    padding-left: ${({ theme }) => `${theme.getValue(12)}px`};
    font-size: ${({ theme }) => `${theme.getValue(10)}px`};
    line-height: 150%;
  }
`;

export const ResourceTitle = styled.h2`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(17)}px`};
  line-height: ${({ theme }) => `${theme.getValue(32)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  .accordion__accordion-content & {
    line-height: normal;
    margin-top: 0;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
      line-height: ${({ theme }) => `${theme.getValue(22)}px`};
    }
  }
`;

export const ResourceOl = styled.ol`
  padding-left: ${({ theme }) => `${theme.getValue(20)}px`};
  li {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-style: normal;
    font-size: ${({ theme }) => `${theme.getValue(12)}px`};
    line-height: 150%;
    /* or 18px */

    letter-spacing: -0.015em;
  }
`;

export const ResourceUl = styled.ul`
  padding-left: ${({ theme }) => `${theme.getValue(20)}px`};
  li {
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    font-style: normal;
    font-size: ${({ theme }) => `${theme.getValue(12)}px`};
    line-height: 150%;
    /* or 18px */

    letter-spacing: -0.015em;
  }
`;
