import * as yup from 'yup';
import YupPassword from 'yup-password';
import { CustomPopUpPropsType } from '../../IVPPopup/types';

YupPassword(yup);

export const ChangePasswordSchema = yup.object({
  currentPassword: yup.string().required('Please enter your current password'),
  password: yup.string().password().minSymbols(0).required('Please enter your new password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match!")
    .required('Please enter your new password'),
});

export type ChangePasswordFieldsType = yup.InferType<typeof ChangePasswordSchema>;

export type ChangePasswordModalProps = {
  handleSaveClick: (passInfo: ChangePasswordFieldsType) => void;
} & Omit<CustomPopUpPropsType, 'children'>;
