import { useState } from 'react';
import { Link } from 'react-router-dom';
import resources from '../../content/vendor-resources';
import Components from './styles';
import IconMobile from '../../assets/images/icon-menu-mobile.svg';
import IconClose from '../../assets/images/icon-close.svg';

export default function MenuDropdown() {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  return (
    <>
      <Components.MobileTrigger>
        <Components.MobileTriggerBtn
          onClick={() => {
            setIsOpen(prevState => !prevState);
          }}
        >
          <Components.MobileTriggerLogo src={isOpen ? IconClose : IconMobile} alt="Vendor Form Resources" />
        </Components.MobileTriggerBtn>
        {isOpen ? (
          <Components.Panel>
            <Components.MobilePanelTitle>Vendor Form Resources</Components.MobilePanelTitle>
            <Components.List>
              {resources.map((resource, index) => (
                <Components.ListItem key={`${resource.title}`}>
                  <Link
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    to={`/resource/${index + 1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${resource.title}`}
                  </Link>
                </Components.ListItem>
              ))}
            </Components.List>
          </Components.Panel>
        ) : null}
      </Components.MobileTrigger>
      <Components.Wrapper className={isOpen ? 'is-open' : ''}>
        <Components.Trigger
          type="button"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          Vendor Form Resources
        </Components.Trigger>
        {isOpen ? (
          <Components.Panel>
            <Components.List>
              {resources.map((resource, index) => (
                <Components.ListItem key={`${resource.title}`}>
                  <Link
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    to={`/resource/${index + 1}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {`${resource.title}`}
                  </Link>
                </Components.ListItem>
              ))}
            </Components.List>
          </Components.Panel>
        ) : null}
      </Components.Wrapper>
    </>
  );
}
