import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { decrement, increment } from '../../store/slices/stepSlice';
import { setConflictOfInterestState } from '../../store/slices/conflictOfInterestSlice';
import CheckboxItem from '../../components/CheckboxItem';
import Headline from '../../components/typography/Headline';
import Button from '../../components/Button';
import { RootState } from '../../store/store';
import { ButtonSection } from '../Step2/styles';
import ConflictOfInterestType, { Step7Schema as validationSchema } from './types';
import {
  Container,
  Label,
  InputContainer,
  StyledInput as Input,
  StyledPhoneInput as PhoneInput,
  FormFlexContainer,
  DecisionContainer,
  Intro,
} from './styles';
import useScrollToTop from '../stepUtils';

export default function Step7() {
  useScrollToTop();
  const conflictOfInterestData: ConflictOfInterestType = useSelector((state: RootState) => state.conflictOfInterest);

  const dispatch = useDispatch();
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isValid } = useFormik({
    initialValues: conflictOfInterestData,
    validateOnChange: true,
    onSubmit: vals => {
      dispatch(setConflictOfInterestState(vals));
      dispatch(increment());
    },
    validationSchema,
  });
  useEffect(() => {
    if (values.conflictOfInterest === 'No') {
      setFieldValue('employeesFirstName', '');
      setFieldValue('employeesLastName', '');
      setFieldValue('employeesRelationship', '');
      setFieldValue('employeesPhoneNumber', '');
    }
  }, [values.conflictOfInterest, setFieldValue]);

  const booleanEntries = [
    {
      label: 'Yes',
    },
    {
      label: 'No',
    },
  ];

  return (
    <Container>
      <Headline>Conflict of Interest</Headline>
      <Intro>
        <strong>
          Does any individual employed by Advantage Solutions have a significant interest in your business?
        </strong>{' '}
        A significant interest includes any situation where an Advantage Solutions employee and/or his or her spouse,
        child, or other relation has an ownership or employment relationship to the vendor. A conflict of interest is
        one that a reasonable person would think may appear to compromise the open, competitive process.
      </Intro>
      <DecisionContainer>
        {booleanEntries.map(entry => (
          <CheckboxItem
            key={entry.label}
            name="conflictOfInterest"
            title={entry?.label}
            description=""
            value={entry?.label}
            onClick={() => {
              setFieldValue('conflictOfInterest', entry.label);
            }}
            onBlur={handleBlur}
            isChecked={entry?.label === values.conflictOfInterest}
            touched={touched.conflictOfInterest}
            error={errors.conflictOfInterest}
          >
            {values.conflictOfInterest === 'Yes' ? (
              <InputContainer>
                <Label>A conflict of interest has been indicated. Please provide the following information.</Label>
                <FormFlexContainer>
                  <Input
                    type="text"
                    id="employeesFirstName"
                    name="employeesFirstName"
                    label="Employee’s First Name*"
                    value={values.employeesFirstName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.employeesFirstName}
                    error={errors.employeesFirstName}
                  />
                  <Input
                    type="text"
                    id="employeesLastName"
                    name="employeesLastName"
                    label="Employee’s Last Name*"
                    value={values.employeesLastName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    touched={touched.employeesLastName}
                    error={errors.employeesLastName}
                  />
                </FormFlexContainer>
                <Input
                  type="text"
                  id="employeesRelationship"
                  name="employeesRelationship"
                  label="Employee’s Relationship and Position within Company*"
                  value={values.employeesRelationship}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.employeesRelationship}
                  error={errors.employeesRelationship}
                />
                <PhoneInput
                  id="employeesPhoneNumber"
                  name="employeesPhoneNumber"
                  label="Employee’s Telephone Number*"
                  value={values.employeesPhoneNumber}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  touched={touched.employeesPhoneNumber}
                  error={errors.employeesPhoneNumber}
                />
              </InputContainer>
            ) : null}
          </CheckboxItem>
        ))}
      </DecisionContainer>
      <ButtonSection>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Back
        </Button>
        <Button
          disabled={!isValid || !values.conflictOfInterest}
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </ButtonSection>
    </Container>
  );
}
