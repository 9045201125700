import styled from 'styled-components/macro';
import Headline from '../../components/typography/Headline';

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(48)}px`};
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: ${({ theme }) => `${theme.getValue(12)}px`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  margin-top: ${({ theme }) => `${theme.getValue(15)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(12)}px`};
  display: inline-block;
  &.name-label {
    margin-bottom: 0;
    font-weight: ${({ theme }) => `${theme.weights.bold}`};
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export const StyledHeadline = styled(Headline)`
  margin-top: 0;
`;

export default { ButtonSection, Label, InputContainer, StyledHeadline };
