import styled from 'styled-components/macro';
import { Error } from '../../Input/styles';
import Button from '../../Button';

const Container = styled.div``;

const ButtonGroup = styled.div`
  display: flex;
  gap: ${({ theme }) => `${theme.getValue(20)}px`};
  justify-content: flex-end;
  ${Button} {
    padding-left: ${({ theme }) => `${theme.getValue(24)}px`};
    padding-right: ${({ theme }) => `${theme.getValue(24)}px`};
    min-width: ${({ theme }) => `${theme.getValue(90)}px`};
    margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
  }
`;

const FormContainer = styled.div`
  width: ${({ theme }) => `${theme.getValue(290)}px`};
`;

const StyledError = styled(Error)`
  text-align: right;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
`;

export default { Container, ButtonGroup, FormContainer, StyledError };
