export default [
  {
    title: 'Sustainability Responsibility',
    label:
      'Does a body or position within your company have the overall responsibility for sustainability (environmental and social responsibility)?',
    inputLabel: 'Please provide details.*',
  },
  {
    title: 'Sustainability Impacts',
    label:
      'Has your company identified its key sustainability impacts and is the company working to improve its sustainability performance in these key areas?',
    inputLabel:
      'Please list the key impacts and provide a short description of your efforts and recent achievements in improving the company’s performance in these areas',
  },
  {
    title: 'Environmental Management Standards',
    label:
      'Has your company identified its key sustainability impacts and is the company working to improve its sustainability performance in these key areas?',
    inputLabel: 'Please provide details',
  },
];
