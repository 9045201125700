import { useFormik } from 'formik';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { decrement } from '../../store/slices/stepSlice';
import { setResponsabilityCertification } from '../../store/slices/responsabilityCertificationSlice';
import Button from '../../components/Button';
import ResponsabilityCertificationType, { Step9Schema as validationSchema } from './types';
import { RootState } from '../../store/store';
import CheckboxUpload from '../../components/CheckboxUpload';
import { Error } from '../../components/Input/styles';
import certification from './data/certify';
import { ButtonSection, StyledHeadline as Headline } from '../Step2/styles';
import { Container, Label, CertifyContainer } from './styles';
import useScrollToTop from '../stepUtils';

const localUuid = uuid();
export default function Step9() {
  useScrollToTop();
  const responsabilityCertificationData: ResponsabilityCertificationType = useSelector(
    (state: RootState) => state.responsabilityCertification,
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { values, touched, errors, handleSubmit, setFieldValue, setFieldTouched } = useFormik({
    initialValues: responsabilityCertificationData,
    onSubmit: vals => {
      dispatch(setResponsabilityCertification(vals));
      navigate(`/review${document.location.search}`);
    },
    validationSchema,
    validateOnMount: true,
  });

  return (
    <Container>
      <Headline>Debarment, Suspension, and Other Responsibility Matters</Headline>
      <Label>
        A. The vendor certifies that it and its principals:
        <br />
        <br />
        <ol>
          <li>
            Are not presently debarred, suspended, proposed for debarment, declared ineligible, sentenced to a denial of
            Federal benefits by a State or Federal court, or voluntarily excluded from covered transactions by any
            Federal department or agency;
          </li>
          <li>
            Have not within a three-year period preceding this application been convicted of or had a civil judgment
            rendered against them for commission of fraud or a criminal offense in connection with obtaining, attempting
            to obtain, or performing a public (Federal, State, or local) transaction or contract under a public
            transaction; violation of Federal or State antitrust statutes or commission of embezzlement, theft, forgery,
            bribery, falsification or destruction of records, making false statements, or receiving stolen property;
          </li>
          <li>
            Are not presently indicted for or otherwise criminally or civilly charged by a governmental entity (Federal,
            State, or local) with the commission of paying person(s) for influencing or attempting to influence an
            officer or employee of any agency, a Member of Congress, an officer or employee of Congress, or an employee
            of a Member of Congress; and
          </li>
          <li>
            Have not within a three-year period preceding this application had one or more public transactions (Federal,
            State, or local) terminated for cause or default;
          </li>
        </ol>
        <br /> B. Where the vendor is unable to certify to any of the statements in this certification, he or she shall
        attach an explanation to this information form.
      </Label>
      <CertifyContainer>
        {certification.map((entry, index) => (
          <CheckboxUpload
            key={`${localUuid}-${entry.label.replace(' ', '-').toLocaleLowerCase()}`}
            title={entry.label}
            description={entry.description}
            uploadLabel={entry.uploadLabel}
            enableRadioTheme
            collapsible={index === certification.length - 1}
            onClick={() => {
              setFieldTouched('vendorCertify', true);
              setFieldTouched('vendorCertifyFiles', true);
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.checked) {
                setFieldValue('vendorCertify', entry.label);
                setFieldValue('vendorDescription', entry.description);
                setFieldValue('requireFiles', entry.requireFiles);
              }
            }}
            filesErrors={values.requireFiles && entry.requireFiles ? errors.vendorCertifyFiles : ''}
            enableUploadControl={entry?.requireFiles && values.vendorCertify === entry.label}
            filesUploaded={values.vendorCertifyFiles}
            filesLocation="vendorCertifyFiles"
            saveFiles={setFieldValue}
            isChecked={values.vendorCertify === entry.label}
            touched={touched.vendorCertify}
          />
        ))}
      </CertifyContainer>
      {!_.isEmpty(touched) && !values.vendorCertify ? <Error>Please select one option</Error> : null}
      <ButtonSection>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Back
        </Button>
        <Button
          disabled={!values.vendorCertify || !!errors.vendorCertifyFiles}
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </ButtonSection>
    </Container>
  );
}
