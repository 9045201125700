import styled from 'styled-components/macro';

const Container = styled.div`
  width: ${({ theme }) => `${theme.getValue(510)}px`};
  padding: ${({ theme }) => `${theme.getValue(50)}px`} ${({ theme }) => `${theme.getValue(70)}px`};
`;

const FormWrapper = styled.div`
  padding-right: ${({ theme }) => `${theme.getValue(70)}px`};
`;

export default { Container, FormWrapper };
