export default [
  {
    id: 'C',
    title: 'C Corporation',
  },
  {
    id: 'S',
    title: 'S Corporation',
  },
  {
    id: 'P',
    title: 'Partnertship',
  },
  {
    id: 'I',
    title: 'Individual',
  },
];
