/* eslint-disable react/jsx-props-no-spreading */
import { v4 as uuid } from 'uuid';
import { Container, Error, NumbersContainer, GroupNumbers, SingleNumber, SeparationBar } from './styles';

export type Props = {
  type: string;
  error: string;
  value: string;
  valueLength: number;
  touched: boolean | undefined;
  onChange: (value: string) => void;
  onBlur: () => void;
};

const businessID = {
  type1: {
    id: 'SSN',
    title: 'Social Security Account',
  },
  type2: {
    id: 'EIN',
    title: 'Federal Employer Identification',
  },
};

const RE_DIGIT = /^\d+$/;

const numberInputUuid = uuid();

export default function NumberInput(props: Props) {
  const { type, error, value, valueLength, onChange, touched, onBlur } = props;
  const businessType = businessID[type as keyof typeof businessID];

  const valueItems = () => {
    const valueArray = value.split('');
    const items: Array<string> = [];
    for (let i = 0; i < valueLength; i += 1) {
      const char = valueArray[i];
      if (RE_DIGIT.test(char)) {
        items.push(char);
      } else {
        items.push('');
      }
    }
    return items;
  };

  const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
    const { target } = e;
    let targetValue = target.value;
    const isTargetValueDigit = RE_DIGIT.test(targetValue);
    if (!isTargetValueDigit && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : ' ';
    const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);
    onChange(newValue);
    if (!isTargetValueDigit) {
      return;
    }
    let nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
    if (businessType.id === businessID.type2.id && idx === 1) {
      const underscore = target.nextElementSibling;
      const underscoreSibling = underscore?.nextElementSibling;
      nextElementSibling = underscoreSibling as HTMLInputElement | null;
    }
    if (businessType.id === businessID.type1.id && (idx === 2 || idx === 4)) {
      const underscore = target.nextElementSibling;
      const underscoreSibling = underscore?.nextElementSibling;
      nextElementSibling = underscoreSibling as HTMLInputElement | null;
    }
    if (nextElementSibling) {
      nextElementSibling.focus();
    }
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, idx: number) => {
    const target = e.target as HTMLInputElement;
    const targetValue = target.value;

    target.setSelectionRange(0, targetValue.length);

    if (e.key !== 'Backspace' || target.value !== '') {
      return;
    }

    let previousElementSibling = target.previousElementSibling as HTMLInputElement | null;

    if (businessType.id === businessID.type2.id && idx === 2) {
      const underscore = target.previousElementSibling;
      const underscoreSibling = underscore?.previousElementSibling;
      previousElementSibling = underscoreSibling as HTMLInputElement | null;
    }

    if (businessType.id === businessID.type1.id && (idx === 3 || idx === 5)) {
      const underscore = target.previousElementSibling;
      const underscoreSibling = underscore?.previousElementSibling;
      previousElementSibling = underscoreSibling as HTMLInputElement | null;
    }

    if (previousElementSibling) {
      previousElementSibling.focus();
    }
  };

  return (
    <Container>
      <p>Please enter your {businessType.title} Number*</p>
      <NumbersContainer>
        <GroupNumbers>
          {valueItems().map((digit, idx) => (
            <>
              <SingleNumber
                // eslint-disable-next-line react/no-array-index-key
                key={`${numberInputUuid}-single-number-${idx}`}
                type="text"
                inputMode="numeric"
                pattern="\d{1}"
                maxLength={1}
                onChange={e => inputOnChange(e, idx)}
                onKeyDown={e => inputOnKeyDown(e, idx)}
                value={digit}
                onBlur={onBlur}
              />
              {businessType.id === businessID.type2.id && idx === 1 && (
                // eslint-disable-next-line react/no-array-index-key
                <SeparationBar key={`${numberInputUuid}-single-number-separation-${idx}`} />
              )}
              {businessType.id === businessID.type1.id && (idx === 2 || idx === 4) && (
                // eslint-disable-next-line react/no-array-index-key
                <SeparationBar key={`${numberInputUuid}-single-number-separation-${idx}`} />
              )}
            </>
          ))}
        </GroupNumbers>
      </NumbersContainer>
      {error && touched && <Error>{error}</Error>}
    </Container>
  );
}
