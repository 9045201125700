import styled from 'styled-components/macro';
import { StyledInput as Input } from '../Step2/styles';
import PhoneInput from '../../components/Input/PhoneInput';

export const StyledInput = styled(Input)`
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    margin-bottom: ${({ theme }) => `${theme.getValue(16)}px`};
  }
`;
export const StyledPhoneInput = styled(PhoneInput)``;

export const Container = styled.div`
  line-height: ${({ theme }) => `${theme.getValue(18)}px`};
`;

export const ButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(48)}px`};
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export const Intro = styled.p`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 150%;
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  margin: ${({ theme }) => `${theme.getValue(8)}px 0`};
  margin-bottom: ${({ theme }) => `${theme.getValue(16)}px`};
`;

export const Label = styled.label`
  color: ${({ theme }) => `${theme.colors.gray1}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(18)}px`};
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  margin: ${({ theme }) => `${theme.getValue(14)}px 0`};
  margin-bottom: 0;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    margin-bottom: ${({ theme }) => `${theme.getValue(24)}px`};
    line-height: 150%;
  }
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${StyledInput} {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      margin-top: 0;
    }
  }
  ${StyledPhoneInput} {
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      margin-top: 0;
    }
  }
`;

export const FormFlexContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
  ${StyledInput} {
    width: calc(50% - ${({ theme }) => `${theme.getValue(10)}px`});
    min-width: 0;
    @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
      width: 100%;
    }
  }
`;

export const DecisionContainer = styled.div`
  margin: ${({ theme }) => `${theme.getValue(14)}px 0`};
`;

export default { Container, ButtonSection, Label, InputContainer, Intro };
