import { useState, useRef, useEffect } from 'react';
import { isTablet } from 'react-device-detect';
import Components from './styles';
import { MoreOptionsPropsType } from './types';

export default function MoreOptions(props: MoreOptionsPropsType) {
  const { onResubmitClick, onResendClick, submissionData, showCompletedOptions } = props;
  const [isVisible, setIsVisible] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const el = event.target;
      if (containerRef.current && el instanceof Node && !containerRef.current.contains(el)) {
        setIsVisible(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  return (
    <Components.MoreButtonWrapper ref={containerRef}>
      <Components.MoreButton
        type="button"
        onClick={() => {
          setIsVisible(true);
        }}
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 6C11.1 6 12 5.1 12 4C12 2.9 11.1 2 10 2C8.9 2 8 2.9 8 4C8 5.1 8.9 6 10 6ZM10 8C8.9 8 8 8.9 8 10C8 11.1 8.9 12 10 12C11.1 12 12 11.1 12 10C12 8.9 11.1 8 10 8ZM10 14C8.9 14 8 14.9 8 16C8 17.1 8.9 18 10 18C11.1 18 12 17.1 12 16C12 14.9 11.1 14 10 14Z"
            fill="#0090BA"
          />
        </svg>
      </Components.MoreButton>
      {isVisible ? (
        <Components.MoreOptionsWrapper>
          <ul>
            {!showCompletedOptions ? (
              <li>
                <Components.MoreOptionsButton
                  type="button"
                  onClick={() => {
                    onResendClick();
                  }}
                >
                  Resend
                </Components.MoreOptionsButton>
              </li>
            ) : (
              <>
                <li>
                  <Components.MoreOptionsButton as="a" href={`/review/form/${submissionData?.submissionId}`}>
                    Review
                  </Components.MoreOptionsButton>
                </li>
                {!isTablet && (
                  <li>
                    <Components.MoreOptionsButton
                      type="button"
                      onClick={() => {
                        window.open(`/review/form/${submissionData?.submissionId}?download=true`, '_blank');
                      }}
                    >
                      Download
                    </Components.MoreOptionsButton>
                  </li>
                )}
                <li>
                  <Components.MoreOptionsButton
                    type="button"
                    onClick={() => {
                      onResubmitClick(submissionData);
                      setIsVisible(false);
                    }}
                  >
                    Resubmit
                  </Components.MoreOptionsButton>
                </li>
              </>
            )}
          </ul>
        </Components.MoreOptionsWrapper>
      ) : null}
    </Components.MoreButtonWrapper>
  );
}
