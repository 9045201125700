import * as yup from 'yup';
import { REQUIRED_VALIDATION_MSG } from '../constants';
import GBIType from '../content/Step2/types';
import BusinessEntityType from '../content/Step4/types';
import SustainabilityPolicyType from '../content/Step5/types';
import IrsFormType from '../content/Step6/types';
import ConflictOfInterestType from '../content/Step7/types';
import BusinessClassificationType from '../content/Step8/types';
import ResponsabilityCertificationType from '../content/Step9/types';
import { vendorActivityState } from '../store/slices/vendorActivitySlice';

const fileSchema = yup
  .object()
  .shape({
    file: yup.mixed().test('fileType', 'Please upload only pdf files', value => value.type.includes('pdf')),
    url: yup.string(),
    name: yup.string(),
  })
  .required(REQUIRED_VALIDATION_MSG);

export const fileArraySchema = yup
  .array()
  .min(1, 'Please upload document(s) in PDF format only.')
  .required(REQUIRED_VALIDATION_MSG)
  .of(fileSchema);

export type FileObjType = yup.InferType<typeof fileSchema>;
export type FileArrayType = yup.InferType<typeof fileArraySchema>;

export type SubmissionInviteType = {
  nameOfBusiness?: string;
  contactName?: string;
  email: string;
  completed: boolean;
  sender: string;
  senderEmail: string;
  sendDate: string;
  completedDate?: string;
  action: 'invite' | 'submit' | 'resend' | 'resubmit';
  businessUnit: string;
};

export type SubmissionType = {
  id: string;
  uuid: string;
  gBI: GBIType;
  vendorActivity: vendorActivityState;
  businessEntity: BusinessEntityType;
  conflictOfInterest: ConflictOfInterestType;
  businessClassification: BusinessClassificationType;
  irsForm: IrsFormType;
  sustainabilityPolicy: SustainabilityPolicyType;
  responsabilityCertification: ResponsabilityCertificationType;
  invite: SubmissionInviteType;
};
