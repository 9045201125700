import { useNavigate } from 'react-router-dom';
import StyledComponents from './styles';
import LogoImg from '../../assets/images/advantage-logo.svg';
import { HeaderType } from './types';
import useCurrentUserInfo from '../../hooks/useUserInfo';

export default function Header(props: HeaderType) {
  const { rightSection } = props;
  const navigate = useNavigate();
  const currentUser = useCurrentUserInfo();
  return (
    <StyledComponents.Header>
      <StyledComponents.Logo
        isClickable={!!currentUser.user}
        onClick={() => {
          if (currentUser.user) {
            navigate('/home');
          }
        }}
      >
        <img src={LogoImg} alt="Advantage Solutions" />
      </StyledComponents.Logo>
      {rightSection ? <div>{rightSection}</div> : null}
    </StyledComponents.Header>
  );
}

Header.defaultProps = {
  rightSection: null,
} as Partial<HeaderType>;
