import styled from 'styled-components';

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => `${theme.getValue(16)}px`};
`;

const UploadLabel = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 150%;
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  margin: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(4)}px`};
  color: ${({ theme }) => `${theme.colors.black}`};
`;

const StyledInputContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: ${({ theme }) => `${theme.getValue(13)}px`};
  background-color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  padding: ${({ theme }) => `${theme.getValue(17)}px 0`};
  border: 1px dashed ${({ theme }) => `${theme.colors.white}`};
  border-radius: 6px;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    padding: ${({ theme }) => `${theme.getValue(10)}px 0`};
  }
  &:hover {
    cursor: pointer;
  }
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledInputIcon = styled.img`
  width: ${({ theme }) => `${theme.getValue(13.3)}px`};
  height: ${({ theme }) => `${theme.getValue(16.6)}px`};
`;

const StyledInputLabel = styled.p`
  margin: 0;
  color: ${({ theme }) => `${theme.colors.white}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}, Proxima Nova`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: ${({ theme }) => `${theme.getValue(16)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
`;

const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => `${theme.getValue(24)}px`};
`;

const FileUploaded = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${({ theme }) => `${theme.getValue(16)}px 0`};
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

const FileName = styled.p`
  margin: 0;
  color: ${({ theme }) => `${theme.colors.black}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}, Proxima Nova`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: ${({ theme }) => `${theme.getValue(12)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
`;

const DeleteFileIcon = styled.img`
  width: ${({ theme }) => `${theme.getValue(13.3)}px`};
  height: ${({ theme }) => `${theme.getValue(16.6)}px`};
`;

export default {
  UploadContainer,
  UploadLabel,
  StyledInputContainer,
  StyledInput,
  StyledInputIcon,
  StyledInputLabel,
  FileContainer,
  FileUploaded,
  FileName,
  DeleteFileIcon,
};
