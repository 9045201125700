import styled from 'styled-components';

const Pill = styled.div`
  display: flex;
  align-items: center;
  border: solid 1px ${({ theme }) => `${theme.colors.gray4}`};
  border-radius: 13px;
  padding: ${({ theme }) => `${theme.getValue(3)}px ${theme.getValue(12)}px`};
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.medium}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(20)}px`};
  text-transform: capitalize;
  color: ${({ theme }) => `${theme.colors.gray11}`};
  & > span {
    color: ${({ theme }) => `${theme.colors.black}`};
    margin-left: ${({ theme }) => `${theme.getValue(6)}px`};
  }
`;
const PillBtn = styled.button`
  padding: 0;
  margin: 0;
  display: block;
  width: ${({ theme }) => `${theme.getValue(12)}px`};
  height: ${({ theme }) => `${theme.getValue(12)}px`};
  background-image: url("data:image/svg+xml,%3Csvg viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 13L13 1M1 1L13 13' stroke='%239CA3AF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
  box-sizing: content-box;
  border: 0;
  background-color: transparent;
  margin-left: ${({ theme }) => `${theme.getValue(16)}px`};
  span {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  background-repeat: no-repeat;
  &:hover {
    cursor: pointer;
  }
`;

export default { Pill, PillBtn };
