/* eslint-disable jsx-a11y/control-has-associated-label */
import { useState, useEffect, useCallback } from 'react';
import qs from 'qs';
import { useTheme } from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import StickyTable from '../../StickyTableHeader';
import { UserType } from './types';
import Components from './styles';
import InviteUserModal from '../InviteUserModal';
import useCurrentUserInfo from '../../../hooks/useUserInfo';
import BackArrow from '../../BackArrow';
import SortArrowImg from './sortArrow.svg';
import Handle401Error from '../../Handle401Error';
import useDimensions from '../../../hooks/useDimensions';

const parseUsers = (
  usersToParse: Array<{
    id?: string | number;
    firstName?: string;
    lastName?: string;
    email?: string;
    createdAt?: string;
  }>,
) => {
  return usersToParse.map(user => ({
    id: user?.id,
    name: `${user?.firstName} ${user?.lastName}`,
    email: user?.email,
    dateAdded: new Date(user?.createdAt || '').toLocaleDateString(),
  }));
};

const columnWidthsLarge: string[] = ['32%', '32%', '30%', '6%'];
const columnWidthsSm: string[] = ['32%', '36%', '16%', '16%'];

export default function Landing() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const screenDimensions = useDimensions();
  const isSmall = screenDimensions.width <= theme.breakpoints.small;
  const columnWidths: string[] = isSmall ? columnWidthsSm : columnWidthsLarge;
  const userInfo = useCurrentUserInfo();
  const { jwt } = userInfo;
  const [selectedSortingField, setSelectedSortingField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState<'desc' | 'asc'>('desc');
  const [emailFilter, setEmailFilter] = useState('');
  const [currentUsersList, setCurrentUsersList] = useState<Array<UserType>>([]);
  const [resultsIndex, setResultsIndex] = useState<number>(0);
  const [resultsTotal, setResultsTotal] = useState<number>(0);
  const [showInviteModal, setShowInviteModal] = useState<boolean>(false);
  const [is401Error, setIs401Error] = useState<boolean>(false);

  const paginationQs = qs.stringify(
    {
      start: resultsIndex,
      limit: 10,
    },
    {
      encodeValuesOnly: true,
    },
  );
  // get Users Data
  const usersFetch = useCallback(
    async (qsParams: Array<string> = []): Promise<Array<Record<string, unknown>>> => {
      let users = [];
      let qsString = '';
      if (qsParams.length > 0) {
        qsString = qsParams.join('&');
      }
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users?${qsString}`, {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        });
        users = await response.json();

        const { error } = users;
        if (error?.message) {
          throw error;
        }
        return users;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.error('Error while fetching users:', error);
        if (error?.status === 401) {
          setIs401Error(true);
        }
        throw error;
      }
      return users;
    },
    [jwt],
  );

  const getUsersTotal = useCallback(async () => {
    try {
      const countResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/count`, {
        headers: {
          Authorization: `bearer ${jwt}`,
        },
      });
      // eslint-disable-next-line no-console
      const count = await countResponse.json();
      setResultsTotal(parseInt(count, 10));
      return count;
    } catch (error) {
      console.error(error);
      return 0;
    }
  }, [jwt]);

  const getUsers = useCallback(
    async (queryParams: Array<string> = [], append = false) => {
      try {
        const fetchedUsers = await usersFetch(queryParams);
        if (!append) {
          setCurrentUsersList(parseUsers(fetchedUsers));
        } else {
          setCurrentUsersList(prevUsers => [...prevUsers, ...parseUsers(fetchedUsers)]);
        }
      } catch (error) {
        console.error(error);
      }
    },
    [usersFetch],
  );

  const handleSearch = (searchQuery: string) => {
    getUsers([`filters[email][$contains]=${searchQuery}&sort[0]=${selectedSortingField}:${sortOrder}`]);
  };

  const handleInviteModalClose = () => {
    if (showInviteModal) {
      setShowInviteModal(false);
    }
  };

  const handleChangeSorting = (field: string) => {
    if (selectedSortingField !== field) {
      setSortOrder('desc');
      setSelectedSortingField(field);
    }
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const getMoreResults = () => {
    getUsers(
      [`filters[email][$contains]=${emailFilter}&sort[0]=${selectedSortingField}:${sortOrder}`, paginationQs],
      true,
    );
  };
  useEffect(() => {
    getUsers([
      `filters[email][$contains]=${emailFilter}&sort[0]=${selectedSortingField}:${sortOrder}`,
      qs.stringify(
        {
          start: 0,
          limit: 10,
        },
        {
          encodeValuesOnly: true,
        },
      ),
    ]);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
  }, [emailFilter, getUsers, selectedSortingField, sortOrder]);

  useEffect(() => {
    getUsersTotal();
  }, [getUsersTotal]);

  useEffect(() => {
    if (currentUsersList) {
      setResultsIndex(currentUsersList.length);
    }
  }, [currentUsersList]);

  return (
    <Handle401Error isActive={is401Error} user={userInfo}>
      <Components.Container>
        <Components.Header>
          <Components.HeaderLeftSide>
            <BackArrow />
            <Components.Title>Users</Components.Title>
            <Components.Description>
              Here you can manage all users and send invites to create new accounts.
            </Components.Description>
          </Components.HeaderLeftSide>
          <Components.HeaderRightSide>
            <Components.StyledButton
              onClick={() => {
                if (!showInviteModal) {
                  setShowInviteModal(true);
                }
              }}
            >
              INVITE USER
            </Components.StyledButton>
            <Components.SearchInput
              placeholder="Search Emails"
              type="text"
              value={emailFilter}
              onChange={e => setEmailFilter(e.target.value)}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') {
                  handleSearch(emailFilter);
                  e.currentTarget.blur();
                }
              }}
            />
          </Components.HeaderRightSide>
        </Components.Header>
        <Components.TableContainer>
          <StickyTable className="sticky-wrapper" height={theme.getValue(150)}>
            <InfiniteScroll
              dataLength={currentUsersList.length}
              next={() => {
                getMoreResults();
              }}
              scrollableTarget="sticky-wrapper-div"
              hasMore={!!(resultsIndex < resultsTotal)}
              loader={<p className="pagination__loading-indicator">Loading...</p>}
              endMessage={
                <p style={{ textAlign: 'center' }}>
                  <b>End of results</b>
                </p>
              }
            >
              <Components.StyledTable className="users-table">
                <Components.TableHeader>
                  <Components.HeaderRow>
                    <Components.HeaderColumn
                      onClick={() => {
                        handleChangeSorting('firstName');
                      }}
                      width={columnWidths[0]}
                    >
                      Name
                      <Components.SortArrow
                        src={SortArrowImg}
                        direction={selectedSortingField === 'firstName' ? sortOrder : 'desc'}
                      />
                    </Components.HeaderColumn>
                    <Components.HeaderColumn
                      onClick={() => {
                        handleChangeSorting('email');
                      }}
                      width={columnWidths[1]}
                    >
                      Email
                      <Components.SortArrow
                        src={SortArrowImg}
                        direction={selectedSortingField === 'email' ? sortOrder : 'desc'}
                      />
                    </Components.HeaderColumn>
                    <Components.HeaderColumn
                      onClick={() => {
                        handleChangeSorting('createdAt');
                      }}
                      width={columnWidths[2]}
                    >
                      Date Added
                      <Components.SortArrow
                        src={SortArrowImg}
                        direction={selectedSortingField === 'createdAt' ? sortOrder : 'desc'}
                      />
                    </Components.HeaderColumn>
                    <Components.HeaderColumn width={columnWidths[3]} />
                  </Components.HeaderRow>
                </Components.TableHeader>
                <Components.TableBody>
                  {currentUsersList?.map(u => (
                    <Components.BodyRow key={u.email}>
                      <Components.BodyColumn>{u.name}</Components.BodyColumn>
                      <Components.BodyColumn>{u.email}</Components.BodyColumn>
                      <Components.BodyColumn>{u.dateAdded}</Components.BodyColumn>
                      <Components.BodyColumn>
                        <Components.TableLink to={`/users/user/${u.id}`}>Edit</Components.TableLink>
                      </Components.BodyColumn>
                    </Components.BodyRow>
                  ))}
                </Components.TableBody>
              </Components.StyledTable>
            </InfiniteScroll>
          </StickyTable>
        </Components.TableContainer>
      </Components.Container>
      <InviteUserModal open={showInviteModal} setModalOpenState={setShowInviteModal} onClose={handleInviteModalClose} />
    </Handle401Error>
  );
}
