import * as yup from 'yup';
import { REQUIRED_VALIDATION_MSG } from '../../constants';

export const Step4Schema = yup.object({
  businessEntity: yup.string().required(REQUIRED_VALIDATION_MSG),
  accountNumber: yup
    .string()
    .transform(value => value.replace(' ', ''))
    .required(REQUIRED_VALIDATION_MSG)
    .length(9, 'Account number must be 9 digits'),
  firstName: yup.string().when('businessEntity', {
    is: 'Individual/Sole Proprietor',
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
    otherwise: yup.string(),
  }),
  lastName: yup.string().when('businessEntity', {
    is: 'Individual/Sole Proprietor',
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
    otherwise: yup.string(),
  }),
  taxClassification: yup.string().when('businessEntity', {
    is: 'Limited Liability Company (LLC)',
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
    otherwise: yup.string(),
  }),
  resident: yup.string().required(REQUIRED_VALIDATION_MSG),
});

export type BusinessEntityType = yup.InferType<typeof Step4Schema>;

export default BusinessEntityType;
