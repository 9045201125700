import FooterComponents from './styles';

export default function AdminFooter() {
  return (
    <FooterComponents.AdminFooter>
      <FooterComponents.LeftContainer>
        <p>
          Have questions or experiencing technical issues? Please reach out to{' '}
          <a href="mailto:support@ampagency.com">support@ampagency.com</a>.
        </p>
      </FooterComponents.LeftContainer>
      <FooterComponents.RightContainer>
        <>
          <p>15310 Barranca Parkway, Suite 100, Irvine, CA 92618</p>
          <p>{new Date().getFullYear()} Advantage Solutions. All Rights Reserved</p>
        </>
      </FooterComponents.RightContainer>
    </FooterComponents.AdminFooter>
  );
}
