import Components from './styles';

export default function DeviceMessage() {
  return (
    <Components.Container>
      <Components.Message>
        this device size is not supported. Please open this link on a desktop computer.
      </Components.Message>
    </Components.Container>
  );
}
