import Page from '../../../components/Page';
import Components from './styles';
import Logo from '../../../assets/images/advantage-logo.svg';

function WallScreen() {
  return (
    <Page showHeader={false}>
      <Components.Wrapper>
        <Components.Container>
          <Components.Banner>
            <Components.Logo>
              <img src={Logo} alt="advantage solutions" />
            </Components.Logo>
          </Components.Banner>
          <Components.Hero>
            <Components.HeadlineContainer>
              <Components.Subheadline>
                Thank you for your interest in partnering with Advantage Solutions.
              </Components.Subheadline>
            </Components.HeadlineContainer>
            <Components.Description>
              <p>
                In order to do so, you will need an invite from your Advantage contact. Please reach out to them for
                further information.
              </p>
            </Components.Description>
          </Components.Hero>
        </Components.Container>
      </Components.Wrapper>
    </Page>
  );
}

export default WallScreen;
