import styled from 'styled-components/macro';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    height: auto;
  }
  @media screen and (max-height: ${({ theme }) => `${theme.breakpoints.small}px`}) and (orientation: landscape) {
    height: auto;
  }
  @media print {
    height: auto;
    margin-bottom: 1in;
  }
  &.page--auth {
    background-color: ${({ theme }) => `${theme.colors.gray9}`};
    main {
      height: 100%;
      @media print {
        margin-top: auto;
      }
    }
  }
`;

const ContentContainer = styled.div`
  height: calc(100vh - ${({ theme }) => `${theme.getValue(80)}px`});
  position: fixed;
  top: 0;
  width: 100%;
  overflow: auto;

  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    position: static;
    height: auto;
    min-height: calc(100vh - ${({ theme }) => `${theme.getValue(68)}px`});
  }

  @media screen and (max-height: ${({ theme }) => `${theme.breakpoints.small}px`}) and (orientation: landscape) {
    position: static;
    height: auto;
    min-height: calc(100vh - ${({ theme }) => `${theme.getValue(68)}px`});
  }

  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    min-height: calc(100vh - ${({ theme }) => `${theme.getValue(96)}px`});
  }
`;

const Main = styled.main<{ hasHeader?: boolean }>`
  @media print {
    height: auto;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const TwoColLayout = styled.div`
  padding-top: ${({ theme }) => `${theme.getValue(48)}px`};
  width: ${({ theme }) => `${theme.getValue(1166)}px`};
  margin: 0 auto;
  display: grid;
  grid-template-columns: ${({ theme }) => `${theme.getValue(274)}px`} ${({ theme }) => `${theme.getValue(721)}px`};
  grid-column-gap: ${({ theme }) => `${theme.getValue(171)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(255 + 68 + 521)}px`};
    grid-template-columns: ${({ theme }) => `${theme.getValue(255)}px`} ${({ theme }) => `${theme.getValue(521)}px`};
    grid-column-gap: ${({ theme }) => `${theme.getValue(68)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    display: block;
    width: calc(100% - ${({ theme }) => `${theme.getValue(180)}px`});
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    display: block;
    padding-top: ${({ theme }) => `${theme.getValue(20)}px`};
    width: calc(100% - ${({ theme }) => `${theme.getValue(40)}px`});
  }
`;

const Sidebar = styled.aside``;

const Column = styled.div``;

export const LogoContainer = styled.div`
  height: ${({ theme }) => `${theme.getValue(88)}px`};
  width: ${({ theme }) => `${theme.getValue(88)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(38)}px`};
  svg {
    display: block;
    width: 100%;
    height: auto;
  }
`;

export const AuthContainer = styled.div`
  min-height: ${({ theme }) => `${theme.getValue(602)}px`};
  width: ${({ theme }) => `${theme.getValue(860)}px`};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(200)}px`});
  }
`;

export default {
  PageContainer,
  Main,
  ContentWrapper,
  Sidebar,
  Column,
  TwoColLayout,
  LogoContainer,
  AuthContainer,
  ContentContainer,
};
