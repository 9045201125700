import * as yup from 'yup';
import YupPassword from 'yup-password';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';

YupPassword(yup);

export const SignUpFormSchema = yup.object({
  firstName: yup.string().required(REQUIRED_VALIDATION_MSG),
  lastName: yup.string().required(REQUIRED_VALIDATION_MSG),
  email: yup
    .string()
    .email(EMAIL_VALIDATION_MSG)
    .required(REQUIRED_VALIDATION_MSG)
    .matches(
      /^[A-Za-z0-9.]+@advantagesolutions\.net$/gm,
      'Only users with an @advantagesolutions.net email address can be invited to this platform.',
    ),
  password: yup.string().password().minSymbols(0).required(REQUIRED_VALIDATION_MSG),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match!")
    .required(REQUIRED_VALIDATION_MSG),
});

type SignUpFormSchemaType = yup.InferType<typeof SignUpFormSchema>;

export default SignUpFormSchemaType;
