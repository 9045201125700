import styled from 'styled-components/macro';

const Container = styled.div``;
const Label = styled.label`
  display: inline-flex;
  align-items: center;
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  &:hover {
    cursor: pointer;
  }
`;
const Checkbox = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;
const ToggleContainer = styled.div`
  width: ${({ theme }) => `${theme.getValue(44)}px`};
  height: ${({ theme }) => `${theme.getValue(24)}px`};
  border-radius: ${({ theme }) => `${theme.getValue(20)}px`};
  position: relative;
  background-color: ${({ theme }) => `${theme.colors.gray5}`};
  transition: background-color 0.3s ease-out;
  margin-left: ${({ theme }) => `${theme.getValue(10)}px`};
  &:before {
    content: '';
    height: ${({ theme }) => `${theme.getValue(20)}px`};
    width: ${({ theme }) => `${theme.getValue(20)}px`};
    border-radius: 50%;
    position: absolute;
    left: ${({ theme }) => `${theme.getValue(2)}px`};
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => `${theme.colors.white}`};
    transition: left 0.3s ease-out;
  }
  ${Checkbox}:checked + & {
    background-color: ${({ theme }) => `${theme.colors.primaryBlue}`};
    &:before {
      /* left: calc(100% - ${({ theme }) => `${theme.getValue(22)}px`});
       */
      left: 52%;
    }
  }
`;

export default { Container, Label, Checkbox, ToggleContainer };
