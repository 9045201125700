/* eslint-disable react/jsx-props-no-spreading */
import { Container, Select as StyledSelect, SelectLabel as Label, Error, Icon } from './styles';
import DropdownType from './types';
import arrow from './arrow.svg';

type DropdownBoxType = DropdownType & React.InputHTMLAttributes<HTMLSelectElement>;

export default function Input(props: DropdownBoxType) {
  const { label, error, touched, className, variant, disabled, value, ...rest } = props;
  return (
    <Container variant={variant} className={className}>
      {label ? <Label className={disabled && !value ? 'is-moved' : ''}>{label}</Label> : null}
      <div style={{ position: 'relative' }}>
        <StyledSelect disabled={disabled} value={value} {...rest} />
        <Icon src={arrow} />
      </div>
      {!disabled && touched && error && <Error>{error}</Error>}
    </Container>
  );
}

Input.defaultProps = {
  variant: 'primary',
};
