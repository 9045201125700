import * as yup from 'yup';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';
import { CustomPopUpPropsType } from '../../IVPPopup/types';

export const inviteUserModalSchema = yup.object({
  email: yup
    .string()
    .email(EMAIL_VALIDATION_MSG)
    .required(REQUIRED_VALIDATION_MSG)
    .matches(
      /^[A-Za-z0-9.]+@advantagesolutions\.net$/gm,
      'Only users with an @advantagesolutions.net email address can be invited to this platform.',
    ),
});

type InviteUserModalFormType = yup.InferType<typeof inviteUserModalSchema>;

export type InviteUserModalProps = Omit<CustomPopUpPropsType, 'children'>;

export type InviteType = {
  email: string;
  inviteToken: string;
  active: boolean;
  sender: string;
};

export default InviteUserModalFormType;
