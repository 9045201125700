export const LETTERS_ONLY_REGEXP = /^([A-Za-z\u00C0-\u00D6\u00D8-\u00f6\u00f8-\u00ff\s]*)$/gi;
export const LETTERS_ONLY_VALIDATION_MSG = 'Please use letters only (a-z)';

export const NUMBERS_ONLY_REGEXP = /^[0-9]+$/;
export const NUMBERS_ONLY_VALIDATION_MSG = 'Please enter numerical values only';

export const REQUIRED_VALIDATION_MSG = 'This field is required';

export const EMAIL_VALIDATION_MSG = 'Please enter a valid email address';
export const PHONE_VALIDATION_MSG = 'Please enter a valid phone number';
export const TOLL_VALIDATION_MSG = 'Please enter a valid toll free phone number';

export const TOLL_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /^(\+?1)?-\((8(00|33|44|55|66|77|88)\) \d{3}-\d{4})$/gim;

export const URL_REGEXP =
  // eslint-disable-next-line no-useless-escape
  /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gim;

export const PAYMENT_TERMS = ['net 30', 'net 45', 'net 90', 'other'];
