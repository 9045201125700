export default [
  {
    title: 'Corporation',
  },
  {
    title: 'Limited Liability Company (LLC)',
  },
  {
    title: 'Partnership',
  },
  {
    title: 'Individual/Sole Proprietor',
  },
  {
    title: 'Estate or Trust',
  },
];
