import styled from 'styled-components/macro';

const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => `${theme.getValue(30)}px`};
`;

const ButtonWrapperRow = styled.div`
  display: flex;
  column-gap: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export default {
  ButtonWrapper,
  ButtonWrapperRow,
};
