import * as Yup from 'yup';
import { EMAIL_VALIDATION_MSG, REQUIRED_VALIDATION_MSG } from '../../../constants';

export const LoginSchema = Yup.object({
  identifier: Yup.string().email(EMAIL_VALIDATION_MSG).required(REQUIRED_VALIDATION_MSG),
  password: Yup.string().required(REQUIRED_VALIDATION_MSG),
});

export type LoginSchemaType = Yup.InferType<typeof LoginSchema>;

export default LoginSchema;
