import styled from 'styled-components/macro';
import Button from '../../Button';
import Input from '../../Input';

const StyledButton = styled(Button)``;
export const StyledInput = styled(Input)`
  min-width: 0;
`;

const Container = styled.div`
  display: flex;
  width: ${({ theme }) => `${theme.getValue(290)}px`};
  flex-direction: column;
  gap: ${({ theme }) => `${theme.getValue(8)}px`};
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
  align-items: center;
  gap: ${({ theme }) => `${theme.getValue(20)}px`};
  ${StyledButton} {
    width: ${({ theme }) => `${theme.getValue(130)}px`};
  }
`;

const StyledLink = styled.a`
  color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: 1;
  text-align: center;
  padding: 0;
  margin: 0;
  background-color: transparent;
  border: 0;
  &:hover {
    cursor: pointer;
  }
`;

export default { Container, ButtonContainer, StyledButton, StyledLink, StyledInput };
