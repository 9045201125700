import { CustomPopUpPropsType } from './types';
import Components from './styles';

export default function IVPPopup(props: CustomPopUpPropsType) {
  const { children, centered, title, ...rest } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Components.StyledPopup {...rest}>
      <Components.Container centered={centered}>
        {title ? <Components.StyledHeadline as="h2">{title}</Components.StyledHeadline> : null}
        {children}
      </Components.Container>
    </Components.StyledPopup>
  );
}
