import styled from 'styled-components/macro';

const Container = styled.div`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  padding: ${({ theme }) => `${theme.getValue(44)}px`} ${({ theme }) => `${theme.getValue(208)}px`};
  width: 100%;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    padding: ${({ theme }) => `${theme.getValue(44)}px`} ${({ theme }) => `${theme.getValue(53)}px`};
  }
`;

const Headline = styled.h1`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-style: normal;
  font-weight: ${({ theme }) => theme.weights.bold};
  font-size: ${({ theme }) => `${theme.getValue(27)}px`};
  line-height: ${({ theme }) => `${theme.getValue(32)}px`};
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  margin-top: 0;
  margin-bottom: ${({ theme }) => `${theme.getValue(10)}px`};
`;

const Subheadline = styled.h2`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  text-align: center;
  color: ${({ theme }) => `${theme.colors.black}`};
  margin: 0;
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
`;

const Inner = styled.div`
  margin-top: ${({ theme }) => `${theme.getValue(42)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    padding: ${({ theme }) => `${theme.getValue(0)}px`} ${({ theme }) => `${theme.getValue(47)}px`};
  }
`;

export default { Container, Headline, Subheadline, Inner };
