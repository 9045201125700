/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface SessionState {
  expired: boolean;
}

const initialState: SessionState = {
  expired: false,
};

export const sessionStateSlice = createSlice({
  name: 'sessionStateSlice',
  initialState,
  reducers: {
    setSessionExpired: (state, action: PayloadAction<boolean>) => {
      state.expired = action.payload;
    },
  },
});

export const { setSessionExpired } = sessionStateSlice.actions;
export default sessionStateSlice.reducer;
