import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import Button from '../../Button';
import { Error as ErrorMessage } from '../../Input/styles';
import Components from './style';
import SignUpFormSchemaType, { SignUpFormSchema } from './types';

const registerUser = async (vals: SignUpFormSchemaType & { token: string }) => {
  const { token, email, ...rest } = vals;

  if (!token) {
    throw new Error('Invite token not found');
  }

  // Register User
  try {
    // Get invite by token
    const inviteResponse = await fetch(`${process.env.REACT_APP_API_URL}/invites?filters[inviteToken][$eq]=${token}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },
    });
    const inviteData = await inviteResponse.json();

    if (inviteData?.error?.message) {
      throw inviteData?.error;
    }
    // If no invite with sent token is found
    if (inviteData?.data?.length === 0) {
      // eslint-disable-next-line no-throw-literal
      throw { name: 'Invalid token', message: 'Invite was not found' };
    }

    const invite = inviteData?.data[0];
    const {
      id: inviteId,
      attributes: { active: isInviteActive, email: inviteEmail },
    } = invite;

    // Validate if invite still active
    if (!isInviteActive) {
      // eslint-disable-next-line no-throw-literal
      throw { name: 'Expired invite', message: 'Invite has expired' };
    }

    // Compare invite email with form email
    if (inviteEmail !== email) {
      // eslint-disable-next-line no-throw-literal
      throw { name: 'Email mismatch', message: 'Please use the invited email address' };
    }

    const bodyData = {
      ...rest,
      email,
      role: 1,
      username: email,
      confirmed: true,
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`,
      },

      body: JSON.stringify(bodyData),
    });

    const responseData = await response.json();

    if (responseData?.error?.message) {
      throw responseData?.error;
    }
    // Invalidate invite after successful register
    const invalidateInviteBody = {
      data: {
        active: false,
      },
    };

    const invitationInvalidateResponse = await fetch(`${process.env.REACT_APP_API_URL}/invites/${inviteId}`, {
      headers: {
        'content-type': 'application/json',
      },
      method: 'put',
      body: JSON.stringify(invalidateInviteBody),
    });

    const invitationInvalidateData = await invitationInvalidateResponse.json();

    if (invitationInvalidateData?.error?.message) {
      console.error(invitationInvalidateData?.error);
    }

    return responseData;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
type SignUpFormPropsType = {
  onSuccess: () => void;
};
export default function SignUpForm(props: SignUpFormPropsType) {
  const { onSuccess } = props;
  const { StyledInput: Input } = Components;
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email') || '';
  const inviteToken = searchParams.get('token') || '';
  const [loading, setLoading] = useState(false);
  const [submitError, setSubmitError] = useState('');

  const initialValues: SignUpFormSchemaType = {
    firstName: '',
    lastName: '',
    email: email || '',
    password: '',
    confirmPassword: '',
  };
  const { values, touched, errors, handleChange, setFieldValue, handleBlur, isValid, handleSubmit } = useFormik({
    validationSchema: SignUpFormSchema,
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: async (vals: SignUpFormSchemaType) => {
      setSubmitError('');
      setLoading(true);
      try {
        await registerUser({ ...vals, token: inviteToken || '', email: vals.email.toLowerCase() });
        // Run on success callback
        onSuccess();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setSubmitError(error?.message || `${error}` || 'Error');
      }
      setLoading(false);
    },
  });

  return (
    <>
      <Components.Container>
        <Input
          className="sign-up__email-field"
          id="email"
          name="email"
          value={values.email.toLowerCase()}
          touched={touched.email}
          error={errors.email}
          onChange={e => setFieldValue('email', e.target.value.toLowerCase())}
          onBlur={handleBlur}
          label="Advantage Email*"
        />
        <Input
          id="firstName"
          name="firstName"
          value={values.firstName}
          touched={touched.firstName}
          error={errors.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          label="First Name*"
        />
        <Input
          id="lastName"
          name="lastName"
          value={values.lastName}
          touched={touched.lastName}
          error={errors.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Last Name*"
        />
        <Input
          id="password"
          name="password"
          value={values.password}
          touched={touched.password}
          error={errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Password"
          type="password"
        />
        <Input
          id="confirmPassword"
          name="confirmPassword"
          value={values.confirmPassword}
          touched={touched.confirmPassword}
          error={errors.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Confirm Password*"
          type="password"
        />
      </Components.Container>
      <Components.ButtonContainer>
        <Button
          disabled={!isValid || loading}
          onClick={() => {
            handleSubmit();
          }}
        >
          Sign up
        </Button>
        {submitError ? <ErrorMessage>{submitError}</ErrorMessage> : null}
      </Components.ButtonContainer>
    </>
  );
}
