import { useDispatch, useSelector } from 'react-redux';
import { setUser, userState } from '../../../store/slices/userSlice';
import { RootState } from '../../../store/store';
import Components from './styles';

function AdminNav() {
  const storedUserInfo: userState = useSelector((state: RootState) => state.userInfo);
  const { user } = storedUserInfo;
  const roleType = user?.role?.type || null;
  const dispatch = useDispatch();
  const handleLogoutClick = () => {
    dispatch(setUser({ jwt: '', user: null }));
  };

  return (
    <Components.Nav>
      <Components.NavList>
        {roleType === 'admin' ? (
          <Components.NavItem>
            <Components.StyledNavLink to="/users/list">Users</Components.StyledNavLink>
          </Components.NavItem>
        ) : null}
        <Components.NavItem>
          <Components.StyledNavLink to="/profile">Profile</Components.StyledNavLink>
        </Components.NavItem>
        <Components.NavItem>
          <a
            href="https://docs.google.com/document/d/17UY8VtDcvU6-lZ65GmqEivdlCvP5017M9fV0J7qL09A/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            User Guide
          </a>
        </Components.NavItem>
        <Components.NavItem>
          <Components.StyledNavButton
            type="button"
            onClick={() => {
              handleLogoutClick();
            }}
          >
            Logout
          </Components.StyledNavButton>
        </Components.NavItem>
      </Components.NavList>
    </Components.Nav>
  );
}

export default AdminNav;
