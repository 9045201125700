import { formatAccountNumber } from '../../../../utils/formating';
import { PrintLabel, PrintValue, PrintTitle } from '../../../PrintTemplate/styles';
import { ReviewStep4PropsType } from './types';

export default function ReviewStep4Print(props: ReviewStep4PropsType) {
  const { fieldValues: businessEntityData } = props;
  const { accountNumber, businessEntity, firstName, lastName, resident, taxClassification } = businessEntityData;
  return (
    <>
      <PrintTitle>{businessEntity}</PrintTitle>
      {accountNumber ? (
        <>
          <PrintLabel>Federal Employee Identification Number</PrintLabel>
          <PrintValue>{formatAccountNumber(accountNumber, businessEntity)}</PrintValue>
        </>
      ) : null}
      {firstName ? (
        <>
          <PrintLabel>First Name</PrintLabel>
          <PrintValue>{firstName}</PrintValue>
        </>
      ) : null}
      {lastName ? (
        <>
          <PrintLabel>Last Name</PrintLabel>
          <PrintValue>{lastName}</PrintValue>
        </>
      ) : null}
      {taxClassification ? (
        <>
          <PrintLabel>Tax Classification</PrintLabel>
          <PrintValue>{taxClassification}</PrintValue>
        </>
      ) : null}
      {resident ? (
        <>
          <PrintLabel>Resident</PrintLabel>
          <PrintValue>{resident}</PrintValue>
        </>
      ) : null}
    </>
  );
}
