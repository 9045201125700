/**
 * Formats the account number based on the business entity.
 *
 * @param {string} accountNumber - The account number to be formatted.
 * @param {string} businessEntity - The business entity for which the account number should be formatted.
 * @return {string} The formatted account number.
 */
export const formatAccountNumber = (accountNumber: string, businessEntity: string): string => {
  if (businessEntity === 'Individual/Sole Proprietor') {
    // Format accountNumber as XXX-XX-XXXX
    // Example format: 123-45-6789
    return accountNumber.replace(/(\d{3})(\d{2})(\d{4})/, '$1-$2-$3');
  }
  // Format accountNumber as XX-XXXXXXX
  // Example format: 12-3456789
  return accountNumber.replace(/(\d{2})(\d{7})/, '$1-$2');
};

export default { formatAccountNumber };
