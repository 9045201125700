import { ReviewStep6PropsType } from './types';
import { PrintLabel, PrintValue, PrintTitle } from '../../../PrintTemplate/styles';

export default function ReviewStep6(props: ReviewStep6PropsType) {
  const { fieldValues: irsData } = props;
  const { withholding, title, phoneNumber, date } = irsData;
  const categoryText = withholding.split('|');
  return (
    <>
      <PrintTitle>{categoryText[0]}</PrintTitle>
      {categoryText[1] ? <PrintLabel>{categoryText[1]}</PrintLabel> : null}
      {date ? (
        <>
          <PrintLabel>Date</PrintLabel>
          <PrintValue>{date}</PrintValue>
        </>
      ) : null}
      {title ? (
        <>
          <PrintLabel>Title</PrintLabel>
          <PrintValue>{title}</PrintValue>
        </>
      ) : null}
      {phoneNumber ? (
        <>
          <PrintLabel>Phone Number</PrintLabel>
          <PrintValue>{phoneNumber}</PrintValue>
        </>
      ) : null}
      {/* {signature ? <SignedDocument link={signature} /> : null} */}
    </>
  );
}
