import styled from 'styled-components/macro';
import Button from '../../../components/Button';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ theme }) => `${theme.getValue(1165)}px`};
  margin: 0 auto;
  flex-wrap: wrap;
  margin-top: ${({ theme }) => `${theme.getValue(36)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(860)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(140)}px`});
  }
  @media print {
    display: block;
    width: 100%;
  }
`;

const DownloadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Title = styled.h1`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(22)}px`};
  line-height: 1.1818181818;
  text-transform: capitalize;
  color: ${({ theme }) => `${theme.colors.black}`};
  width: 100%;
  text-align: center;
  flex: 1 1 auto;
  margin-bottom: ${({ theme }) => `${theme.getValue(50)}px`};
  @media print {
    margin-bottom: ${({ theme }) => `${theme.getValue(16)}px`};
  }
`;

const Description = styled.p`
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: ${({ theme }) => `${theme.getValue(21)}px`};
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  margin: ${({ theme }) => `${theme.getValue(4)}px 0 0`};
`;

const DownloadButton = styled(Button)`
  @media print {
    display: none;
  }
`;

const PrintWrapper = styled.div`
  width: 550px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
`;

export default { Container, Title, PrintWrapper, Description, DownloadContainer, DownloadButton };
