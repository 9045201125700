import { useNavigate, useLocation } from 'react-router-dom';
import Page from '../../../components/Page';
import Components from './styles';
import Logo from '../../../assets/images/advantage-logo.svg';
import Button from '../../../components/Button';

export default function WelcomeScreen() {
  const navigate = useNavigate();
  const { search } = useLocation();
  return (
    <Page showHeader={false}>
      <Components.Wrapper>
        <Components.Container>
          <Components.Banner>
            <Components.Logo>
              <img src={Logo} alt="advantage solutions" />
            </Components.Logo>
          </Components.Banner>
          <Components.Hero>
            <Components.HeadlineContainer>
              <Components.Title>Hello!</Components.Title>
              <Components.Subheadline>Thank you for partnering with Advantage Solutions!</Components.Subheadline>
            </Components.HeadlineContainer>
            <Components.Description>
              <p>
                The purpose of this form is to gather information required for tax and payment purposes. All requested
                information is legally required in order to work with Advantage Solutions and will be used in lieu of
                the of IRS Form W-9.
              </p>
              <p>
                While it will require multiple steps, you will be guided throughout the process. You must complete the
                form in its entirety for it to be submitted and considered complete.{' '}
                <strong>
                  All information must match your W-9 – please double check all entries as any errors may impact the
                  invoicing/payment process.
                </strong>
              </p>
              <p>
                If you have questions, please reach out to your Advantage contact. We look forward to working with you!
              </p>
              <Button
                onClick={() => {
                  navigate(`/form${search}`);
                }}
              >
                Start
              </Button>
            </Components.Description>
          </Components.Hero>
        </Components.Container>
      </Components.Wrapper>
    </Page>
  );
}
