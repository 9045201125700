import businessClassificationCategories from '../../../content/Step8/content/businessClassificationCategories';
import sustainabilityPolicies from '../../../content/Step5/data/sustainabilityPolicies';

const filterOptions = [
  {
    label: 'Filter By',
    value: '',
  },
  {
    label: 'Business Classification',
    value: 'businessClassificationCategories',
    options: businessClassificationCategories.map(cat => ({
      label: cat.title,
      value: cat.title,
      field: '[businessClassification][businessClassificationCategories][title]',
    })),
  },
  {
    label: 'Sustainability Policies',
    value: 'sustainabilityPolicies',
    options: sustainabilityPolicies.map(cat => ({
      label: cat.title,
      value: cat.id,
      field: `[sustainabilityPolicy][${cat.id}]`,
    })),
  },
  {
    label: 'Sustainability Responsibility',
    value: 'sustainabilityResponsibility',
    options: [
      { label: 'Yes', value: 'yes', field: '[sustainabilityPolicy][sustainabilityResponsibility]' },
      { label: 'No', value: 'no', field: '[sustainabilityPolicy][sustainabilityResponsibility]' },
    ],
  },
  {
    label: 'Sustainability Impacts',
    value: 'Sustainability Impacts',
    options: [
      { label: 'Yes', value: 'yes', field: '[sustainabilityPolicy][impactsIdentifiedList]' },
      { label: 'No', value: 'no', field: '[sustainabilityPolicy][impactsIdentifiedList]' },
    ],
  },
  {
    label: 'Environment Management Standards',
    value: 'Environment Management Standards',
    options: [
      { label: 'Yes', value: 'yes', field: '[sustainabilityPolicy][managementStandardsDetails]' },
      { label: 'No', value: 'no', field: '[sustainabilityPolicy][managementStandardsDetails]' },
    ],
  },
];

export default filterOptions;
