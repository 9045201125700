import React, { useEffect, useRef } from 'react';
import StickyTableHeaderProps from './types';

export default function StickyTableHeader({
  className = '',
  height = 450,
  hiddenVerticalScrollbar = false,
  hiddenHorizontalScrollbar = false,
  children,
}: StickyTableHeaderProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const el = tableContainerRef.current;
    function handleScroll() {
      if (el) {
        const thead = el.querySelector('thead');
        if (thead) {
          const translate = `translate(0, ${el.scrollTop}px)`;
          thead.style.transform = translate;
        }
      }
    }
    if (el) {
      el.addEventListener('scroll', handleScroll);
      return () => {
        el.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <div
      style={{
        overflow: 'hidden',
        height: hiddenHorizontalScrollbar ? height - 15 : '',
      }}
    >
      <div
        id="sticky-wrapper-div"
        ref={tableContainerRef}
        className={className}
        style={{
          overflow: 'auto',
          height,
          width: hiddenVerticalScrollbar ? 'calc(100% + 15px)' : '100%',
        }}
      >
        {children}
      </div>
    </div>
  );
}
