import { HTMLElement } from 'react';
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface StepState {
  currentStep: number;
  scrollableRef: HTMLElement | null;
}

const initialState: StepState = {
  currentStep: 0,
  scrollableRef: null,
};

export const stepSlice = createSlice({
  name: 'stepSlice',
  initialState,
  reducers: {
    increment: state => {
      state.currentStep += 1;
    },
    decrement: state => {
      state.currentStep -= 1;
    },
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setScrollableRef: (state, action) => {
      state.scrollableRef = action.payload;
    },
  },
});

export const { increment, decrement, setCurrentStep, setScrollableRef } = stepSlice.actions;
export default stepSlice.reducer;
