import * as yup from 'yup';
import YupPassword from 'yup-password';

YupPassword(yup);

export const ResetPasswordSchema = yup.object({
  password: yup.string().password().minSymbols(0).required('Please enter your new password'),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], "Passwords don't match!")
    .required('Please enter your new password'),
});

export type ResetPasswordFormPropsType = {
  setShowSuccess: React.Dispatch<React.SetStateAction<boolean>>;
};

export type ResetPasswordFieldsType = yup.InferType<typeof ResetPasswordSchema>;
