import Button from '../../Button';
import IVPPopup from '../../IVPPopup';
import { CustomPopUpPropsType } from '../../IVPPopup/types';
import Components from './styles';

type SendConfirmationModalPropsType = Omit<CustomPopUpPropsType, 'children'>;
export default function SendConfirmationModal(props: SendConfirmationModalPropsType) {
  const { setModalOpenState } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IVPPopup title="Sent!" centered {...props}>
      <Components.Container>
        <p>
          The link to complete the New Vendor Form has been sent to the vendor. You will receive an email notification
          once your vendor has submitted the form.
        </p>
      </Components.Container>
      <Components.ButtonContainer>
        <Button
          onClick={() => {
            setModalOpenState?.(false);
          }}
        >
          OK
        </Button>
      </Components.ButtonContainer>
    </IVPPopup>
  );
}
