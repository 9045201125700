import styled from 'styled-components/macro';
import Headline from '../../../components/typography/Headline';

const PageHeader = styled.div`
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(140)}px`});
    margin: 0 auto;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(40)}px`});
  }
`;

const Title = styled(Headline)`
  margin: 0;
  margin-top: ${({ theme }) => `${theme.getValue(48)}px`};
  margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
  text-align: center;

  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    margin-top: ${({ theme }) => `${theme.getValue(25)}px`};
  }
`;

const Subtext = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 1.5;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  margin: 0;
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  letter-spacing: -0.015em;
  margin-bottom: ${({ theme }) => `${theme.getValue(28)}px`};
  max-width: ${({ theme }) => `${theme.getValue(860)}px`};
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    font-size: ${({ theme }) => `${theme.getValue(16)}px`};
    line-height: ${({ theme }) => `${theme.getValue(22)}px`};
    margin-bottom: ${({ theme }) => `${theme.getValue(18)}px`};
  }
`;

export default { Title, Subtext, PageHeader };
