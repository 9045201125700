import FooterComponents from './styles';

export default function Footer() {
  return (
    <FooterComponents.Footer>
      <FooterComponents.LeftContainer>
        <p>Have questions or experiencing technical issues? Please reach out to your Advantage contact.</p>
      </FooterComponents.LeftContainer>
      <FooterComponents.RightContainer>
        <>
          <p>15310 Barranca Parkway, Suite 100, Irvine, CA 92618</p>
          <p>{new Date().getFullYear()} Advantage Solutions. All Rights Reserved</p>
        </>
      </FooterComponents.RightContainer>
    </FooterComponents.Footer>
  );
}
