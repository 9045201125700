import * as yup from 'yup';
import { REQUIRED_VALIDATION_MSG } from '../../constants';
import { fileArraySchema } from '../../types';

export const Step5Schema = yup.object({
  hasSustainanbilityPolicy: yup.bool(),
  sustainabilityPolicyFiles: yup.array().when('hasSustainanbilityPolicy', {
    is: true,
    then: fileArraySchema,
    otherwise: yup.array(),
  }),
  hasEnvironmentPolicy: yup.bool(),
  environmentPolicyFiles: yup.array().when('hasEnvironmentPolicy', {
    is: true,
    then: fileArraySchema,
    otherwise: yup.array(),
  }),
  hasSocialResponsibilityPolicy: yup.bool().required(REQUIRED_VALIDATION_MSG),
  socialResponsibilityFiles: yup.array().when('hasSocialResponsibilityPolicy', {
    is: true,
    then: fileArraySchema,
    otherwise: yup.array(),
  }),
  hasSustainabilityProcurementPolicy: yup.bool().required(REQUIRED_VALIDATION_MSG),
  sustainabilityProcurementFiles: yup.array().when('hasSustainabilityProcurementPolicy', {
    is: true,
    then: fileArraySchema,
    otherwise: yup.array(),
  }),
  hasNonePolicies: yup.bool(),
  hasSelectedPolicies: yup
    .bool()
    .oneOf([true], 'Please select at least one policy option')
    .required(REQUIRED_VALIDATION_MSG)
    .default(false),
  hasSustainabilityResponsibility: yup.string().required(REQUIRED_VALIDATION_MSG),
  sustainabilityResponsibility: yup.string().when('hasSustainabilityResponsibility', {
    is: 'Yes',
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
    otherwise: yup.string(),
  }),
  hasIdentifiedImpacts: yup.string().required(REQUIRED_VALIDATION_MSG),
  impactsIdentifiedList: yup.string().when('hasIdentifiedImpacts', {
    is: 'Yes',
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
    otherwise: yup.string(),
  }),
  hasEnvironmentalManagementStandards: yup.string().required(REQUIRED_VALIDATION_MSG),
  managementStandardsDetails: yup.string().when('hasEnvironmentalManagementStandards', {
    is: 'Yes',
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
    otherwise: yup.string(),
  }),
});

export type SustainabilityPolicyType = yup.InferType<typeof Step5Schema>;

export default SustainabilityPolicyType;
