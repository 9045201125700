import styled from 'styled-components/macro';

export const Description = styled.div`
  p {
    font-size: ${({ theme }) => `${theme.getValue(12)}px`};
    font-family: ${({ theme }) => `${theme.fonts.primary}`};
    line-height: 150%;
    color: ${({ theme }) => `${theme.colors.gray2}`};
  }
`;

export const Container = styled.div`
  & + & {
    margin-top: ${({ theme }) => `${theme.getValue(24)}px`};
  }
`;

export default { Container, Description };
