import styled from 'styled-components/macro';
import IBtn from './types';

const Button = styled.button<IBtn>`
  background-color: ${({ theme, variant }) =>
    variant === 'secondary' ? 'transparent' : `${theme.colors.primaryBlue}`};
  color: ${({ theme, variant }) => (variant === 'secondary' ? `${theme.colors.primaryBlue}` : `${theme.colors.white}`)};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  border: ${({ theme, variant }) => (variant === 'secondary' ? `solid 1px ${theme.colors.primaryBlue}` : 0)};
  border-radius: ${({ theme }) => `${theme.getValue(6)}px`};
  text-align: center;
  min-width: ${({ theme }) => `${theme.getValue(130)}px`};
  width: ${({ size }) => (size === 'wide' ? '100%' : 'auto')};
  height: ${({ theme }) => `${theme.getValue(52)}px`};
  padding-left: ${({ theme }) => `${theme.getValue(33.5)}px`};
  padding-right: ${({ theme }) => `${theme.getValue(33.5)}px`};
  padding-top: ${({ theme }) => `${theme.getValue(18)}px`};
  padding-bottom: ${({ theme }) => `${theme.getValue(18)}px`};
  text-transform: uppercase;
  &:disabled {
    background-color: ${({ theme }) => `${theme.colors.primaryBlueDisabled}`};
  }
  &:hover {
    cursor: pointer;
  }
`;

export default Button;
