import { ReviewGridInput as Input, SectionTitle as Title } from '../../ReviewGridStep/styles';
import Components from './styles';
import { ReviewStep6PropsType } from './types';
import SignedDocument from '../../../../components/SignedDocument';
import { formatDate } from '../../../../utils';

export default function ReviewStep6(props: ReviewStep6PropsType) {
  const { fieldValues: irsData } = props;
  const { withholding, signature, title, phoneNumber, date } = irsData;
  const categoryText = withholding.split('|');
  return (
    <>
      <Title>{categoryText[0]}</Title>
      {categoryText[1] ? <Components.Description>{categoryText[1]}</Components.Description> : null}
      {date ? <Input disabled label="Date" value={formatDate(new Date(date))} /> : null}
      {title ? <Input disabled label="Title" value={title} /> : null}
      {phoneNumber ? <Input disabled label="Phone Number" value={phoneNumber} /> : null}
      {signature ? <SignedDocument link={signature} /> : null}
    </>
  );
}
