import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useFormik } from 'formik';
import useUserInfo from '../../../hooks/useUserInfo';
import Button from '../../Button';
import IVPPopup from '../../IVPPopup';
import { CustomPopUpPropsType } from '../../IVPPopup/types';
import Components from './styles';
import { Error } from '../../Input/styles';
import InviteUserModalFormType, { InviteType, inviteUserModalSchema as validationSchema } from './types';

type InviteUserModalPropsType = Omit<CustomPopUpPropsType, 'children'>;

export default function InviteUserModal(props: InviteUserModalPropsType) {
  const userInfo = useUserInfo();
  const { user, jwt } = userInfo;
  const [loading, setLoading] = useState(false);
  const [errorMsg, setError] = useState('');
  const [sucessfulSubmit, setSucessfulSubmit] = useState(false);
  const { setModalOpenState } = props;

  const createInvite = async (inviteData: Partial<InviteType>) => {
    const data = {
      data: {
        ...inviteData,
        active: true,
      },
    };

    const response = await fetch(`${process.env.REACT_APP_API_URL}/invites`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Authorization: `Bearer ${jwt}`,
      },
      body: JSON.stringify(data),
    });
    const parsedResponse = await response.json();
    return parsedResponse;
  };

  const inviteUserFormInitialVals: InviteUserModalFormType = {
    email: '',
  };

  const { values, touched, errors, isValid, handleBlur, setFieldValue, handleSubmit, resetForm } = useFormik({
    validationSchema,
    validateOnMount: true,
    initialValues: inviteUserFormInitialVals,
    onSubmit: async vals => {
      // Get email from form
      const { email } = vals;

      // Create unique token
      const inviteToken = uuidv4();

      // Create invite
      try {
        setSucessfulSubmit(false);
        setError('');
        setLoading(true);
        const invite = await createInvite({ email: email.toLowerCase(), inviteToken, sender: user?.email });
        setLoading(false);
        // Show invite creation confirmation
        if (invite?.data) {
          setSucessfulSubmit(true);
        } else if (invite?.error) {
          throw invite?.error;
        }
      } catch (error) {
        console.error(error);
        setError(`Something went wrong, please try again later or contact support`);
        setLoading(false);
      }
    },
    enableReinitialize: true,
  });

  return (
    <IVPPopup
      title="Invite User"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <Components.Container>
        {sucessfulSubmit ? (
          <p>Invite was sent</p>
        ) : (
          <>
            <p>
              Please enter the user’s Advantage email where we will send a link to set up their account information.
            </p>
            <Components.StyledInput
              id="email"
              name="email"
              value={values.email.toLowerCase()}
              touched={touched.email}
              error={errors.email}
              label="Advantage Email*"
              onChange={e => setFieldValue('email', e.target.value.toLowerCase())}
              onBlur={handleBlur}
            />
          </>
        )}
        <Components.ButtonContainer>
          {sucessfulSubmit ? (
            <Button
              variant="secondary"
              onClick={() => {
                setLoading(false);
                setError('');
                setSucessfulSubmit(false);
                setModalOpenState?.(false);
                resetForm();
              }}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setLoading(false);
                  setError('');
                  setSucessfulSubmit(false);
                  setModalOpenState?.(false);
                  resetForm();
                }}
              >
                Cancel
              </Button>
              <Button
                disabled={!isValid || loading}
                onClick={() => {
                  handleSubmit();
                }}
              >
                Send
              </Button>
            </>
          )}
        </Components.ButtonContainer>
        {errorMsg ? <Error style={{ textAlign: 'right' }}>{errorMsg}</Error> : null}
      </Components.Container>
    </IVPPopup>
  );
}
