import { useDispatch } from 'react-redux';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileScreenPropsType } from './types';
import Components from './styles';
import { setUser } from '../../../store/slices/userSlice';
import BackArrow from '../../../components/BackArrow';
import AdminPage from '../../../components/Page/AdminPage';
import Headline from '../../../components/typography/Headline';
import ProfileForm from '../../../components/admin/ProfileForm';
import ProfileFormPropsType from '../../../components/admin/ProfileForm/types';
import ChangePasswordModal from '../../../components/admin/ChangePasswordModal';
import { ChangePasswordFieldsType } from '../../../components/admin/ChangePasswordModal/types';

function ProfileScreen(props: ProfileScreenPropsType) {
  const navigate = useNavigate();
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>('');
  const dispatch = useDispatch();
  const { user: currentUserData } = props;
  const { user, jwt } = currentUserData;

  const updateUser = async (updatedInfo: ProfileFormPropsType, authToken: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${user?.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify({ ...updatedInfo }),
      });
      const responseData = await response.json();
      const { data, error } = responseData;
      if (error) throw error;
      return data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  // Use this to fetch user info globally
  const fetchUser = async (id: number | null | undefined, authToken: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      });
      const responseData = await response.json();
      const { error } = responseData;
      if (error) throw error;
      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const doSaveChanges: ProfileFormPropsType['handleSaveChanges'] = async (newInfo: ProfileFormPropsType) => {
    try {
      setErrorMsg('');
      await updateUser(newInfo, jwt);
      const userData = await fetchUser(user?.id, jwt);
      dispatch(setUser({ jwt, user: { ...user, ...userData } }));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      setErrorMsg(error.message || `${error}` || 'Something went wrong');
      throw error;
    }
  };

  const updatePassword = async (passwordInfo: ChangePasswordFieldsType, authToken: string) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/auth/change-password`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'Content-type': 'Application/json',
        },
        body: JSON.stringify({
          currentPassword: passwordInfo.currentPassword,
          password: passwordInfo.password,
          passwordConfirmation: passwordInfo.confirmPassword,
        }),
      });
      const responseData = await response.json();
      const { error } = responseData;
      if (error) throw error;
      return responseData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  const doPasswordChange = async (passInfo: ChangePasswordFieldsType) => {
    const result = await updatePassword(passInfo, jwt);
    return result;
  };

  return (
    <AdminPage>
      <Components.Container>
        <BackArrow onClick={() => navigate('/home')} />
        <Headline>Profile</Headline>
        <Components.FormWrapper>
          <ProfileForm
            handleSaveChanges={doSaveChanges}
            firstName={user?.firstName || ''}
            lastName={user?.lastName || ''}
            email={user?.email || ''}
            errorMsg={errorMsg}
            clearErrorMsg={() => setErrorMsg('')}
          />
          <Components.LinkButton
            onClick={() => {
              setShowChangePasswordModal(true);
            }}
          >
            Change password
          </Components.LinkButton>
        </Components.FormWrapper>
      </Components.Container>
      <ChangePasswordModal
        open={showChangePasswordModal}
        setModalOpenState={setShowChangePasswordModal}
        handleSaveClick={doPasswordChange}
      />
    </AdminPage>
  );
}

export default ProfileScreen;
