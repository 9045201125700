import InfiniteScroll from 'react-infinite-scroll-component';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import StickyTable from '../../StickyTableHeader';
import LandingComponents from '../Landing/styles';
import CompletedIndicator from './CompletedIndicator';
import MoreOptions from './MoreOptions';
import Styles from './styles';
import tableSubmissionType, { submissionsTablePropsType } from './types';
import SortArrowImg from './sortArrow.svg';
import Pill from '../../Pill';
import useDimensions from '../../../hooks/useDimensions';
import { RootState } from '../../../store/store';

const columnWidthsLarge: string[] = ['16%', '12%', '18%', '10%', '11%', '11%', '10%', '10%', '4%'];

const columnWidthsSmall: string[] = ['16%', '12%', '10%', '9%', '11%', '16%', '10%', '10%', '6%'];

export default function SubmissionsTable(props: submissionsTablePropsType) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const theme: any = useTheme();
  const screenDimensions = useDimensions();
  const { submissions, onResendClick, onResubmitClick, onSort, sortingValues, hasMore, next, isLoading } = props;
  const storedBusinessUnits = useSelector((state: RootState) => state.businessUnits.businessUnits);
  const Components = { ...LandingComponents, ...Styles };

  const isSmall = screenDimensions.width <= theme.breakpoints.small;
  const columnWidths = isSmall ? columnWidthsSmall : columnWidthsLarge;
  return (
    <Components.TableContainer>
      <StickyTable className="sticky-wrapper" height={theme.getValue(150)}>
        <InfiniteScroll
          dataLength={submissions.length}
          next={() => {
            next();
          }}
          scrollableTarget="sticky-wrapper-div"
          hasMore={!!hasMore}
          loader={<p className="pagination__loading-indicator">Loading...</p>}
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>End of results</b>
            </p>
          }
        >
          <Components.StyledTable className="submissions-table">
            <Components.TableHeader>
              <Components.HeaderRow>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.nameOfBusiness');
                  }}
                  width={columnWidths[0]}
                >
                  Business, Org., or Person
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.nameOfBusiness' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.contactName');
                  }}
                  width={columnWidths[1]}
                >
                  Contact Name
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.contactName' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.email');
                  }}
                  width={columnWidths[2]}
                >
                  Vendor Email
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.email' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.completed');
                  }}
                  width={columnWidths[3]}
                >
                  Status
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.completed' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.sender');
                  }}
                  width={columnWidths[4]}
                >
                  Sent By
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.sender' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn width={columnWidths[5]}>Business Unit</Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.sendDate');
                  }}
                  width={columnWidths[6]}
                >
                  Sent
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.sendDate' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('invite.completedDate');
                  }}
                  width={columnWidths[7]}
                >
                  Completed
                  <Components.SortArrow
                    src={SortArrowImg}
                    direction={sortingValues.field === 'invite.completedDate' ? sortingValues.order : 'desc'}
                  />
                </Components.HeaderColumn>
                <Components.HeaderColumn
                  onClick={() => {
                    onSort('createdAt');
                  }}
                  width={columnWidths[8]}
                />
              </Components.HeaderRow>
            </Components.TableHeader>
            <Components.TableBody>
              {submissions.length ? (
                submissions?.map((submission: tableSubmissionType) => {
                  const {
                    nameOfBusiness,
                    contactName,
                    email,
                    completed,
                    sender,
                    sendDate,
                    completedDate,
                    uuid,
                    submissionId,
                    businessUnit,
                  } = submission;
                  return (
                    <Components.BodyRow key={`${email}-${sender}-${submissionId}`}>
                      <Components.BodyColumn>{nameOfBusiness}</Components.BodyColumn>
                      <Components.BodyColumn>{contactName}</Components.BodyColumn>
                      <Components.BodyColumn>{email}</Components.BodyColumn>
                      <Components.BodyColumn>
                        <CompletedIndicator completed={completed} />
                      </Components.BodyColumn>
                      <Components.BodyColumn>{sender}</Components.BodyColumn>
                      <Components.BodyColumn>
                        <Pill>{businessUnit}</Pill>
                      </Components.BodyColumn>
                      <Components.BodyColumn>{sendDate}</Components.BodyColumn>
                      <Components.BodyColumn>{completedDate}</Components.BodyColumn>
                      <Components.BodyColumn>
                        <MoreOptions
                          showCompletedOptions={completed}
                          onResubmitClick={onResubmitClick}
                          onResendClick={() => {
                            onResendClick(submission);
                          }}
                          submissionData={{
                            email,
                            contactName: contactName || '',
                            uuid,
                            submissionId,
                            businessUnit: businessUnit || storedBusinessUnits[1],
                          }}
                        />
                      </Components.BodyColumn>
                    </Components.BodyRow>
                  );
                })
              ) : (
                <Components.BodyRow>
                  <Components.BodyColumn style={{ textAlign: 'center' }} colSpan={9}>
                    {!isLoading ? 'No results found' : 'Loading...'}
                  </Components.BodyColumn>
                </Components.BodyRow>
              )}
            </Components.TableBody>
          </Components.StyledTable>
        </InfiniteScroll>
      </StickyTable>
    </Components.TableContainer>
  );
}
