import { screenResolutions } from './utils/ui';

const fonts = {
  primary: "'Proxima Nova', sans-serif",
  secondary: "'Gotham', sans-serif",
  print: 'Helvetica, sans-serif',
};

const weights = {
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900,
};

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  blue1: ' #0090BA',
  blue2: '#0B3B60',
  blue3: '#0090BA80',
  blue4: '#003764',
  gray1: '#1F2937',
  gray2: '#4B5563',
  gray3: '#D8DFE1',
  gray4: '#D1D5DB',
  gray5: '#9FBEC7',
  gray6: '#C4C4C4',
  gray7: '#D9D9D9',
  gray8: '#E5E7EB',
  gray9: '#EFEFEF',
  gray10: '#FAFAFA',
  gray11: '#9d9d9d',
  gray12: '#253746',
  green1: '#10B981',
  orange1: '#F36E21',
  primaryBlue: '',
  primaryBlueDisabled: '',
  secondaryBlue: '',
};

const breakpoints = {
  large: screenResolutions.large.width,
  medium: screenResolutions.medium.width,
  tabletlarge: screenResolutions.tabletlarge.width,
  small: screenResolutions.small.width,
  tabletsmall: screenResolutions.tabletsmall.width,
  xsmall: screenResolutions.xsmall.width,
  xxsmall: screenResolutions.xxsmall.width,
};

colors.primaryBlue = colors.blue1;
colors.primaryBlueDisabled = colors.blue3;
colors.secondaryBlue = colors.blue2;

export default { fonts, colors, weights, breakpoints };
