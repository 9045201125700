/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface conflictOfInterestState {
  conflictOfInterest: string;
  employeesFirstName: string;
  employeesLastName: string;
  employeesRelationship: string;
  employeesPhoneNumber: string;
}

const initialState: conflictOfInterestState = {
  conflictOfInterest: '',
  employeesFirstName: '',
  employeesLastName: '',
  employeesRelationship: '',
  employeesPhoneNumber: '',
};

export const conflictOfInterestStateSlice = createSlice({
  name: 'conflictOfInterestState',
  initialState,
  reducers: {
    setConflictOfInterestState: (state, action: PayloadAction<conflictOfInterestState>) => {
      return { ...action.payload };
    },
  },
});

export const { setConflictOfInterestState } = conflictOfInterestStateSlice.actions;
export default conflictOfInterestStateSlice.reducer;
