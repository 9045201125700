/* eslint-disable no-restricted-globals */
import { useTheme } from 'styled-components';
import Page from '.';
import AdminNav from '../admin/AdminNav';
import PageType from './types';
import useDimensions from '../../hooks/useDimensions';
import DeviceMessage from '../DeviceMessage';

if (history.scrollRestoration) {
  history.scrollRestoration = 'manual';
}

export default function AdminPage(props: PageType) {
  const screenDimensions = useDimensions();
  const theme = useTheme();
  const isXsmall = screenDimensions.width < theme.breakpoints.tabletsmall;
  const { showHeader, children, sidebar, layout } = props;
  return isXsmall ? (
    <DeviceMessage />
  ) : (
    <Page sidebar={sidebar} layout={layout} showHeader={showHeader} headerContent={<AdminNav />} admin>
      {children}
    </Page>
  );
}

AdminPage.defaultProps = {
  layout: 'single',
  sidebar: null,
} as Partial<PageType>;
