/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import Components from './styles';
import { Error } from '../Input/styles';
import CheckboxUploadPropsType from './types';

import CustomCheckbox from './CustomCheckbox';
import CustomUpload from './CustomUpload';
import { FileArrayType, FileObjType } from '../../types';

function CheckboxUpload(props: CheckboxUploadPropsType & React.InputHTMLAttributes<HTMLInputElement>) {
  const {
    id,
    title,
    description,
    isChecked,
    enableUploadControl,
    uploadLabel,
    uploadInputLabel,
    saveFiles,
    filesLocation,
    filesUploaded,
    collapsible,
    isOpen,
    onAccordionBtnClick: handleAccordionBtnClick,
    touched,
    error,
    filesErrors,
    onClick,
    enableRadioTheme,
  } = props;
  const [isCurrentlyOpen, setIsCurrentlyOpen] = useState(isOpen);
  const [filesToUpload, setFilesToUpload] = useState<FileArrayType>(filesUploaded || []);
  const [newFiles, setNewFiles] = useState<FileArrayType>([]);

  const onFileFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const filesArr: File[] = Array.from(e.target.files || []);
    const fileObjsArr: FileArrayType = filesArr.map((f: File) => {
      return { file: f, name: f?.name, url: URL.createObjectURL(f) };
    });
    setNewFiles(fileObjsArr);
    setFilesToUpload([...(filesToUpload || []), ...fileObjsArr]);
    saveFiles?.(filesLocation, [...(filesToUpload || []), ...fileObjsArr]);
  };

  const removeFile = (fileToRemove: FileObjType) => {
    const prevArray: FileArrayType = (filesToUpload || []).filter((f: FileObjType) => f.url !== fileToRemove.url);
    setFilesToUpload(prevArray);
    saveFiles?.(filesLocation, prevArray);
  };

  useEffect(() => {
    if (isChecked) {
      setIsCurrentlyOpen(true);
    }
  }, [isChecked]);

  return (
    <Components.Container checked={!!isChecked} onClick={onClick}>
      <Components.Header>
        <CustomCheckbox {...props} id={id} enableRadioTheme={enableRadioTheme} />
        {title ? <Components.Title dangerouslySetInnerHTML={{ __html: title }} /> : null}
        {collapsible && !isChecked ? (
          <Components.AccordionBtn
            className={isCurrentlyOpen ? 'is-open' : ''}
            onClick={() => {
              setIsCurrentlyOpen(!isCurrentlyOpen);
              handleAccordionBtnClick?.();
            }}
          />
        ) : null}
      </Components.Header>
      <Components.Content className={collapsible && !isCurrentlyOpen ? 'is-collapsed' : ''}>
        {description ? <Components.Description dangerouslySetInnerHTML={{ __html: description || '' }} /> : null}
        {enableUploadControl ? (
          <CustomUpload
            id={id || ''}
            filesToDisplay={filesToUpload}
            newFiles={newFiles}
            onFileFieldChange={onFileFieldChange}
            removeFile={removeFile}
            uploadLabel={uploadLabel}
            uploadInputLabel={uploadInputLabel}
            error={filesErrors || ''}
          />
        ) : null}
        {touched && error ? <Error>{error}</Error> : null}
      </Components.Content>
    </Components.Container>
  );
}

CheckboxUpload.defaultProps = {
  title: 'Checkbox Title',
  description: 'Including Dentistry, Podiatry, Psychotherapy, Optometry, Chiropractic, etc.',
  isChecked: false,
  touched: false,
  collpasible: false,
  uploadLabel: 'Please upload your files*',
  uploadInputLabel: 'Click to Select Files',
  filesUploaded: [],
  filesLocation: '',
  error: '',
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveFiles: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChange: () => {},
} as Partial<CheckboxUploadPropsType>;

export default React.memo(CheckboxUpload);
