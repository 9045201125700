import React from 'react';
import { ReviewGridTextArea as TextArea } from '../../ReviewGridStep/styles';
import Components from './styles';
import { ReviewStep3PropsType } from './types';

export function ReviewStep3(props: ReviewStep3PropsType) {
  const { fieldValues: vendorActivity } = props;

  const { vendorActivity: activity, other } = vendorActivity;
  return (
    <>
      <Components.ActivityLabel>{activity}</Components.ActivityLabel>
      {other ? <TextArea label="Please specify" value={other} disabled /> : null}
    </>
  );
}

export default ReviewStep3;
