import styled from 'styled-components/macro';

const Title = styled.h1`
  text-transform: capitalize;
`;

const Wrapper = styled.div`
  width: ${({ theme }) => `${theme.getValue(860)}px`};
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(180)}px`});
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: calc(100% - ${({ theme }) => `${theme.getValue(40)}px`});
  }
`;

const Container = styled.div``;

export default { Title, Wrapper, Container };
