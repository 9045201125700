/* eslint-disable @typescript-eslint/no-explicit-any */
import * as yup from 'yup';
import 'yup-phone-lite';
import {
  EMAIL_VALIDATION_MSG,
  LETTERS_ONLY_REGEXP,
  LETTERS_ONLY_VALIDATION_MSG,
  NUMBERS_ONLY_REGEXP,
  NUMBERS_ONLY_VALIDATION_MSG,
  PHONE_VALIDATION_MSG,
  TOLL_VALIDATION_MSG,
  TOLL_REGEXP,
  REQUIRED_VALIDATION_MSG,
  URL_REGEXP,
  PAYMENT_TERMS,
} from '../../constants';

export const GBISchema = yup.object({
  nameOfBusiness: yup.string().required(REQUIRED_VALIDATION_MSG),
  dba: yup.string().optional(),
  street1: yup.string().required(REQUIRED_VALIDATION_MSG),
  street2: yup.string().optional(),
  city: yup.string().required(REQUIRED_VALIDATION_MSG).matches(LETTERS_ONLY_REGEXP, LETTERS_ONLY_VALIDATION_MSG),
  state: yup.string().required(REQUIRED_VALIDATION_MSG),
  zip: yup
    .string()
    .matches(NUMBERS_ONLY_REGEXP, NUMBERS_ONLY_VALIDATION_MSG)
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required(REQUIRED_VALIDATION_MSG),
  mailingStreet1: yup.string().required(REQUIRED_VALIDATION_MSG),
  mailingStreet2: yup.string(),
  mailingCity: yup.string().required(REQUIRED_VALIDATION_MSG).matches(LETTERS_ONLY_REGEXP, LETTERS_ONLY_VALIDATION_MSG),
  mailingState: yup.string().required(REQUIRED_VALIDATION_MSG),
  mailingZip: yup
    .string()
    .matches(NUMBERS_ONLY_REGEXP, NUMBERS_ONLY_VALIDATION_MSG)
    .min(5, 'Must be exactly 5 digits')
    .max(5, 'Must be exactly 5 digits')
    .required(REQUIRED_VALIDATION_MSG),
  phone: yup.string().phone('US', PHONE_VALIDATION_MSG).required(REQUIRED_VALIDATION_MSG),
  faxNumber: yup.string().optional().phone('US', PHONE_VALIDATION_MSG),
  tollFreeNumber: yup.string().optional().matches(TOLL_REGEXP, TOLL_VALIDATION_MSG),
  firstName: yup.string().required(REQUIRED_VALIDATION_MSG),
  lastName: yup.string().required(REQUIRED_VALIDATION_MSG),
  title: yup.string().required(REQUIRED_VALIDATION_MSG),
  email: yup.string().required(REQUIRED_VALIDATION_MSG).email(EMAIL_VALIDATION_MSG),
  webAddress: yup.string().matches(URL_REGEXP, 'Please enter a valid url'),
  stp: yup.string().required(REQUIRED_VALIDATION_MSG),
  stpOther: yup.string().when('stp', {
    is: PAYMENT_TERMS[PAYMENT_TERMS.length - 1],
    then: yup.string().required(REQUIRED_VALIDATION_MSG),
  }),
});

export type GBIType = yup.InferType<typeof GBISchema>;

// eslint-disable-next-line no-console

export type Step2FormType = {
  disableAll?: boolean;
  fieldValues: GBIType;
};

export default GBIType;
