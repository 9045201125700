/* eslint-disable react/jsx-no-useless-fragment */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components/macro';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import useDimensions from './hooks/useDimensions';
import themeObj from './theme';
import getWidth from './utils/ui';
import WelcomeScreen from './screens/vendor/WelcomeScreen';
import WallScreen from './screens/vendor/WallScreen';
import FormScreen from './screens/vendor/FormScreen';
import ReviewFormScreen from './screens/vendor/ReviewFormScreen';
import UsersScreen from './screens/admin/UsersScreen';
import { RootState } from './store/store';
import ThankYouScreen from './screens/vendor/ThankYouScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import UserScreen from './screens/admin/UserScreen';
import SignUpScreen from './screens/admin/SignUpScreen';
import LogInScreen from './screens/admin/LogInScreen';
import ResetPasswordScreen from './screens/admin/ResetPasswordScreen';
import HomeScreen from './screens/admin/HomeScreen';
import ProtectedRoute from './components/ProtectedRoute';
import UtilScreen from './screens/vendor/UtilScreen';
import ReviewScreen from './screens/admin/ReviewScreen';
import ProfileScreen from './screens/admin/ProfileScreen';
import ResourceScreen from './screens/vendor/resources/ResourceScreen';
import useSubmissionInfoWithUuid from './hooks/useSubmissionInfoWithUuid';
import useBusinessUnits from './hooks/useBusinessUnits';

function App() {
  const [isInvited, setIsInvited] = useState<boolean>(true);
  const [businessUnitFromUuid, setBusinessUnitFromUuid] = useState<string>('');
  const [searchParams] = useSearchParams();
  const formUuid = searchParams.get('uuid') || '';
  useBusinessUnits({ authToken: `${process.env.REACT_APP_API_TOKEN}` });
  const formInfo = useSubmissionInfoWithUuid(formUuid, `${process.env.REACT_APP_API_TOKEN}`);
  const screenDimensions = useDimensions();

  const getValue = useCallback(
    (value: number) => {
      return getWidth({ currentScreenDimensions: screenDimensions, value });
    },
    [screenDimensions],
  );

  const theme = useMemo((): DefaultTheme => ({ ...themeObj, getValue }), [getValue]);
  const storedUserInfo = useSelector((state: RootState) => state.userInfo);
  const { user } = storedUserInfo;
  const userRole = user?.role?.type;
  const storedBusinessUnits = useSelector((state: RootState) => state.businessUnits.businessUnits);
  useEffect(() => {
    if (formInfo.submissionFound !== null) {
      setIsInvited(formInfo.submissionFound);
    }
    if (formInfo.data) {
      setBusinessUnitFromUuid(formInfo.data.invite.businessUnit || storedBusinessUnits[1]);
    }
  }, [formInfo, storedBusinessUnits]);
  return (
    <ThemeProvider theme={theme}>
      <Routes>
        <Route
          path="/users/list"
          element={
            <ProtectedRoute isAllowed={!!user && userRole === 'admin'}>
              <UsersScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/users/user/:userId"
          element={
            <ProtectedRoute isAllowed={!!user && userRole === 'admin'}>
              <UserScreen user={storedUserInfo} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/home"
          element={
            <ProtectedRoute isAllowed={!!user}>
              {storedUserInfo ? <HomeScreen user={storedUserInfo} /> : null}
            </ProtectedRoute>
          }
        />
        <Route
          path="/review/form/:id"
          element={
            <ProtectedRoute isAllowed={!!user}>
              <ReviewScreen user={storedUserInfo} />
            </ProtectedRoute>
          }
        />
        <Route path="/" element={user ? <Navigate to="/home" /> : <Navigate to="/welcome" />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute isAllowed={!!user}>
              <ProfileScreen user={storedUserInfo} />
            </ProtectedRoute>
          }
        />
        <Route path="/restricted" element={<WallScreen />} />
        <Route
          path="/welcome"
          element={
            <ProtectedRoute isAllowed={isInvited} redirectRoute="/restricted">
              <WelcomeScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path="/form"
          element={
            <ProtectedRoute isAllowed={isInvited && !!formUuid} redirectRoute="/restricted">
              <FormScreen businessUnit={businessUnitFromUuid} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/review"
          element={
            <ProtectedRoute isAllowed={isInvited && !!formUuid} redirectRoute="/restricted">
              <ReviewFormScreen businessUnit={businessUnitFromUuid} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/thank-you"
          element={
            <ProtectedRoute isAllowed={isInvited} redirectRoute="/restricted">
              <ThankYouScreen />
            </ProtectedRoute>
          }
        />
        <Route path="/sign-up" element={<SignUpScreen />} />
        <Route path="/login" element={<LogInScreen />} />
        <Route path="/reset-password" element={<ResetPasswordScreen />} />
        <Route path="/util" element={<UtilScreen />} />
        <Route path="/resource/:number" element={<ResourceScreen />} />
        <Route path="*" element={<NotFoundScreen />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
