import styled from 'styled-components/macro';

export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => `${theme.getValue(44)}px`};
`;

export const Link = styled.a`
  margin: 0;
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  text-decoration: none;
`;

export const Icon = styled.img`
  width: ${({ theme }) => `${theme.getValue(15.83)}px`};
  height: ${({ theme }) => `${theme.getValue(15.83)}px`};
  vertical-align: top;
`;

export default { Link, FileContainer, Icon };
