import React from 'react';
import Button from '../../Button';
import IVPPopup from '../../IVPPopup';
import { DeleteUserModalProps } from './types';
import Components from './styles';

export default function DeleteUserModal(props: DeleteUserModalProps) {
  const { setDeleteDialogState, handleDeleteClick } = props;
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IVPPopup centered title="Delete User" {...props}>
      <Components.Container>
        <p>By deleting this user the account will no longer exist. Are you sure you want to delete the account?</p>
        <Components.ButtonGroup>
          <Button
            variant="secondary"
            onClick={() => {
              setDeleteDialogState(false);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleDeleteClick}>Delete</Button>
        </Components.ButtonGroup>
      </Components.Container>
    </IVPPopup>
  );
}
