import AdminPage from '../../../components/Page/AdminPage';
import Landing from '../../../components/admin/Landing';

export default function UsersScreen() {
  return (
    <AdminPage>
      <Landing />
    </AdminPage>
  );
}
