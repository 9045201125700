import styled from 'styled-components';

const Container = styled.div`
  display: fixed;
  width: 100vw;
  height: 100vh;
  position: relative;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => `${theme.colors.white}`};
`;

const Message = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(17)}px`};
  line-height: 148%;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => `${theme.colors.blue4}`};
  width: ${({ theme }) => `${theme.getValue(300)}px`};
  margin: auto;
`;

export default { Container, Message };
