export default [
  {
    title: 'Subject To Backup Withholding',
    description:
      'Select this option if you have been notified by the IRS that you are currently subject to backup Withholding because you have failed to report all interest and dividends on your tax return.',
    certify:
      'Under penalties of perjury, I hereby certify that the taxpayer identification number (TIN) shown on this form is my correct taxpayer identification number and I am a U.S. person (including a U.S. resident alien).',
    template: process.env.REACT_APP_W9_SUBJECT,
  },
  {
    title: 'Not Subject to Backup Withholding',
    description:
      'Select this option if you have not been notified by the IRS that you are currently subject to backup Withholding because you have failed to report all interest and dividends on your tax return.',
    certify:
      'Under penalties of perjury, I hereby certify that the taxpayer identification number (TIN) shown on this form is my correct taxpayer identification number, and that I am not subject to backup withholding because: (a) I am exempt from backup withholding, or (b) I have not been notified by the Internal Revenue Service (IRS) that I am subject to backup withholding as a result of a failure to report all interest or dividends, or (c) the IRS has notified me that I am no longer subject to backup withholding as a result of a failure to report all interest or dividends, and I am a U.S. person (including a U.S. resident alien).',
    template: process.env.REACT_APP_W9_NOT_SUBJECT,
  },
];
