import PrintTemplate from '../../../content/PrintTemplate';
import { ReviewGridSubmissionType } from '../../../content/PrintTemplate/types';
import LogoImg from '../../../assets/images/advantage-logo.png';
import Components from './styles';

type PDFScreenPropsType = {
  title: string;
  submission: ReviewGridSubmissionType;
  businessUnit: string;
};

function PDFScreen(props: PDFScreenPropsType) {
  const { title, submission, businessUnit } = props;

  return (
    <div id="report">
      <Components.PrintWrapper>
        <Components.PrintHeader>
          <Components.PrintLogo>
            <img src={LogoImg} alt="Advantage Solutions" />
          </Components.PrintLogo>
          <Components.StyledPill>{businessUnit}</Components.StyledPill>
        </Components.PrintHeader>
        <Components.Title>{title}</Components.Title>
        <PrintTemplate submission={submission} />
      </Components.PrintWrapper>
    </div>
  );
}

export default PDFScreen;
