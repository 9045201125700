import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { capitalizeString } from '../../utils/text';
import { increment, decrement } from '../../store/slices/stepSlice';
import Button from '../../components/Button';
import Toggle from '../../components/Toggle';
import { setGBI } from '../../store/slices/generalBusinessInfoSlice';
import stateOptions from './data/stateOptions';
import useScrollToTop from '../stepUtils';
import { GBISchema as validationSchema, Step2FormType } from './types';
import {
  FormWrapper,
  FormSection,
  FormFlexContainer,
  ButtonSection,
  StyledInput as Input,
  StyledDropdown as Dropdown,
  PhoneInputWrapper,
  StyledTextArea as TextArea,
} from './styles';
import PhoneInput from '../../components/Input/PhoneInput';
import { PAYMENT_TERMS } from '../../constants';

export default function Step2Form(props: Step2FormType) {
  useScrollToTop();
  const { disableAll, fieldValues } = props;
  const DEBUG_MODE = false;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isSameAddress, setIsSameAddress] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, setFieldValue, setFieldTouched } =
    useFormik({
      initialValues: fieldValues,
      validateOnMount: true,
      onSubmit: vals => {
        dispatch(setGBI(vals));
        if (DEBUG_MODE) {
          navigate(`/review${document.location.search}`);
        }
        dispatch(increment());
      },
      validationSchema,
    });

  useEffect(() => {
    if (isSameAddress) {
      setFieldValue('mailingStreet1', values.street1, true);
      setFieldValue('mailingStreet2', values.street2, true);
      setFieldValue('mailingCity', values.city, true);
      setFieldValue('mailingState', values.state, true);
      setFieldValue('mailingZip', values.zip, true);
      setTimeout(() => {
        setFieldTouched('mailingStreet1', true);
        setFieldTouched('mailingStreet2', true);
        setFieldTouched('mailingCity', true);
        setFieldTouched('mailingState', true);
        setFieldTouched('mailingZip', true);
      }, 350);
    }
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSameAddress]);

  return (
    <FormWrapper>
      <Input
        id="nameOfBusiness"
        name="nameOfBusiness"
        label={
          <>
            <strong>Name of Business, Organization, or Name of Person*</strong> (as shown on your tax return)
          </>
        }
        value={values.nameOfBusiness}
        error={errors.nameOfBusiness}
        touched={touched.nameOfBusiness}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disableAll}
      />
      <Input
        id="dba"
        name="dba"
        label={
          <>
            <strong>Business Name (DBA)/disregarded entity name, if different from above.</strong> (Ensure your input
            exactly matches your business name in the “Payment Information” section of your contract. If you do not have
            a contract, ensure it exactly matches your company or bank letterhead.)
          </>
        }
        value={values.dba}
        error={errors.dba}
        touched={touched.dba}
        onChange={handleChange}
        onBlur={handleBlur}
        disabled={disableAll}
      />
      <FormSection>
        <legend>Physical Address</legend>
        <FormFlexContainer>
          <Input
            id="street1"
            name="street1"
            label="Street 1*"
            value={values.street1}
            error={errors.street1}
            touched={touched.street1}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          <Input
            id="street2"
            name="street2"
            label="Street 2"
            value={values.street2}
            error={errors.street2}
            touched={touched.street2}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          <Input
            id="city"
            name="city"
            label="City/Town*"
            value={values.city}
            error={errors.city}
            touched={touched.city}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          {disableAll ? (
            <Input
              id="state"
              name="state"
              label="State*"
              value={values.state}
              error={errors.state}
              touched={touched.state}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSameAddress || disableAll}
              className="half"
            />
          ) : (
            <Dropdown
              id="state"
              name="state"
              label="State*"
              value={values.state}
              error={errors.state}
              touched={touched.state}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableAll}
              className="half"
            >
              <option value="" disabled hidden>
                --
              </option>
              {stateOptions.map(state => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </option>
              ))}
            </Dropdown>
          )}

          <Input
            id="zip"
            name="zip"
            label="Zip Code*"
            value={values.zip}
            error={errors.zip}
            touched={touched.zip}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
            className="half"
          />
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <legend style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
          Mailing Address{' '}
          {!disableAll ? (
            <Toggle
              onClick={() => {
                setIsSameAddress(!isSameAddress);
              }}
              label="Same as Physical Address"
            />
          ) : null}
        </legend>
        <FormFlexContainer>
          <Input
            id="mailingStreet1"
            name="mailingStreet1"
            label="Street 1*"
            value={values.mailingStreet1}
            error={errors.mailingStreet1}
            touched={touched.mailingStreet1}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSameAddress || disableAll}
          />
          <Input
            id="mailingStreet2"
            name="mailingStreet2"
            label="Street 2"
            value={values.mailingStreet2}
            error={errors.mailingStreet2}
            touched={touched.mailingStreet2}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSameAddress || disableAll}
          />
          <Input
            id="mailingCity"
            name="mailingCity"
            label="City/Town*"
            value={values.mailingCity}
            error={errors.mailingCity}
            touched={touched.mailingCity}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSameAddress || disableAll}
          />
          {isSameAddress || disableAll ? (
            <Input
              id="mailingState"
              name="mailingState"
              label="State*"
              value={values.mailingState}
              error={errors.mailingState}
              touched={touched.mailingState}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={isSameAddress || disableAll}
              className="half"
            />
          ) : (
            <Dropdown
              id="mailingState"
              name="mailingState"
              label="State*"
              value={values.mailingState}
              error={errors.mailingState}
              touched={touched.mailingState}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableAll}
              className="half"
            >
              <option value="" disabled hidden>
                --
              </option>
              {stateOptions.map(state => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </option>
              ))}
            </Dropdown>
          )}

          <Input
            id="mailingZip"
            name="mailingZip"
            label="Zip Code*"
            value={values.mailingZip}
            error={errors.mailingZip}
            touched={touched.mailingZip}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isSameAddress || disableAll}
            className="half"
          />
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <legend>Phone Numbers</legend>
        <FormFlexContainer>
          <PhoneInputWrapper>
            <PhoneInput
              id="phone"
              name="phone"
              label="Telephone Number*"
              value={values.phone}
              error={errors.phone}
              touched={touched.phone}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableAll}
            />
          </PhoneInputWrapper>

          <PhoneInputWrapper>
            <PhoneInput
              id="faxNumber"
              name="faxNumber"
              label="Fax Number"
              value={values.faxNumber}
              error={errors.faxNumber}
              touched={touched.faxNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableAll}
            />
          </PhoneInputWrapper>
          <PhoneInputWrapper>
            <PhoneInput
              id="tollFreeNumber"
              name="tollFreeNumber"
              label="Toll Free Number"
              value={values.tollFreeNumber}
              error={errors.tollFreeNumber}
              touched={touched.tollFreeNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={disableAll}
              tollFree
            />
          </PhoneInputWrapper>
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        <legend>Contact Person</legend>
        <FormFlexContainer>
          <Input
            id="firstName"
            name="firstName"
            label="First Name*"
            value={values.firstName}
            error={errors.firstName}
            touched={touched.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          <Input
            id="lastName"
            name="lastName"
            label="Last Name*"
            value={values.lastName}
            error={errors.lastName}
            touched={touched.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          <Input
            id="title"
            name="title"
            label="Title*"
            value={values.title}
            error={errors.title}
            touched={touched.title}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          <Input
            id="email"
            name="email"
            label="Email Address*"
            value={values.email}
            error={errors.email}
            touched={touched.email}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
          <Input
            type="url"
            id="webAddress"
            name="webAddress"
            label="Web Address"
            placeholder="https://"
            value={values.webAddress}
            error={errors.webAddress}
            touched={touched.webAddress}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
        </FormFlexContainer>
      </FormSection>
      <FormSection>
        {disableAll ? (
          <Input
            id="stp"
            name="stp"
            label="Payment Terms*"
            value={capitalizeString(values.stp)}
            error={errors.stp}
            touched={touched.stp}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          />
        ) : (
          <Dropdown
            id="stp"
            name="stp"
            label="Payment Terms*"
            value={values.stp}
            error={errors.stp}
            touched={touched.stp}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
          >
            <option value="" disabled>
              Please choose...
            </option>
            {PAYMENT_TERMS.map(term => (
              <option key={term} value={term}>
                {capitalizeString(term)}
              </option>
            ))}
          </Dropdown>
        )}

        {values.stp === PAYMENT_TERMS[PAYMENT_TERMS.length - 1] ? (
          <TextArea
            id="stpOther"
            name="stpOther"
            label="Please describe your payment terms*"
            value={values.stpOther}
            error={errors.stpOther}
            touched={touched.stpOther}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disableAll}
            rows={1}
          />
        ) : null}
      </FormSection>
      {!disableAll ? (
        <ButtonSection>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(decrement());
            }}
          >
            Back
          </Button>
          <Button
            disabled={!isValid}
            onClick={() => {
              handleSubmit();
            }}
          >
            Next
          </Button>
        </ButtonSection>
      ) : null}
    </FormWrapper>
  );
}

Step2Form.defaultProps = {
  disableAll: false,
};
