/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/jsx-props-no-spreading */
import InputMask from 'react-input-mask';
import Input, { InputBoxType } from '.';

type InputPhoneType = InputBoxType & { tollFree?: boolean };

const renderField = (props: any) => {
  return <Input type="tel" {...props} />;
};

function PhoneInput(props: InputPhoneType) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  const { tollFree } = props;
  const mask = tollFree ? '1-(999) 999-9999' : '999-999-9999';
  return (
    <InputMask className="input--phone" {...props} mask={mask}>
      {renderField(props)}
    </InputMask>
  );
}

PhoneInput.defaultProps = {
  tollFree: false,
};

export default PhoneInput;
