import { useFormik } from 'formik';
import { useState } from 'react';
import Button from '../../Button';
import IVPPopup from '../../IVPPopup';
import { ChangePasswordFieldsType, ChangePasswordModalProps, ChangePasswordSchema } from './types';
import Components from './styles';
import Input from '../../Input';
import { Error as ErrorMsg } from '../../Input/styles';

const initialValues: ChangePasswordFieldsType = {
  currentPassword: '',
  password: '',
  confirmPassword: '',
};

export default function ChangePasswordModal(props: ChangePasswordModalProps) {
  const [showSuccessMsg, setShowSuccessMsg] = useState<boolean>(false);
  const [serverErr, setServerErr] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { handleSaveClick, setModalOpenState } = props;
  const { values, errors, touched, isValid, handleChange, handleBlur, handleSubmit, resetForm } = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnMount: true,
    validationSchema: ChangePasswordSchema,
    onSubmit: async (vals: ChangePasswordFieldsType) => {
      setIsLoading(true);
      try {
        await handleSaveClick(vals);

        setShowSuccessMsg(true);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        setServerErr(error?.message || error || 'Something went wrong. Please try again later.');
        console.error(error);
      }
      setIsLoading(false);
    },
  });
  const handleCloseForm = () => {
    resetForm();
    setModalOpenState?.(false);
    setShowSuccessMsg(false);
    setServerErr('');
  };
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IVPPopup title="Change password" {...props}>
      <Components.Container>
        {!showSuccessMsg ? (
          <>
            <Components.FormWrapper>
              <Input
                id="currentPassword"
                name="currentPassword"
                value={values.currentPassword}
                touched={touched.currentPassword}
                error={errors.currentPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                label="Current password*"
              />
              <Input
                id="password"
                name="password"
                value={values.password}
                touched={touched.password}
                error={errors.password}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                label="New password*"
              />
              <Input
                id="confirmPassword"
                name="confirmPassword"
                value={values.confirmPassword}
                touched={touched.confirmPassword}
                error={errors.confirmPassword}
                onChange={handleChange}
                onBlur={handleBlur}
                type="password"
                label="Confirm new password*"
              />
            </Components.FormWrapper>
            <Components.ButtonGroup>
              <Components.ButtonGroupWrapper>
                <Button
                  variant="secondary"
                  onClick={() => {
                    handleCloseForm();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  disabled={!isValid || isLoading}
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Save
                </Button>
                {serverErr ? <ErrorMsg>{serverErr}</ErrorMsg> : null}
              </Components.ButtonGroupWrapper>
            </Components.ButtonGroup>
          </>
        ) : (
          <>
            <p>Your password was successfully updated!</p>
            <Components.ButtonGroup>
              <Button
                variant="secondary"
                onClick={() => {
                  handleCloseForm();
                }}
              >
                Close
              </Button>
            </Components.ButtonGroup>
          </>
        )}
      </Components.Container>
    </IVPPopup>
  );
}
