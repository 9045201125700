export const ResidentsDescription = [
  {
    title: 'CA Resident (Estate)',
    description: 'Decendent was a CA resident at time of death',
  },
  {
    title: 'CA Resident (Trust)',
    description: 'At least one trustee is a CA resident',
  },
  {
    title: 'CA Nonresident',
    description: '',
  },
];

export const ResidentwithoutDescription = [
  {
    title: 'CA Resident',
    description: '',
  },
  {
    title: 'CA Nonresident',
    description: '',
  },
];
