import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import type { RootState } from '../store/store';

const useScrollToTop = () => {
  const scrollableRef = useSelector((state: RootState) => state.step.scrollableRef);
  useEffect(() => {
    const scrollToTop = () => {
      const targetPosition = 0; // Scroll to the top of the element
      if (scrollableRef) {
        if ('scrollBehavior' in document.documentElement.style) {
          // Scroll using scrollOptions if scrollBehavior is supported
          scrollableRef.scrollTo({ top: targetPosition, behavior: 'smooth' });
          window.scrollTo({ top: targetPosition, behavior: 'smooth' });
        } else {
          // Scroll using scrollOptions if scrollBehavior is not supported
          scrollableRef.scrollTo(targetPosition, targetPosition);
          window.scrollTo(targetPosition, targetPosition);
        }
      }
    };

    scrollToTop();
  }, [scrollableRef]);
};

export default useScrollToTop;
