import styled from 'styled-components/macro';

const Headline = styled.h1`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  font-size: ${({ theme }) => `${theme.getValue(24)}px`};
  line-height: calc(32 / 24);
  color: ${({ theme }) => `${theme.colors.gray1}`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    font-size: ${({ theme }) => `${theme.getValue(20)}px`};
    line-height: calc(28 / 20);
  }
`;

export default Headline;
