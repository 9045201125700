import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useSearchParams } from 'react-router-dom';
import { setUser } from '../../../store/slices/userSlice';
import Components, { StyledInput as Input } from './styles';
import { LoginSchemaType, LoginSchema as validationSchema } from './types';
import { Error as ErrorMsg } from '../../Input/styles';
import { RootState } from '../../../store/store';
import ForgotPasswordModal from '../ForgotPasswordModal';

const initialValues: LoginSchemaType = {
  identifier: '',
  password: '',
};

const loginUser = async (loginInfo: LoginSchemaType) => {
  try {
    const { identifier, password } = loginInfo;

    const loginResponse = await fetch(`${process.env.REACT_APP_API_URL}/auth/local`, {
      method: 'post',
      headers: {
        'content-type': 'application/json',
      },
      body: JSON.stringify({
        identifier: identifier.toLowerCase(),
        password,
      }),
    });

    const loginData = await loginResponse.json();
    const { jwt, user, error } = loginData;

    if (!user) {
      throw error;
    }
    return { jwt, user };
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getUserRole = async (userId: string, authToken: string) => {
  try {
    const userResponse = await fetch(`${process.env.REACT_APP_API_URL}/users/${userId}?populate=role`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${authToken}`,
      },
    });
    const userData = await userResponse.json();
    const { error, role } = userData;

    if (error) throw error;

    // eslint-disable-next-line no-throw-literal
    if (!role) throw { name: 'Error', message: 'Role info is missing' };

    return role;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export default function LoginForm() {
  const storedUserInfo = useSelector((state: RootState) => state.userInfo);
  const { user } = storedUserInfo;
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState<string>('');
  const [showForgotPassModal, setShowForgotPassModal] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const expiredSession = searchParams.get('expired');
  const [isExpiredSession] = useState(!!expiredSession);

  const handleLoginSubmit = async (credentials: LoginSchemaType) => {
    try {
      const userInfo = await loginUser(credentials);
      const roleInfo = await getUserRole(userInfo?.user?.id, userInfo.jwt);

      // Add user to store
      dispatch(setUser({ jwt: userInfo?.jwt, user: { ...userInfo?.user, role: { ...roleInfo } } }));

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.error(error);
      const errorMsg = typeof error === 'string' ? error : error?.message;
      setServerError(errorMsg);
    }
  };

  const { values, errors, touched, setFieldValue, handleChange, handleBlur, handleSubmit, isValid } = useFormik({
    validationSchema,
    initialValues,
    onSubmit: vals => {
      handleLoginSubmit(vals);
    },
    enableReinitialize: true,
    validateOnMount: true,
  });
  useEffect(() => {
    if (isExpiredSession) {
      setServerError(`Your session expired, please log in again.`);
    }
  }, [isExpiredSession]);

  return !user ? (
    <>
      <Components.Container>
        <Input
          id="identifier"
          name="identifier"
          value={values.identifier.toLowerCase()}
          touched={touched.identifier}
          error={errors.identifier}
          onChange={e => setFieldValue('identifier', e.target.value.toLowerCase())}
          onBlur={handleBlur}
          label="Advantage Email*"
        />
        <Input
          id="password"
          name="password"
          value={values.password}
          touched={touched.password}
          error={errors.password}
          onChange={handleChange}
          onBlur={handleBlur}
          label="Password*"
          type="password"
        />
      </Components.Container>
      <Components.ButtonContainer>
        <Components.StyledButton
          disabled={!isValid}
          onClick={() => {
            handleSubmit();
          }}
        >
          Log In
        </Components.StyledButton>
        <Components.StyledLink
          as="button"
          onClick={() => {
            setShowForgotPassModal(true);
          }}
        >
          Forgot Password?
        </Components.StyledLink>
        {serverError ? <ErrorMsg>{serverError}</ErrorMsg> : null}
      </Components.ButtonContainer>
      <ForgotPasswordModal open={showForgotPassModal} setModalOpenState={setShowForgotPassModal} />
    </>
  ) : (
    <Navigate to="/home" replace />
  );
}
