import { useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { decrement, increment } from '../../store/slices/stepSlice';
import { setVendorActivity } from '../../store/slices/vendorActivitySlice';
import CheckboxItem from '../../components/CheckboxItem';
import activities from '../vendorActivities';
import Button from '../../components/Button';
import { Step3Schema as validationSchema } from './types';
import { ButtonSection, StyledHeadline as Headline } from '../Step2/styles';
import { RootState } from '../../store/store';
import TextArea from '../../components/TextArea';
import useScrollToTop from '../stepUtils';

export default function Step3() {
  useScrollToTop();
  const dispatch = useDispatch();
  const storedVendorActivity = useSelector((state: RootState) => state.vendorActivity);
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue, isValid } = useFormik({
    initialValues: storedVendorActivity,
    validateOnChange: true,
    onSubmit: vals => {
      const { vendorActivity, other } = vals;
      dispatch(setVendorActivity({ vendorActivity, other }));
      dispatch(increment());
    },
    validationSchema,
  });

  return (
    <>
      <Headline>Vendor Activity</Headline>
      <p>Please select the activity that best describes your service. You may only choose one Vendor Activity.</p>
      <div>
        {activities.map(activity => (
          <CheckboxItem
            key={activity.title}
            name="vendorActivity"
            title={activity?.title}
            description={activity?.description}
            value={activity?.title}
            onClick={() => {
              setFieldValue('vendorActivity', activity.title);
              setFieldValue('specifyOther', activity.title === 'Other');
            }}
            onBlur={handleBlur}
            isChecked={activity?.title === values.vendorActivity}
            touched={touched.vendorActivity}
            error={errors.vendorActivity}
          >
            {activity?.title === 'Other' ? (
              <TextArea
                type="text"
                id="other"
                name="other"
                label="Please specify*"
                value={values.other}
                onChange={handleChange}
                onBlur={handleBlur}
                touched={touched.other}
                error={errors.other}
                rows={1}
              />
            ) : null}
          </CheckboxItem>
        ))}
      </div>
      <ButtonSection>
        <Button
          variant="secondary"
          onClick={() => {
            dispatch(decrement());
          }}
        >
          Back
        </Button>
        <Button
          disabled={!isValid || !values.vendorActivity}
          onClick={() => {
            handleSubmit();
          }}
        >
          Next
        </Button>
      </ButtonSection>
    </>
  );
}
