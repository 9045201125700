import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step8 from './Step8';
import Step9 from './Step9';

const stepList = [
  {
    title: 'Review Vendor Information Resources',
    content: <Step1 />,
  },
  {
    title: 'General Business Information',
    content: <Step2 />,
  },
  {
    title: 'Vendor Activity',
    content: <Step3 />,
  },
  {
    title: 'TIN & Residency Status',
    content: <Step4 />,
  },
  {
    title: 'Sustainability Policies',
    content: <Step5 />,
  },
  {
    title: 'Substitute IRS Form W-9 Certification',
    content: <Step6 />,
  },
  {
    title: 'Conflict of Interest',
    content: <Step7 />,
  },
  {
    title: 'Business Classification',
    content: <Step8 />,
  },
  {
    title: 'Debarment, Suspension, and Other Responsibility Matters',
    content: <Step9 />,
  },
  {
    title: 'Review',
    content: null,
  },
];

export default stepList;
