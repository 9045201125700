import * as yup from 'yup';
import { REQUIRED_VALIDATION_MSG } from '../../constants';
import { fileArraySchema } from '../../types';

export const Step9Schema = yup.object({
  vendorCertify: yup.string().required(REQUIRED_VALIDATION_MSG),
  vendorDescription: yup.string(),
  requireFiles: yup.boolean(),
  vendorCertifyFiles: yup.array().when('requireFiles', {
    is: true,
    then: fileArraySchema,
  }),
});

export type ResponsabilityCertificationType = yup.InferType<typeof Step9Schema>;

export default ResponsabilityCertificationType;
