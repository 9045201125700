import styled from 'styled-components/macro';
import UserScreenComponents from '../UserScreen/styles';

const Container = styled(UserScreenComponents.Container)`
  width: ${({ theme }) => `${theme.getValue(550)}px`};
`;
const LinkButton = styled.button`
  background: none;
  padding: 0;
  margin: 0;
  border: 0;
  color: ${({ theme }) => `${theme.colors.primaryBlue}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-style: normal;
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  text-align: center;
  text-decoration: underline;
  margin-top: ${({ theme }) => `${theme.getValue(32)}px`};
  &:hover {
    cursor: pointer;
  }
`;
export default { ...UserScreenComponents, Container, LinkButton };
