/* eslint-disable @typescript-eslint/no-explicit-any */
import Components from './styles';
import { SectionDescription } from '../../ReviewGridStep/styles';

import { ReviewStep9PropsType } from './types';
import AttachedFilesList from '../../../../components/AttachedFilesList';

export default function ReviewStep9(props: ReviewStep9PropsType) {
  const { fieldValues: responsabilityCertificationData } = props;
  const { vendorCertify, vendorCertifyFiles } = responsabilityCertificationData;
  const certify = vendorCertify === 'I certify the above.';
  return (
    <>
      <SectionDescription>
        The vendor <strong>{certify ? 'certifies' : 'does not certify'}</strong> that it and its principals:
      </SectionDescription>
      <Components.OrderedList>
        <Components.ListItem>
          Are not presently debarred, suspended, proposed for debarment, declared ineligible, sentenced to a denial of
          Federal benefits by a State or Federal court, or voluntarily excluded from covered transactions by any Federal
          department or agency;
        </Components.ListItem>
        <Components.ListItem>
          Have not within a three-year period preceding this application been convicted of or had a civil judgment
          rendered against them for commission of fraud or a criminal offense in connection with obtaining, attempting
          to obtain, or performing a public (Federal, State, or local) transaction or contract under a public
          transaction; violation of Federal or State antitrust statutes or commission of embezzlement, theft, forgery,
          bribery, falsification or destruction of records, making false statements, or receiving stolen property;
        </Components.ListItem>
        <Components.ListItem>
          Are not presently indicted for or otherwise criminally or civilly charged by a governmental entity (Federal,
          State, or local) with the commission of paying person(s) for influencing or attempting to influence an officer
          or employee of any agency, a Member of Congress, an officer or employee of Congress, or an employee of a
          Member of Congress; and
        </Components.ListItem>
        <Components.ListItem>
          Have not within a three-year period preceding this application had one or more public transactions (Federal,
          State, or local) terminated for cause or default;
        </Components.ListItem>
      </Components.OrderedList>
      <SectionDescription>
        <strong>{vendorCertify}</strong>
      </SectionDescription>
      {!certify && (
        <SectionDescription>
          Where the vendor is unable to certify to any of the statements in this certification, he or she shall attach
          an explanation to this information form.
        </SectionDescription>
      )}
      <AttachedFilesList
        files={(
          (vendorCertifyFiles &&
            (Array.isArray(vendorCertifyFiles)
              ? vendorCertifyFiles
              : (vendorCertifyFiles as any).data?.map((f: any) => f.attributes))) ||
          []
        )?.map((file: any) => ({ name: file.name, url: file.url }))}
      />
    </>
  );
}
