export const DESIGN_DIMENSIONS = {
  width: 1920,
  height: 1080,
};

type UI_PARAMS = {
  currentScreenDimensions: {
    width: number;
    height: number;
  };
  value: number;
};

// This needs to be ordered from small to large
export const screenResolutions: Record<
  string,
  {
    width: number;
    height: number;
  }
> = {
  xxsmall: {
    width: 320,
    height: 480,
  },
  xsmall: {
    width: 640,
    height: 896,
  },
  tabletsmall: {
    width: 768,
    height: 1024,
  },
  small: {
    width: 820,
    height: 1024,
  },
  tabletlarge: {
    width: 1366,
    height: 1024,
  },
  medium: {
    width: 1440,
    height: 900,
  },
  large: {
    width: 1920,
    height: 1080,
  },
};

const getDesignDimensions = (scWidth: number): { width: number; height: number } => {
  if (scWidth >= screenResolutions.large.width) return screenResolutions.large;
  const resolutionLabel =
    Object.keys(screenResolutions).find((scRKey: string) => scWidth <= screenResolutions[scRKey].width) || 'medium';
  return screenResolutions[resolutionLabel];
};

function getWidth(params: UI_PARAMS): number {
  const { currentScreenDimensions, value } = params;
  if (currentScreenDimensions.width > screenResolutions.xsmall.width) {
    return Math.ceil(
      (currentScreenDimensions.width / getDesignDimensions(currentScreenDimensions.width).width) * value,
    );
  }

  return value;
}

export default getWidth;
