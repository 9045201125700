/* eslint-disable no-undef */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import BusinessClassificationType from '../../content/Step8/types';

export interface businessClassificationState {
  businessClassificationCategories: BusinessClassificationCategoryType[];
}

const initialState: BusinessClassificationType = {
  businessClassificationCategories: [],
};

export const businessClassificationSlice = createSlice({
  name: 'businessClassification',
  initialState,
  reducers: {
    setBusinessClassification: (state, action: PayloadAction<businessClassificationState>) => {
      return { ...action.payload };
    },
  },
});

export const { setBusinessClassification } = businessClassificationSlice.actions;
export default businessClassificationSlice.reducer;
