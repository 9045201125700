import styled from 'styled-components/macro';
import BgImg from '../../../assets/images/vendor-welcome-bg.png';

const Wrapper = styled.div`
  background: url(${BgImg}) no-repeat;
  height: calc(100vh - ${({ theme }) => `${theme.getValue(80)}px`});
  width: 100vw;
  display: flex;
  background-size: cover;
  background-position: center center;
  justify-content: center;
  align-items: flex-start;
  padding-top: ${({ theme }) => `${theme.getValue(108)}px`};
  padding-bottom: ${({ theme }) => `${theme.getValue(108)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    padding-top: ${({ theme }) => `${theme.getValue(100)}px`};
    padding-bottom: ${({ theme }) => `${theme.getValue(100)}px`};
    height: calc(100vh - ${({ theme }) => `${theme.getValue(68)}px`});
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    padding-top: ${({ theme }) => `${theme.getValue(30)}px`};
    padding-bottom: ${({ theme }) => `${theme.getValue(60)}px`};
  }
`;

const Container = styled.div`
  width: ${({ theme }) => `${theme.getValue(870)}px`};
  margin: 0 auto;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(846)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: ${({ theme }) => `calc(100% - ${theme.getValue(180)}px)`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: ${({ theme }) => `calc(100% - ${theme.getValue(40)}px)`};
  }
`;

const Banner = styled.div`
  height: ${({ theme }) => `${theme.getValue(116)}px`};
  background-color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  padding: ${({ theme }) => `${theme.getValue(22)}px`} 0;
  border-radius: 6px;
  text-align: center;
  margin-bottom: ${({ theme }) => `${theme.getValue(24)}px`};
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    height: ${({ theme }) => `${theme.getValue(92)}px`};
    padding: ${({ theme }) => `${theme.getValue(13)}px`} 0;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    height: ${({ theme }) => `${theme.getValue(66)}px`};
    padding: ${({ theme }) => `${theme.getValue(10)}px`} 0;
    margin-bottom: ${({ theme }) => `${theme.getValue(8)}px`};
  }
`;

const Logo = styled.div`
  width: ${({ theme }) => `${theme.getValue(310)}px`};
  height: ${({ theme }) => `${theme.getValue(72)}px`};
  margin: 0 auto;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: ${({ theme }) => `${theme.getValue(280)}px`};
    height: ${({ theme }) => `${theme.getValue(66)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    width: ${({ theme }) => `${theme.getValue(195)}px`};
    height: ${({ theme }) => `${theme.getValue(46)}px`};
  }
  img {
    display: block;
    width: 100%;
    height: auto;
  }
`;

const Hero = styled.div`
  min-height: ${({ theme }) => `${theme.getValue(516)}px`};
  background-color: ${({ theme }) => `${theme.colors.white}`};
  border-radius: 6px;
  text-align: center;
  padding: ${({ theme }) => `${theme.getValue(44)}px`};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    padding: ${({ theme }) => `${theme.getValue(40)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    padding: ${({ theme }) => `${theme.getValue(24)}px`} ${({ theme }) => `${theme.getValue(15)}px`};
  }
`;

const Title = styled.h1`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  font-size: ${({ theme }) => `${theme.getValue(27)}px`};
  line-height: 1.5;
  text-transform: uppercase;
`;

const Subheadline = styled.h2`
  font-family: ${({ theme }) => `${theme.fonts.secondary}`};
  color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  font-size: ${({ theme }) => `${theme.getValue(22)}px`};
  line-height: 1.5;
  text-transform: uppercase;
  width: ${({ theme }) => `${theme.getValue(620)}px`};
  margin: 0 auto;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.medium}px`}) {
    width: ${({ theme }) => `${theme.getValue(609)}px`};
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: 100%;
  }
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.xsmall}px`}) {
    font-size: ${({ theme }) => `${theme.getValue(18)}px`};
    line-height: ${({ theme }) => `${theme.getValue(25)}px`};
  }
`;

const HeadlineContainer = styled.header`
  width: 100%;
`;

const Description = styled.div`
  width: ${({ theme }) => `${theme.getValue(580)}px`};
  text-align: center;
  @media screen and (max-width: ${({ theme }) => `${theme.breakpoints.small}px`}) {
    width: 100%;
  }
  a {
    font-weight: ${({ theme }) => `${theme.weights.bold}`};
    color: ${({ theme }) => `${theme.colors.secondaryBlue}`};
  }
`;

export default { Wrapper, Container, Banner, Hero, Logo, Title, Subheadline, HeadlineContainer, Description };
