import Components from './style';
import uploadIcon from './uploadIcon.svg';
import deleteIcon from './deleteIcon.svg';
import ProgressBar from '../../ProgressBar';
import { CustomUploadPropsType } from './types';
import { Error as ErrorMsg } from '../../Input/styles';

function CustomUpload(props: CustomUploadPropsType) {
  const { id, filesToDisplay, newFiles, onFileFieldChange, removeFile, uploadLabel, uploadInputLabel, error } = props;

  const handleClick = (e: React.MouseEvent<HTMLInputElement>) => {
    (e.target as HTMLInputElement).value = '';
  };

  return (
    <Components.UploadContainer>
      {uploadLabel ? <Components.UploadLabel>{uploadLabel}</Components.UploadLabel> : null}
      {filesToDisplay
        ? filesToDisplay.map(file => {
            const { file: fileData } = file;

            return (
              <Components.FileContainer key={`${id}-file-${file.name.replace(' ', '-').toLowerCase()}`}>
                <Components.FileUploaded>
                  <Components.FileName>{file.name}</Components.FileName>
                  <Components.DeleteFileIcon onClick={() => removeFile?.(file)} src={deleteIcon} alt="deleteIcon" />
                </Components.FileUploaded>
                <ProgressBar
                  size={fileData?.size || 0}
                  shouldDisplayBar={!!newFiles?.find(newFile => newFile.url === file.url)}
                />
              </Components.FileContainer>
            );
          })
        : null}
      <Components.StyledInputContainer>
        <Components.StyledInputIcon src={uploadIcon} alt="uploadIcon" />
        <Components.StyledInput
          type="file"
          multiple
          onChange={onFileFieldChange}
          onClick={e => {
            handleClick(e);
          }}
        />
        <Components.StyledInputLabel>{uploadInputLabel}</Components.StyledInputLabel>
      </Components.StyledInputContainer>
      {typeof error === 'string' && error ? <ErrorMsg>{error}</ErrorMsg> : null}
      {typeof error !== 'string' && error?.length && error.length > 0
        ? error.map((fileError: Record<string, unknown>) =>
            Object.entries(fileError).map(([key, value]) => <ErrorMsg key={key}>{`${value}`}</ErrorMsg>),
          )
        : null}
    </Components.UploadContainer>
  );
}

export default CustomUpload;
