/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
import { Container, TextArea as StyledTextArea, DisabledTextArea, Label, Error } from './styles';
import TextAreaType from './types';

export type InputBoxType = TextAreaType & React.InputHTMLAttributes<HTMLTextAreaElement>;

export default function TextArea(props: InputBoxType) {
  const { label, error, touched, className, variant, disabled, value, rows, ...rest } = props;
  return (
    <Container variant={variant} className={className}>
      {label && <Label className={disabled && !value ? 'is-moved' : ''}>{label}</Label>}
      {disabled ? (
        value ? (
          <DisabledTextArea>{value}</DisabledTextArea>
        ) : (
          <DisabledTextArea />
        )
      ) : (
        <StyledTextArea disabled={disabled} value={value} rows={rows} {...rest} />
      )}
      {!disabled && touched && error && <Error>{error}</Error>}
    </Container>
  );
}

TextArea.defaultProps = {
  variant: 'primary',
  rows: 3,
};
