export function VendorResource5() {
  return (
    <>
      <p>
        Payments made to CA nonresident vendors, including corporations, individuals, partnerships, estates and trusts,
        are subject to withholding. Nonresident vendors performing services in California or receiving rent, lease or
        royalty payments from property (real or personal) located in California will have 7% of their total payments
        withheld for state income taxes. However, no withholding is required if total payments to the vendor are $1,500
        or less for the calendar year.
      </p>
      <p>
        A CA nonresident vendor may request that income taxes be withheld at a lower rate or waived by sending a
        completed form FTB 588 to the address listed below. A waiver will generally be granted when a vendor has a
        history of filing California returns and making timely estimated payments. If the vendor activity is carried on
        outside of California or partially outside of California, a waiver or reduced withholding rate may be granted.
        For more information, contact:
      </p>
      <p>
        <strong>Franchise Tax Board</strong>
        <br />
        Withhold at Source Unit <br />
        Attention: State Agency Withholding Coordinator P.O. Box 651
        <br />
        Sacramento, CA 95812-0651
        <br />
        Telephone: 916-845-4900 FAX: 916-845-4831
      </p>
    </>
  );
}

export default {
  title: 'are you subject to CA Nonresident withholding?',
  content: <VendorResource5 />,
};
