import styled from 'styled-components/macro';

const Container = styled.div<{ checked: boolean }>`
  background-color: ${({ theme }) => `${theme.colors.white}`};
  border-radius: 4px;
  border: solid 1px ${({ theme, checked }) => (checked ? `${theme.colors.blue1}` : `${theme.colors.gray4}`)};
  margin-bottom: ${({ theme }) => `${theme.getValue(10)}px`};
  padding: ${({ theme }) => `${theme.getValue(16)}px`};
  &::last-child {
    margin-bottom: 0;
  }
`;

const Inner = styled.div`
  padding-left: ${({ theme }) => `${theme.getValue(32)}px`};
`;

const Description = styled.p`
  font-family: 'Proxima Nova';
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: 150%;
  margin: 0;
  margin-top: ${({ theme }) => `${theme.getValue(8)}px`};
  color: ${({ theme }) => `${theme.colors.gray2}`};
`;

const Title = styled.h3`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: 1;
  margin: 0;
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
`;

const Content = styled.div``;

const RadioWrapper = styled.div``;

const Checkbox = styled.input`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

const Control = styled.div`
  position: relative;
  width: ${({ theme }) => `${theme.getValue(16)}px`};
  height: ${({ theme }) => `${theme.getValue(16)}px`};
  box-sizing: border-box;
  &:before {
    position: absolute;
    content: '';
    border-radius: 50%;
    top: 50%;
    left: 50%;
    width: ${({ theme }) => `${theme.getValue(10)}px`};
    height: ${({ theme }) => `${theme.getValue(10)}px`};
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    transition: background-color 0.2s linear;
  }
  &:after {
    position: absolute;
    content: '';
    border-radius: 50%;
    border: solid 1px ${({ theme }) => `${theme.colors.gray6}`};
    top: 0.1px;
    left: 0.3px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
  ${Checkbox}:checked + & {
    &:before {
      background-color: ${({ theme }) => `${theme.colors.primaryBlue}`};
    }
    &:after {
      border-color: ${({ theme }) => `${theme.colors.primaryBlue}`};
    }
  }
`;

const Label = styled.label`
  display: flex;
  gap: ${({ theme }) => `${theme.getValue(16)}px`};

  &:hover {
    cursor: pointer;
  }
`;

export default { Description, Title, Content, RadioWrapper, Checkbox, Control, Label, Container, Inner };
