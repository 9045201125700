import styled from 'styled-components/macro';

const BackContainer = styled.div`
  button {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${({ theme }) => `${theme.getValue(10)}px`};
    &:hover {
      cursor: pointer;
    }
    @media print {
      display: none;
    }
  }
`;

const BackLabel = styled.span`
  color: ${({ theme }) => `${theme.colors.gray2}`};
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-size: ${({ theme }) => `${theme.getValue(18)}px`};
  line-height: ${({ theme }) => `${theme.getValue(27)}px`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  margin-top: ${({ theme }) => `${theme.getValue(2)}px`};
`;

const BackIcon = styled.img`
  width: ${({ theme }) => `${theme.getValue(16.5)}px`};
`;

export default { BackContainer, BackLabel, BackIcon };
