import { memo } from 'react';

function MagglassIcon() {
  return (
    <svg viewBox="0 0 13 14" fill="none">
      <path
        d="M12.772 12.183l-2.94-2.94a5.432 5.432 0 001.088-3.268c0-3.01-2.45-5.46-5.46-5.46S0 2.965 0 5.975s2.45 5.46 5.46 5.46a5.431 5.431 0 003.268-1.089l2.94 2.94a.782.782 0 001.104-1.103zM1.56 5.975a3.9 3.9 0 117.8 0 3.9 3.9 0 01-7.8 0z"
        fill="#4B5563"
      />
    </svg>
  );
}

const MemoMagglassIcon = memo(MagglassIcon);
export default MemoMagglassIcon;
