import { useNavigate } from 'react-router-dom';
import Button from '../../Button';
import IVPPopup from '../../IVPPopup';
import { CustomPopUpPropsType } from '../../IVPPopup/types';
import Components from './styles';

type SignUpConfirmationModalPropsType = Omit<CustomPopUpPropsType, 'children'>;
export default function SignUpConfirmationModal(props: SignUpConfirmationModalPropsType) {
  const { setModalOpenState } = props;
  const navigate = useNavigate();
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <IVPPopup title="Account created successfully!" centered {...props}>
      <Components.Container>
        <p>Your account has successfully been created. To get started, please login with your new credentials.</p>
      </Components.Container>
      <Components.ButtonContainer>
        <Button
          onClick={() => {
            setModalOpenState?.(false);
            navigate('/login');
          }}
        >
          GO TO LOGIN
        </Button>
      </Components.ButtonContainer>
    </IVPPopup>
  );
}
