import styled from 'styled-components/macro';

const FormWrapper = styled.div`
  width: ${({ theme }) => `${theme.getValue(290)}px`};
  margin-left: auto;
  margin-right: auto;
`;

const ButtonGroup = styled.div`
  text-align: center;
  margin-top: ${({ theme }) => `${theme.getValue(20)}px`};
`;

export default { FormWrapper, ButtonGroup };
