import styled from 'styled-components/macro';
import Input from '../../Input';

const StyledInput = styled(Input)`
  min-width: 0;
  width: calc(50% - ${({ theme }) => `${theme.getValue(12)}px`});
  &.sign-up__email-field {
    width: 100%;
  }
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => `${theme.getValue(24)}px`};
  row-gap: ${({ theme }) => `${theme.getValue(8)}px`};
`;

const ButtonContainer = styled.div`
  margin-top: ${({ theme }) => `${theme.getValue(32)}px`};
  text-align: center;
`;

export default { Container, StyledInput, ButtonContainer };
