import styled from 'styled-components/macro';
import { Inner } from '../../ReviewGridStep/styles';

const Title = styled.p<{ inner?: boolean }>`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.semibold}`};
  font-size: ${({ theme }) => `${theme.getValue(16)}px`};
  line-height: ${({ theme }) => `${theme.getValue(16)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  margin: ${({ theme, inner }) => (inner ? 0 : `0 0 ${theme.getValue(15)}px 0`)};
`;

const SubTitle = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.bold}`};
  font-size: ${({ theme }) => `${theme.getValue(14)}px`};
  line-height: ${({ theme }) => `${theme.getValue(14)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  margin: ${({ theme }) => `${theme.getValue(20)}px 0 0 0`};
  & + ${Inner} {
    margin-top: ${({ theme }) => `${theme.getValue(5)}px`};
  }
`;

const Description = styled.p`
  font-family: ${({ theme }) => `${theme.fonts.primary}`};
  font-weight: ${({ theme }) => `${theme.weights.normal}`};
  font-size: ${({ theme }) => `${theme.getValue(12)}px`};
  line-height: ${({ theme }) => `${theme.getValue(12)}px`};
  color: ${({ theme }) => `${theme.colors.gray1}`};
  margin: ${({ theme }) => `${theme.getValue(3)}px 0 0 0`};
`;

export default { Title, SubTitle, Description };
