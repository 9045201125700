import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { useState } from 'react';
import { setBusinessEntity } from '../../store/slices/businessEntitySlice';
import CheckboxItem from '../../components/CheckboxItem';
import { InputContainer, StyledHeadline as Headline } from './styles';
import { ButtonSection } from '../Step2/styles';
import { decrement, increment } from '../../store/slices/stepSlice';
import entities from './data/businessEntities';
import { ResidentsDescription, ResidentwithoutDescription } from './data/caResidents';
import taxClassification from './data/taxClassification';
import Button from '../../components/Button';
import NumberInput from '../../components/NumberInput';
import Input from '../../components/Input';
import { Step4Schema as validationSchema } from './types';
import { RootState } from '../../store/store';
import useScrollToTop from '../stepUtils';

const step4uuid = uuid();

export default function Step4() {
  useScrollToTop();
  const businessEntityData = useSelector((state: RootState) => state.businessEntity);

  const dispatch = useDispatch();

  const [details, setDetails] = useState(false);

  const { values, errors, touched, handleBlur, handleChange, handleSubmit, isValid, setFieldValue, setFieldTouched } =
    useFormik({
      initialValues: businessEntityData,
      onSubmit: vals => {
        dispatch(setBusinessEntity({ ...vals }));
        dispatch(increment());
      },
      validateOnMount: true,
      validationSchema,
    });

  const resetValues = () => {
    setFieldValue('businessEntity', '');
    setFieldValue('accountNumber', '');
    setFieldTouched('accountNumber', false);
    setFieldValue('taxClassification', '');
    setFieldValue('firstName', '');
    setFieldTouched('firstName', false);
    setFieldValue('lastName', '');
    setFieldTouched('lastName', false);
    setFieldValue('resident', '');
  };

  return (
    <>
      {!details && (
        <div>
          <Headline>First, please choose your Business Entity type.</Headline>
          <p>
            Please choose your Business Entity below <strong>as per your W-9</strong>. You may only choose one.
          </p>
          {entities.map(entity => (
            <CheckboxItem
              key={uuid()}
              name="businessEntity"
              title={entity?.title}
              description=""
              value={entity?.title}
              onClick={() => {
                if (entity?.title !== values.businessEntity) {
                  resetValues();
                }
                setFieldValue('businessEntity', entity?.title);
              }}
              onBlur={handleBlur}
              isChecked={entity?.title === values.businessEntity}
              touched={touched.businessEntity}
              error={errors.businessEntity}
            />
          ))}
          <ButtonSection>
            <Button
              variant="secondary"
              onClick={() => {
                dispatch(decrement());
              }}
            >
              Back
            </Button>
            <Button
              disabled={values.businessEntity === ''}
              onClick={() => {
                setDetails(true);
              }}
            >
              Next
            </Button>
          </ButtonSection>
        </div>
      )}
      {details && (
        <div>
          <Headline>{values.businessEntity}</Headline>
          <p>
            As {values.businessEntity === 'Estate or Trust' ? 'an' : 'a'} {values.businessEntity}, you will need to
            provide the following information – <strong>all data must match your W-9.</strong>
          </p>
          <NumberInput
            touched={touched.accountNumber}
            key={`${step4uuid}-numberInput`}
            type={values.businessEntity === 'Individual/Sole Proprietor' ? 'type1' : 'type2'}
            value={values.accountNumber}
            valueLength={9}
            onBlur={() => {
              setFieldTouched('accountNumber', true);
            }}
            onChange={value => {
              setFieldValue('accountNumber', value);
              setFieldTouched('accountNumber', true);
            }}
            error={errors.accountNumber as string}
          />
          {values.businessEntity === 'Limited Liability Company (LLC)' && (
            <>
              <p>
                <strong>Please choose your tax classfication*</strong>
              </p>
              {taxClassification.map(tax => (
                <CheckboxItem
                  key={uuid()}
                  name="taxClassification"
                  title={`${tax.id} = ${tax?.title}`}
                  description=""
                  value={tax?.id}
                  onClick={() => {
                    setFieldValue('taxClassification', tax?.id);
                    setFieldTouched('taxClassification', true);
                  }}
                  onBlur={handleBlur}
                  isChecked={tax?.id === values.taxClassification}
                  touched={touched.taxClassification}
                  error={errors.taxClassification}
                />
              ))}
            </>
          )}
          {values.businessEntity === 'Individual/Sole Proprietor' && (
            <>
              <p>Name of Owner</p>
              <InputContainer>
                <Input
                  key="first-name"
                  id="firstName"
                  name="firstName"
                  label="First Name*"
                  value={values.firstName}
                  error={errors.firstName}
                  touched={touched.firstName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="name-container"
                />
                <Input
                  key="last-name"
                  id="lastName"
                  name="lastName"
                  label="Last Name*"
                  value={values.lastName}
                  error={errors.lastName}
                  touched={touched.lastName}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className="name-container"
                />
              </InputContainer>
            </>
          )}
          <p>
            <strong>Please choose a residency designation*</strong> (For questions, refer to{' '}
            <strong>Determine State of California Residency Status - Resident Or Nonresident</strong> resource.)
          </p>
          {values.businessEntity === 'Estate or Trust'
            ? ResidentsDescription.map(resident => (
                <CheckboxItem
                  key={uuid()}
                  name="businessEntity"
                  title={resident?.title}
                  description={resident?.description}
                  value={resident?.title}
                  onClick={() => {
                    setFieldValue('resident', resident?.title);
                  }}
                  onBlur={handleBlur}
                  isChecked={resident?.title === values.resident}
                  touched={touched.businessEntity}
                  error={errors.businessEntity}
                />
              ))
            : ResidentwithoutDescription.map(resident => (
                <CheckboxItem
                  key={uuid()}
                  name="businessEntity"
                  title={resident?.title}
                  description=""
                  value={resident?.title}
                  onClick={() => {
                    setFieldValue('resident', resident?.title);
                  }}
                  onBlur={handleBlur}
                  isChecked={resident?.title === values.resident}
                  touched={touched.businessEntity}
                  error={errors.businessEntity}
                />
              ))}
          <ButtonSection>
            <Button
              variant="secondary"
              onClick={() => {
                setDetails(false);
              }}
            >
              Back
            </Button>
            <Button
              disabled={!isValid}
              onClick={() => {
                handleSubmit();
              }}
            >
              Next
            </Button>
          </ButtonSection>
        </div>
      )}
    </>
  );
}
