import qs from 'qs';
import { VendorFilterTag } from '../components/admin/VendorFilter/types';

const parseFilterTag = (tag: VendorFilterTag) => {
  const { filterField, value } = tag;
  const normalizedVal = value.trim().toLowerCase();
  const operator = !value || normalizedVal === 'no' ? '$eq' : '$ne';

  if (filterField?.includes('sustainabilityResponsibility')) {
    if (normalizedVal === 'yes') {
      return {
        $and: [
          {
            sustainabilityPolicy: {
              sustainabilityResponsibility: {
                $ne: '',
              },
            },
          },
          {
            sustainabilityPolicy: {
              sustainabilityResponsibility: {
                $notContainsi: 'n/a',
              },
            },
          },
        ],
      };
    }
    return {
      $and: [
        {
          sustainabilityPolicy: {
            id: {
              $notNull: true,
            },
          },
        },
        {
          $or: [
            {
              sustainabilityPolicy: {
                sustainabilityResponsibility: {
                  $null: true,
                },
              },
            },
            {
              sustainabilityPolicy: {
                sustainabilityResponsibility: {
                  $eq: '',
                },
              },
            },
            {
              sustainabilityPolicy: {
                sustainabilityResponsibility: {
                  $ne: '',
                  $containsi: 'n/a',
                },
              },
            },
          ],
        },
      ],
    };
  }
  if (filterField?.includes('businessClassification')) {
    return qs.parse(`${filterField}[$containsi]=${value}`);
  }

  return qs.parse(`${filterField}[${operator}]`);
};

const parseFilter = (filterName: string, filterTags: VendorFilterTag[]) => {
  // let qsPartial = {};

  const filtersToParse = filterTags.filter(f => f.label === filterName);

  // if (filtersToParse.length > 1) {
  //   qsPartial = {
  //     $or: [...filtersToParse.map(tag => ({ ...parseFilterTag(tag) }))],
  //   };
  // } else {
  //   qsPartial = { ...parseFilterTag(filtersToParse[0]) };
  // }

  return filtersToParse.map(tag => ({ ...parseFilterTag(tag) }));
};

const parseFilterQuery = (filterTags: VendorFilterTag[]) => {
  if (filterTags?.length === 0) return [];

  // single out filter names
  const filterNames = [...new Set(filterTags.map(filter => filter.label))];

  const queryArr = filterNames.flatMap(fName => parseFilter(fName, filterTags));

  return queryArr;
};

export default parseFilterQuery;
